import { Children } from "react";
import AliceCarousel from "react-alice-carousel";
import { CaruselWrapper, GalleryItem } from "./common-styles";

export const Carusel = ({ children }) => {
  const handleDragStart = (e) => e.preventDefault();

  const items = Children.toArray(children).map((item) => (
    <GalleryItem onDragStart={handleDragStart} className="ls-gallery-item">
      {item}
    </GalleryItem>
  ));

  return (
    <CaruselWrapper>
      <AliceCarousel
        autoWidth
        mouseTracking
        keyboardNavigation
        items={items}
        className="ls-gallery"
      />
    </CaruselWrapper>
  );
};

// TO REMOVE IF NOT NEED
// export const Carusel = ({ children }) => {
//   const arrayChildren = Children.toArray(children);
//   const galleryScroller = useRef(null);
//   const [index, setIndex] = useState();

//   useEffect(() => {
//     const galleryItemSize = galleryScroller.current.firstChild.clientWidth;
//     galleryScroller.current.scrollTo(index * galleryItemSize, 0);
//   }, [index]);

//   const scrollToNextPage = () => {
//     const galleryItemSize = galleryScroller.current.firstChild.clientWidth;
//     galleryScroller.current.scrollBy(galleryItemSize, 0);
//   };
//   const scrollToPrevPage = () => {
//     const galleryItemSize = galleryScroller.current.firstChild.clientWidth;
//     galleryScroller.current.scrollBy(-galleryItemSize, 0);
//   };

//   return (
//     <CaruselWrapper>
//       <ul className="ls-gallery" ref={galleryScroller}>
//         {arrayChildren.map((item, idx) => (
//           <li className="ls-gallery-item" key={idx}>
//             {item}
//           </li>
//         ))}
//       </ul>
//       <div className="ls-btns">
//         <span className="btn prev" onClick={scrollToPrevPage}></span>
//         <span className="btn next" onClick={scrollToNextPage}></span>
//       </div>
//       <div className="ls-dots">
//         {arrayChildren.map((_, idx) => {
//           return (
//             <span
//               key={idx}
//               className={"dot" + (index === idx ? " active" : "")}
//               onClick={() => setIndex(idx)}
//             ></span>
//           );
//         })}
//       </div>
//     </CaruselWrapper>
//   );
// };
