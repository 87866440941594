import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { ReactComponent as Logo } from "assets/icons/logo_color.svg";
import { Icon } from "assets/icons/Icon";

import * as S from "./styles";
import { useStore } from "structure";
import { Button } from "semantic-ui-react";

export const BackNavigation = observer(() => {
  const { backNavigation } = useStore().viewStore;
  return (
    <S.BackNavigationWrapper>
      {backNavigation ? (
        <>
          {typeof backNavigation.path === "function" ? (
            <Button
              basic
              key={backNavigation.text}
              onClick={backNavigation.path}
            >
              <Icon name="left" /> {backNavigation.text}
            </Button>
          ) : (
            <Button
              basic
              key={backNavigation.text}
              as={Link}
              to={backNavigation.path}
            >
              <Icon name="left" /> {backNavigation.text}
            </Button>
          )}
        </>
      ) : (
        <Button basic as={Link} to="/" className="ls-logo">
          <Logo height={32} width={88} />
        </Button>
      )}
    </S.BackNavigationWrapper>
  );
});
