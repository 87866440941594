import { Field, useFormState } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";
import { useTranslation } from "react-i18next";
import { FieldButtonSelect } from "ui/FieldButtonSelect";
import * as S from "./styles";

export const ButtonSelect = ({ blockId }) => {
  const { t } = useTranslation();
  const values = useFormState()?.values?.[blockId]?.["tool-16"] || {};

  const options = (values.options || []).map((option, idx) => ({
    key: `${idx}-${option}`,
    value: option,
    content: option,
  }));

  return (
    <S.Block>
      <Field
        name={`${blockId}.tool-16.field`}
        initialValue={values.field || ""}
      >
        {(props) => (
          <TextareaAutosize
            name={props.input.name}
            value={props.input.value}
            onChange={props.input.onChange}
            className="ls-contentEditable"
            placeholder={t("block.button_select.field_placeholder")}
          />
        )}
      </Field>
      <Field name={`${blockId}.tool-16.desc`} initialValue={values.desc || ""}>
        {(props) => (
          <S.Field>
            <TextareaAutosize
              name={props.input.name}
              value={props.input.value}
              onChange={props.input.onChange}
              className="ls-contentEditable"
              placeholder={t("block.button_select.desc_placeholder")}
            />
          </S.Field>
        )}
      </Field>

      <div>
        <FieldButtonSelect
          finalFormProps={{ name: `${blockId}-preview` }}
          options={options}
          multiple={values.selection === "multi"}
        />
      </div>
    </S.Block>
  );
};
