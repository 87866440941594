import { observer } from "mobx-react-lite";

import { useStore } from "structure";
import { Button, Dropdown } from "semantic-ui-react";
import { Icon } from "assets/icons/Icon";

import { useTranslation } from "react-i18next";

export const MenuActions = observer(
  ({
    isForm,
    setOpenDuplicateModal,
    setIsDeleteModalOpen,
    handleSaveDraft,
  }) => {
    const { accountStore } = useStore();
    const { currentAccount } = accountStore;
    const { t } = useTranslation();

    return (
      <Dropdown
        trigger={
          <Button className="ls-bright ls-icon" color="grey">
            <Icon name="menu" />
          </Button>
        }
        loading={currentAccount?.isLoading}
        floating
        direction="left"
        icon={null}
      >
        <Dropdown.Menu>
          <Dropdown.Item
            text={t("step.menuActions.draft")}
            icon={<Icon name="unpublished" height={20} />}
            onClick={handleSaveDraft}
          />

          {isForm && (
            <Dropdown.Item
              text={t("step.menuActions.duplicate")}
              icon={<Icon name="duplicate" height={20} />}
              onClick={() => setOpenDuplicateModal(true)}
            />
          )}

          <Dropdown.Item
            text={t("step.menuActions.delete")}
            icon={<Icon name="delete" height={20} />}
            onClick={() => setIsDeleteModalOpen(true)}
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  }
);
