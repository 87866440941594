import { Link, useHistory } from "react-router-dom";
import { ProjectPreviewWrapper } from "./styles";
import { formatDate } from "util/helpers";
import { Icon } from "assets/icons/Icon";
import { T } from "util/Translation";
import { Dropdown } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { AsText } from "ui/common-styles";
import { observer } from "mobx-react-lite";

export const ProjectPreview = observer(({ data, actions }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onClick = () => {
    history.push(`/project/${data.id}`);
  };

  return (
    <ProjectPreviewWrapper onClick={onClick}>
      <div className="ls-header-preview">
        <div className="ls-name">
          <h4>{data.name}</h4>
          {data.isPublic ? (
            <span className="ls-public">
              <T id="projects.public" />
            </span>
          ) : (
            <span className="ls-draft">
              <T id="projects.draft" />
            </span>
          )}
        </div>
        <Dropdown
          trigger={
            <AsText onClick={(e) => e.stopPropagation}>
              <Icon name="menu" color="var(--grey-700)" />
            </AsText>
          }
          floating
          direction="left"
          icon={null}
        >
          <Dropdown.Menu>
            <Dropdown.Item
              text={t("projects.menu.edit")}
              icon={<Icon name="edit" height={20} />}
              as={Link}
              to={`/project/${data.id}`}
            />
            {data.isPublic ? (
              <Dropdown.Item
                text={t("projects.menu.draft")}
                icon={<Icon name="unpublished" height={20} />}
                onClick={() => actions.handleDraft(data.id)}
              />
            ) : (
              <Dropdown.Item
                text={t("projects.menu.publish")}
                icon={<Icon name="published" height={20} />}
                onClick={() => actions.handlePublish(data.id)}
              />
            )}

            <Dropdown.Item
              text={t("projects.menu.delete")}
              icon={<Icon name="delete" height={20} />}
              onClick={() => actions.handleDelete(data.id)}
            />
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="ls-body-preview">
        <div className="ls-replies">
          <strong>{data.submissions}</strong>{" "}
          <span>
            <T id="projects.replies" />
          </span>
        </div>
        <div className="ls-time">
          <span>
            <T id="projects.last_update" />
          </span>
          <strong>{formatDate(data.updated_at, "datetime")}</strong>
        </div>
      </div>
    </ProjectPreviewWrapper>
  );
});
