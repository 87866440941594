import { FieldText } from "ui/FieldText";
import * as S from "./styles";
import { multiValidators, required } from "util/validators";

export const Text = ({ blockId, data }) => {
  const validatorsArr = data.required ? [required] : [];
  return (
    <S.Block>
      {data.field && (
        <p className={"lf-field" + (data.required ? " lf-required" : "")}>
          {data.field}
        </p>
      )}
      <FieldText
        label={data.desc}
        finalFormProps={{
          name: blockId,
          validate: multiValidators(validatorsArr),
        }}
        semanticProps={{
          placeholder: data.placeholder,
          type: data.type,
        }}
      />
    </S.Block>
  );
};
