import { useCallback, useState } from "react";

const LazyImage = ({ src, alt, width, height, className }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const imgWidth = width
    ? typeof width === "number"
      ? `${width}px`
      : width
    : "auto";

  const imgHeight = height
    ? typeof height === "number"
      ? `${height}px`
      : height
    : "auto";

  const handleImageLoad = useCallback(() => {
    setIsLoaded(true);
  }, []);

  return (
    <div
      style={{
        width: imgWidth,
        height: imgHeight,
        position: "relative",
        overflow: "hidden",
      }}
      className={className}
    >
      {!isLoaded && (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="ls-loader"></div>
        </div>
      )}
      <img
        src={src}
        alt={alt}
        style={{
          width: "100%",
          height: "100%",
          opacity: isLoaded ? 1 : 0,
          objectFit: "cover",
          transition: "opacity 0.5s ease-in-out",
        }}
        loading="lazy"
        onLoad={handleImageLoad}
      />
    </div>
  );
};

export default LazyImage;
