import styled from "styled-components";

export const FieldWrapper = styled.div`
  .ls-h {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;

    :not(.ls-sub) {
      padding-top: 12px;
      border-top: 1px solid var(--grey-100);
    }

    svg {
      height: 16px;
      width: 16px;
    }
  }

  .ls-row {
    display: grid;
    grid-template-columns: auto 100px;
    align-items: end;
    gap: 16px;

    .input > input {
      width: 0;
      height: 43px;
    }
  }

  .ls-row-border {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
