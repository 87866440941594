import { useState } from "react";
import { Icon } from "assets/icons/Icon";
import { Button, Modal } from "semantic-ui-react";

import { useTranslation } from "react-i18next";

import { ModalCloseWrapper, ModalContentWrapper } from "./common-styles";

export const Confirm = (props) => {
  const {
    title,
    text,
    open,
    onCancel,
    onConfirm,
    onClose = null,
    size = "tiny",
    cancelButton,
    confirmButton,
    color,
  } = props;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    setIsLoading(true);
    await onConfirm();
    setIsLoading(false);
    return onClose ? onClose() : onCancel();
  };
  return (
    <Modal
      size={size}
      onClose={onClose ? onClose : onCancel}
      open={open}
      closeIcon={
        <ModalCloseWrapper>
          <Icon name="close" />
        </ModalCloseWrapper>
      }
    >
      <ModalContentWrapper>
        <div className="ls-content">
          {!!title && <h3>{title}</h3>}
          {!!text && <p>{text}</p>}
        </div>
        <div className="ls-actions">
          <Button
            type="button"
            fluid
            onClick={handleConfirm}
            className="ls-bright"
            {...(color ? { color } : { primary: true })}
            content={confirmButton || t("confirm.default_confirm")}
            disabled={isLoading}
            loading={isLoading}
          />
          <Button
            type="button"
            fluid
            className="ls-bright"
            color="grey"
            content={cancelButton || t("confirm.default_cancel")}
            onClick={onCancel}
            disabled={isLoading}
          />
        </div>
      </ModalContentWrapper>
    </Modal>
  );
};
