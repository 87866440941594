import { MediaGallery } from "./MediaGallery";

import { T } from "util/Translation";
import * as S from "./styles";
import { useTranslation } from "react-i18next";
import { useStore } from "structure";
import { useEffect } from "react";

export const Media = () => {
  const { t } = useTranslation();
  const { viewStore } = useStore();

  useEffect(() => {
    viewStore.setBackNavigation(t("navigation.home"), `/`);
    return () => viewStore.resetBackNavigation();
    // eslint-disable-next-line
  }, []);

  return (
    <S.Wrapper>
      <div className="main-section">
        <div className="header">
          <h1>
            <T id="media.title" />
          </h1>
          <p>
            <T id="media.description" />
          </p>
        </div>
      </div>
      <MediaGallery />
    </S.Wrapper>
  );
};
