import { T } from "util/Translation";

// Wrapper to apply multiple validators in sequence
export const multiValidators = (validators) => (value, allValues, meta) => {
  for (const validator of validators) {
    const result = validator(value, allValues, meta);
    if (result) return result;
  }
  return undefined;
};

// Basic Validators
export const required = (value) =>
  value ? undefined : <T id="validation.required" />;
export const requiredCheck = (value) =>
  value ? undefined : <T id="validation.required_check" />;
export const number = (value) =>
  typeof value === "number" ? undefined : <T id="validation.number" />;

// Length Validators
export const min = (minLength) => (value) =>
  value?.length >= minLength ? undefined : (
    <T id="validation.min_length" values={{ minLength }} />
  );
export const max = (maxLength) => (value) =>
  value?.length <= maxLength ? undefined : (
    <T id="validation.max_length" values={{ maxLength }} />
  );

// Matching Validator
export const equals = (field) => (value, allValues) =>
  value === allValues[field] ? undefined : (
    <T id="validation.equals" values={{ field }} />
  );

// Email Validator
export const email = (value) =>
  value && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? undefined : (
    <T id="validation.email" />
  );

// File Validators
export const maxFileSize = (mbSize) => (file) =>
  file.size <= mbSize * 1024 * 1024 ? undefined : (
    <T id="validation.max_file_size" values={{ mbSize }} />
  );
export const fileTypes = (allowedTypes) => (file) =>
  allowedTypes.includes(file.type) ? undefined : (
    <T
      id="validation.file_type"
      values={{ allowedTypes: allowedTypes.join(", ") }}
    />
  );
