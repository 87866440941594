import { observer } from "mobx-react-lite";
import { Link, useHistory } from "react-router-dom";

import { useStore } from "structure";

import { Button, Modal } from "semantic-ui-react";
import { ModalCloseWrapper, ModalContentWrapper } from "ui/common-styles";
import { Icon } from "assets/icons/Icon";

import { useTranslation } from "react-i18next";
import { T } from "util/Translation";
import { CreateFormModal } from "components/shared/CreateFormModal";
import { toast } from "react-toastify";
import { useState } from "react";

export const ContinueModal = observer(({ open, setOpen, project }) => {
  const { t } = useTranslation();
  const [isNewModalOpen, setIsNewModalOpen] = useState(false);
  const { projectsStore } = useStore();
  const history = useHistory();

  const createNewForm = async (name) => {
    const newId = await projectsStore.createNewForm(project.id, name);
    if (newId) {
      toast.success(t("forms.new_form_success"));
      history.push(`/project/${project.id}/form/${newId}`);
    } else {
      toast.error(t("forms.new_form_error"));
    }
  };

  return (
    <>
      <Modal
        size="tiny"
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        closeIcon={
          <ModalCloseWrapper>
            <Icon name="close" />
          </ModalCloseWrapper>
        }
      >
        <ModalContentWrapper>
          <div className="ls-content">
            <h3>
              <T id="continue_form_modal.title" />
            </h3>
            <p>
              <T id="continue_form_modal.description" />
            </p>
          </div>
          <div className="ls-options">
            <Button
              type="button"
              className="ls-bright"
              color="green"
              onClick={() => {
                setIsNewModalOpen(true);
                setOpen(false);
              }}
            >
              <T id="continue_form_modal.next_step" />
              <Icon name="nextPlan" />
            </Button>
            <Button
              type="button"
              className="ls-bright"
              primary
              as={Link}
              to={`/project/${project.id}/preview`}
            >
              <T id="continue_form_modal.preview" />
              <Icon name="output" />
            </Button>

            <Button
              type="button"
              className="ls-bright"
              secondary
              as={Link}
              to={`/project/${project.id}`}
            >
              <T id="continue_form_modal.back" />
              <Icon name="return" />
            </Button>
            <Button
              type="button"
              className="ls-bright"
              color="grey"
              onClick={() => setOpen(false)}
            >
              <T id="continue_form_modal.stay" />
              <Icon name="stay" />
            </Button>
          </div>
        </ModalContentWrapper>
      </Modal>
      <CreateFormModal
        open={isNewModalOpen}
        setOpen={setIsNewModalOpen}
        handleCreate={createNewForm}
      />
    </>
  );
});
