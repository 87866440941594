import { Field } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";

import { useTranslation } from "react-i18next";
import { T } from "util/Translation";

import * as S from "./styles";

export const VideoSettings = ({ blockId }) => {
  const { t } = useTranslation();

  return (
    <S.SectionWrapper>
      <li>
        <Field name={`${blockId}.tool-12.desc`}>
          {(props) => (
            <S.Field>
              <label>
                <T id="video_settings.notes" />
              </label>
              <TextareaAutosize
                name={props.input.name}
                value={props.input.value}
                onChange={props.input.onChange}
                className="ls-contentEditable"
                placeholder={t("video_settings.notes_placeholder")}
              />
            </S.Field>
          )}
        </Field>
      </li>
      <li>
        <Field name={`${blockId}.tool-12.url`}>
          {(props) => (
            <S.Field>
              <label>
                <T id="video_settings.url" />
              </label>
              <TextareaAutosize
                name={props.input.name}
                value={props.input.value}
                onChange={props.input.onChange}
                className="ls-contentEditable ls-field"
                placeholder={t("video_settings.url_placeholder")}
              />
            </S.Field>
          )}
        </Field>
      </li>
    </S.SectionWrapper>
  );
};
