import { DndContext, rectIntersection } from "@dnd-kit/core";
import { Interface } from "./Interface";

export const CreateForm = () => {
  return (
    <DndContext collisionDetection={rectIntersection}>
      <Interface />
    </DndContext>
  );
};
