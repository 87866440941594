import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Enter } from "components/Enter";
import { Dashboard } from "components/Dashboard";
import { Bounce, ToastContainer } from "react-toastify";

import "semantic-ui-css/semantic.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-quill/dist/quill.snow.css";
import "./App.css";
import "util/i18n";
import { ProjectPreview } from "components/Dashboard/Project/ProjectPreview";
import { Payment } from "components/Payment/Payment";
import { AppWrapper } from "styles";
import { useStore } from "structure";
import { observer } from "mobx-react-lite";
import { FullPageLoading } from "components/shared/Loading/Loading";
import { SubscriptionCompleted } from "components/SubscriptionCompleted";
import { SubscriptionCanceled } from "components/SubscriptionCanceled";
import TranslationEditor from "components/TranslationEditor/TranslationEditor";
import { useTranslation } from "react-i18next";

const App = observer(() => {
  const { i18n } = useTranslation();
  const { accountStore } = useStore();
  const { isFirstLoading } = accountStore;

  if (isFirstLoading) {
    return <FullPageLoading />;
  }

  const isRTL = i18n.language === "he";

  return (
    <AppWrapper dir={isRTL ? "rtl" : "ltr"}>
      <Router>
        <Switch>
          <Route exact path="/login">
            <Enter currentScreen="login" />
          </Route>
          <Route exact path="/register">
            <Enter currentScreen="register" />
          </Route>
          <Route exact path="/email-verification">
            <Enter currentScreen="verify" />
          </Route>
          <Route exact path="/reset-password">
            <Enter currentScreen="reset" />
          </Route>

          <Route exact path="/subscription/complete">
            <SubscriptionCompleted />
          </Route>
          <Route exact path="/subscription/cancel">
            <SubscriptionCanceled />
          </Route>
          <Route exact path="/payment/:plan">
            <Payment />
          </Route>
          <Route exact path="/project/:projectId/preview">
            <ProjectPreview />
          </Route>

          <Route exact path="/dev/translate">
            <TranslationEditor />
          </Route>
          <Route path="*">
            <Dashboard />
          </Route>
        </Switch>
      </Router>
      <ToastContainer
        position="bottom-right"
        transition={Bounce}
        autoClose={4000}
        hideProgressBar={true}
        closeOnClick
        rtl={isRTL}
        pauseOnFocusLoss
        draggable
        draggablePercent={50}
        pauseOnHover
        theme="light"
      />
    </AppWrapper>
  );
});

export default App;
