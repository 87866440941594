import { FieldCheckbox } from "ui/FieldCheckbox";
import { useTranslation } from "react-i18next";
import * as S from "./styles";

export const Switch = ({ name, labelIntl }) => {
  const { t } = useTranslation();
  return (
    <S.FieldWrapper>
      <FieldCheckbox
        finalFormProps={{
          name: name,
        }}
        semanticProps={{
          label: t(labelIntl),
          toggle: true,
        }}
      />
    </S.FieldWrapper>
  );
};
