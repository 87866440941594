import { makeObservable, observable, runInAction } from "mobx";
import { media as apiMedia } from "../endpoints";

export class Media {
  media = [];
  isLoading = false;

  constructor(root) {
    this.rootStore = root;
    makeObservable(this, {
      media: observable.ref,
      isLoading: observable,
    });
  }

  async fetchMedia() {
    runInAction(() => {
      this.isLoading = true;
    });
    const res = await apiMedia.get();
    if (res) {
      runInAction(() => {
        this.media = res;
        this.isLoading = false;
      });
    }

    runInAction(() => {
      this.isLoading = false;
    });
  }

  async addImage(selectedFile) {
    let formData = new FormData();
    formData.append("image", selectedFile);
    return apiMedia.add(formData).then((res) => {
      if (res.errors) throw res.errors;
      this.fetchMedia();
    });
  }

  getUsage() {
    return apiMedia.usage();
  }

  async removeImage(url) {
    const parts = url.split("/");
    const fileNameWithExtension = parts[parts.length - 1];
    return apiMedia
      .delete(fileNameWithExtension)
      .then(() => this.fetchMedia())
      .catch((e) => console.error(e));
  }
}
