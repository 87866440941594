import { MessagePageWrapper } from "./styles";
import { ReactComponent as LogoWebsite } from "assets/illustrations/logo.svg";
import { Link } from "react-router-dom";

export const MessagePage = ({ children }) => {
  return (
    <MessagePageWrapper>
      <Link className="ls-logo-website" to="/">
        <LogoWebsite />
      </Link>
      <div className="ls-content">{children}</div>
    </MessagePageWrapper>
  );
};
