import { Field, useFormState } from "react-final-form";
import { Dropdown, Checkbox, Input } from "semantic-ui-react";

import { useTranslation } from "react-i18next";
import { T } from "util/Translation";
import { FieldButtonSelect } from "ui/FieldButtonSelect";
import * as S from "./styles";

const iconsOptions = [
  {
    key: "star",
    value: "⭐",
    content: "⭐",
  },
  {
    key: "love",
    value: "😍",
    content: "😍",
  },
];

export const RateSettings = ({ blockId }) => {
  const { t } = useTranslation();
  const { values } = useFormState();

  const typeOptions = [
    {
      key: "numbers",
      text: t("rate_settings.type.numbers"),
      value: "numbers",
    },
    {
      key: "icon",
      text: t("rate_settings.type.icon"),
      value: "icon",
    },
  ];

  return (
    <S.SectionWrapper>
      <li>
        <Field name={`${blockId}.tool-18.required`}>
          {(props) => (
            <S.Field>
              <Checkbox
                name={props.input.name}
                checked={props.input.value || false}
                onChange={(e, data) => {
                  props.input.onChange(data.checked);
                }}
                label={t("rate_settings.required")}
              />
            </S.Field>
          )}
        </Field>
      </li>

      <li>
        <Field name={`${blockId}.tool-18.amount`} defaultValue={5}>
          {(props) => (
            <S.Field>
              <label style={{ marginRight: 16 }}>
                <T id="rate_settings.amount" />
              </label>
              <Input
                type="number"
                name={props.input.name}
                value={props.input.value}
                onChange={(e, data) => {
                  props.input.onChange(data.value);
                }}
                min={1}
                max={100}
              />
            </S.Field>
          )}
        </Field>
      </li>

      <Field name={`${blockId}.tool-18.type`} defaultValue="icon">
        {(props) => (
          <S.Field>
            <label>
              <T id="rate_settings.type_field" />
            </label>
            <Dropdown
              name={props.input.name}
              value={props.input.value}
              onChange={(e, data) => {
                props.input.onChange(data.value);
              }}
              fluid
              selection
              placeholder={t("rate_settings.type_field")}
              options={typeOptions}
            />
          </S.Field>
        )}
      </Field>
      {values[blockId]?.["tool-18"]?.type === "icon" && (
        <li>
          <FieldButtonSelect
            finalFormProps={{ name: `${blockId}.tool-18.icon` }}
            options={iconsOptions}
            label={t("rate_settings.icon")}
          />
        </li>
      )}
    </S.SectionWrapper>
  );
};
