import { Button, Modal } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { ModalCloseWrapper, ModalContentWrapper } from "ui/common-styles";
import { Icon } from "assets/icons/Icon";

import { useStore } from "structure";
import { useTranslation } from "react-i18next";
import * as S from "./styles";
import { T } from "util/Translation";
import { FieldRadio } from "ui/FieldRadio";
import { Form } from "react-final-form";

const LANGS = [
  {
    key: "en",
    text: "English",
    value: "en",
  },
  {
    key: "he",
    text: "עברית",
    value: "he",
  },
];

export const LangModal = observer(() => {
  const { i18n, t } = useTranslation();
  const { accountStore } = useStore();
  const { isLangModalOpen } = accountStore;
  const initialValue = i18n.resolvedLanguage;

  const closeModal = () => accountStore.setIsLangModalOpen(false);

  const onSubmit = ({ lang }) => {
    i18n.changeLanguage(lang);
    closeModal();
  };

  return (
    <Modal
      size="tiny"
      onClose={closeModal}
      open={isLangModalOpen}
      closeIcon={
        <ModalCloseWrapper>
          <Icon name="close" />
        </ModalCloseWrapper>
      }
    >
      <ModalContentWrapper>
        <div className="ls-content">
          <h3>{t("change_lang_modal.title")}</h3>
          <p>
            <T
              id={"change_lang_modal.description"}
              components={{ br: <br /> }}
            />
          </p>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <S.LangOptions>
                    <FieldRadio
                      finalFormProps={{
                        name: "lang",
                        initialValue: initialValue,
                      }}
                      semanticProps={{
                        options: LANGS,
                        disabled: submitting,
                      }}
                    />
                  </S.LangOptions>
                  <div className="ls-actions">
                    <Button
                      type="submit"
                      className="ls-bright"
                      primary
                      content={t("change_lang_modal.apply")}
                    />
                  </div>
                </form>
              );
            }}
          />
        </div>
      </ModalContentWrapper>
    </Modal>
  );
});
