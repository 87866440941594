import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 90%;

  .loader {
    width: 120px;
    height: 22px;
    border-radius: 20px;
    color: var(--grey-700);
    border: 2px solid;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      margin: 2px;
      inset: 0 100% 0 0;
      border-radius: inherit;
      background: currentColor;
      animation: p6 2s infinite;
    }
    @keyframes p6 {
      100% {
        inset: 0;
      }
    }
  }

  @media only screen and (max-width: 700px) {
    height: 100dvh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background: var(--white);
    z-index: 12;
  }
`;

export const FullPageWrapper = styled.div`
  height: 98dvh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
`;
