import { Field } from "react-final-form";
import { Input, Dropdown, Checkbox } from "semantic-ui-react";
import * as S from "./styles";
import { useState } from "react";

import { useTranslation } from "react-i18next";
import { T } from "util/Translation";

export const DateSettings = ({ blockId }) => {
  const { t } = useTranslation();

  const typeOptions = [
    {
      key: "date",
      text: t("date_settings.type.date"),
      value: "date",
    },
    {
      key: "time",
      text: t("date_settings.type.time"),
      value: "time",
    },
    {
      key: "datetime",
      text: t("date_settings.type.datetime"),
      value: "datetime-local",
    },
  ];

  const dateOptions = [
    {
      key: "none",
      text: t("date_settings.date_option.none"),
      value: "none",
    },
    {
      key: "current",
      text: t("date_settings.date_option.current"),
      value: "current",
    },
    {
      key: "spesific",
      text: t("date_settings.date_option.specific"),
      value: "spesific",
    },
  ];

  const [selection, setSelection] = useState({
    type: "date",
    default: false,
    min: false,
    max: false,
  });

  return (
    <S.SectionWrapper>
      <li>
        <Field name={`${blockId}.tool-7.required`}>
          {(props) => (
            <S.Field>
              <Checkbox
                name={props.input.name}
                checked={props.input.value || false}
                onChange={(e, data) => {
                  props.input.onChange(data.checked);
                }}
                label={t("date_settings.required")}
              />
            </S.Field>
          )}
        </Field>
      </li>
      <li>
        <Field name={`${blockId}.tool-7.type`} defaultValue={"date"}>
          {(props) => (
            <S.Field>
              <label>
                <T id="date_settings.field_type" />
              </label>
              <Dropdown
                name={props.input.name}
                value={props.input.value}
                onChange={(e, data) => {
                  props.input.onChange(data.value);
                  setSelection({ ...selection, type: data.value });
                }}
                fluid
                search
                selection
                options={typeOptions}
              />
            </S.Field>
          )}
        </Field>
      </li>
      <li>
        <Field
          name={`${blockId}.tool-7.defaultDate`}
          defaultValue={"none"}
          className="selectField"
        >
          {(props) => (
            <S.Field>
              <label>
                <T id="date_settings.default_date" />
              </label>
              <Dropdown
                name={props.input.name}
                value={props.input.value}
                onChange={(e, data) => {
                  setSelection({
                    ...selection,
                    default: data.value === "spesific",
                  });
                  props.input.onChange(data.value);
                }}
                fluid
                search
                selection
                options={dateOptions}
              />
            </S.Field>
          )}
        </Field>
      </li>
      {selection.default && (
        <li>
          <Field name={`${blockId}.tool-7.defaultDatePicker`}>
            {(props) => (
              <S.Field className="pickerField">
                <Input
                  type={selection.type}
                  name={props.input.name}
                  value={props.input.value}
                  onChange={(e, data) => {
                    props.input.onChange(data.value);
                  }}
                />
              </S.Field>
            )}
          </Field>
        </li>
      )}
      <li>
        <Field name={`${blockId}.tool-7.minDate`} defaultValue={"none"}>
          {(props) => (
            <S.Field className="selectField">
              <label>
                <T id="date_settings.min_date" />
              </label>
              <Dropdown
                name={props.input.name}
                value={props.input.value}
                onChange={(e, data) => {
                  setSelection({
                    ...selection,
                    min: data.value === "spesific",
                  });
                  props.input.onChange(data.value);
                }}
                fluid
                search
                selection
                options={dateOptions}
              />
            </S.Field>
          )}
        </Field>
      </li>
      {selection.min && (
        <li>
          <Field
            name={`${blockId}.tool-7.minDatePicker`}
            className="selectField"
          >
            {(props) => (
              <S.Field className="pickerField">
                <Input
                  type={selection.type}
                  name={props.input.name}
                  value={props.input.value}
                  onChange={(e, data) => {
                    props.input.onChange(data.value);
                  }}
                />
              </S.Field>
            )}
          </Field>
        </li>
      )}
      <li>
        <Field name={`${blockId}.tool-7.maxDate`} defaultValue={"none"}>
          {(props) => (
            <S.Field className="selectField">
              <label>
                <T id="date_settings.max_date" />
              </label>
              <Dropdown
                name={props.input.name}
                value={props.input.value}
                onChange={(e, data) => {
                  setSelection({
                    ...selection,
                    max: data.value === "spesific",
                  });
                  props.input.onChange(data.value);
                }}
                fluid
                search
                selection
                options={dateOptions}
              />
            </S.Field>
          )}
        </Field>
      </li>
      {selection.max && (
        <li>
          <Field name={`${blockId}.tool-7.maxDatePicker`}>
            {(props) => (
              <S.Field className="pickerField">
                <Input
                  type={selection.type}
                  name={props.input.name}
                  value={props.input.value}
                  onChange={(e, data) => {
                    props.input.onChange(data.value);
                  }}
                />
              </S.Field>
            )}
          </Field>
        </li>
      )}
    </S.SectionWrapper>
  );
};
