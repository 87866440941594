import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProjectsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  width: 100%;
  gap: 24px;

  @media only screen and (max-width: 700px) {
    max-width: 100%;
  }
`;

export const ProjectPreviewWrapper = styled.div`
  display: flex;
  background: white;
  max-width: calc(100vw - 108px);
  width: 100%;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  padding: 16px 24px;
  gap: 16px;
  cursor: pointer;
  color: var(--grey-500);
  box-shadow: var(--shadow-200);
  border: 1px solid var(--grey-200);
  transition: 0.2s;

  &:active {
    border-color: var(--tertiary-500);
  }

  .ls-header-preview {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .ls-name {
      display: flex;
      flex-direction: column;
      gap: 4px;

      h4 {
        color: var(--secondary-600);
      }

      .ls-public,
      .ls-draft {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 2px 12px;
        border-radius: 16px;
        line-height: 1.5;
        width: fit-content;

        :before {
          content: "";
          display: inline-flex;
          width: 8px;
          height: 8px;
          border-radius: 4px;
        }
      }

      .ls-public {
        color: var(--green-700);
        background: var(--green-100);

        :before {
          background: var(--green-400);
          filter: drop-shadow(0px 0px 4px var(--green-400));
        }
      }

      .ls-draft {
        color: var(--grey-700);
        background: var(--grey-100);

        :before {
          background: var(--grey-400);
        }
      }
    }
  }

  .ls-body-preview {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .ls-replies {
      display: flex;
      flex-direction: column;

      strong {
        font-size: 40px;
        line-height: 1;
        font-weight: 400;
        color: var(--grey-800);
      }
    }

    .ls-time {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      span {
        font-size: 14px;
      }

      strong {
        font-weight: 400;
        color: var(--grey-700);
      }
    }
  }

  &.ls-new-project {
    gap: 4px;
    align-items: start;
    justify-content: center;
    border-color: var(--primary-200);
    background: var(--primary-50);
    color: var(--primary-700);
    fill: var(--primary-700);
    box-shadow: 0px 4px 16px #66e7f821;

    h4 {
      display: flex;
      align-items: center;
    }

    > svg {
      padding: 2px;
      height: 28px;
      width: 28px;
      fill: var(--white);
      background: var(--primary-700);
      border-radius: 16px;
    }

    :hover {
      box-shadow: 0px 2px 12px #66c7d457;
    }
  }

  &:hover {
    box-shadow: var(--shadow-400);
  }

  .ui.floating.dropdown > .menu {
    margin-top: -32px !important;
  }

  @media only screen and (max-width: 700px) {
    max-width: 100%;
  }
`;
