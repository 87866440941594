import { Field } from "react-final-form";
import { FormField } from "./common-styles";
import { Radio } from "semantic-ui-react";

export const FieldRadio = ({ label, finalFormProps, semanticProps }) => {
  const { options, ...otherSemanticProps } = semanticProps;

  return (
    <Field {...finalFormProps}>
      {({ input, meta }) => {
        const hasError =
          meta.touched &&
          (meta.error || (meta.submitError && !meta.modifiedSinceLastSubmit));

        return (
          <FormField>
            {typeof label === "string" ? <label>{label}</label> : label}
            <div style={{ width: "fit-content" }}>
              {options.map((option, idx) => {
                if (typeof option === "string") {
                  return (
                    <div key={idx}>
                      <Radio
                        name={input.name}
                        label={option}
                        value={option}
                        checked={input.value === option}
                        onClick={() => {
                          input.onChange(option);
                        }}
                        {...otherSemanticProps}
                      />
                    </div>
                  );
                }
                return (
                  <div key={option.key}>
                    <Radio
                      name={input.name}
                      label={option.text}
                      value={option.value}
                      checked={input.value === option.value}
                      onClick={() => {
                        input.onChange(option.value);
                      }}
                      {...otherSemanticProps}
                    />
                  </div>
                );
              })}
            </div>

            {hasError && (
              <p className="ls-error">{meta.error || meta.submitError}</p>
            )}
          </FormField>
        );
      }}
    </Field>
  );
};
