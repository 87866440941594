import { Field } from "react-final-form";
import * as S from "./styles";
import { Dropdown as DropdownSemantic } from "semantic-ui-react";

export const Dropdown = ({ blockId, data }) => {
  if (!data.options) return null;
  const options = data.options.map((item) => ({
    key: item,
    text: item,
    value: item,
  }));
  return (
    <S.Block>
      <Field name={blockId}>
        {(props) => (
          <S.Field>
            {data.field && (
              <p className={"lf-field" + (data.required ? " lf-required" : "")}>
                {data.field}
              </p>
            )}
            {data.desc && <p>{data.desc}</p>}
            <DropdownSemantic
              name={props.input.name}
              value={
                props.input.value || (data.selection === "multi" ? [] : null)
              }
              onChange={(e, data) => {
                props.input.onChange(data.value);
              }}
              search
              selection
              clearable
              multiple={data.selection === "multi"}
              options={options}
            />
          </S.Field>
        )}
      </Field>
    </S.Block>
  );
};
