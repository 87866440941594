import StyleFields from "./StyleFields";

const GlobalSettings = () => {
  return (
    <>
      <StyleFields.Switch
        name="global.steps"
        labelIntl="projectPreview.design.global.steps"
      />
      <StyleFields.Color
        name="global.primaryColor"
        labelIntl="projectPreview.design.global.primary_color"
      />
      <StyleFields.Background
        name="global.background"
        labelIntl="projectPreview.design.global.background"
      />
      <StyleFields.FontStyle
        name="global.titleStyle"
        labelIntl="projectPreview.design.global.title_style"
      />
      <StyleFields.FontSize
        name="global.titleSize"
        labelIntl="projectPreview.design.global.title_style"
        //defaultValue={{ size: 24, weight: "700" }}
      />
      <StyleFields.FontStyle
        name="global.textStyle"
        labelIntl="projectPreview.design.global.text_style"
      />
      <StyleFields.FontSize
        name="global.textSize"
        labelIntl="projectPreview.design.global.text_size"
        //defaultValue={{ size: 16, weight: "400" }}
      />
    </>
  );
};

export default GlobalSettings;
