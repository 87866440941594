import { Radio } from "semantic-ui-react";
import * as S from "./styles";
import { Field } from "react-final-form";
import { FormField } from "ui/common-styles";
import { multiValidators, required } from "util/validators";

export const RadioGroup = ({ blockId, data }) => {
  if (!data.options) return null;

  const validatorsArr = data.required ? [required] : [];

  return (
    <S.Block>
      {data.field && (
        <p className={"lf-field" + (data.required ? " lf-required" : "")}>
          {data.field}
        </p>
      )}
      {data.desc && <p>{data.desc}</p>}
      <Field name={`${blockId}`} validate={multiValidators(validatorsArr)}>
        {({ input, meta }) => {
          return (
            <FormField>
              <div style={{ width: "fit-content" }}>
                {data.options.map((option, idx) => (
                  <div key={idx}>
                    <Radio
                      label={option}
                      name={`${blockId}`}
                      value={option}
                      checked={input.value === option}
                      onClick={() => {
                        input.onChange(option);
                      }}
                    />
                  </div>
                ))}
              </div>

              {meta.touched && meta.error && (
                <p className="ls-error">{meta.error}</p>
              )}
            </FormField>
          );
        }}
      </Field>
    </S.Block>
  );
};
