import { Form } from "react-final-form";
import { Button } from "semantic-ui-react";
import { publicEndpoints } from "structure/endpoints";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { ContentPart } from "./ContentPart";

const PAGE_STATUSES = {
  FORM: 0,
  SUCCESS: 1,
  ERROR: 2,
};

export const MainSection = ({
  data,
  setPageStatus,
  setReplyContent = null,
}) => {
  const { username, projectSlug } = useParams();
  const [formStep, setFormStep] = useState(0);
  const settings = {
    ...data.settings,
    steps: data.settings?.custom_ui?.global?.steps === true,
    prevBtn: data.settings?.custom_ui?.button?.prevButtonText || "Back",
    nextBtn: data.settings?.custom_ui?.button?.nextButtonText || "Next",
    submitBtn: data.settings?.custom_ui?.button?.submitButtonText || "Submit",
  };
  const isFinalStep = settings.steps
    ? data.forms.length - 1 === formStep
    : true;

  const changeFormStep = (step) => {
    setFormStep(step);
    window.scrollTo(0, 0);
  };

  const submitForm = async (reply) => {
    if (!isFinalStep) {
      changeFormStep(formStep + 1);
      return;
    }

    const formReply = createFormReply(data, reply);
    if (username && projectSlug) {
      await publicEndpoints
        .sendReply(username, projectSlug, formReply)
        .then((res) => {
          setPageStatus(PAGE_STATUSES.SUCCESS);
        })
        .catch((e) => {
          console.error(e);
          setPageStatus(PAGE_STATUSES.ERROR);
        });
    } else {
      setReplyContent && setReplyContent(formReply);
      setPageStatus(PAGE_STATUSES.SUCCESS);
    }
  };

  const scrollToFirstError = (errors) => {
    let formId = Object.keys(errors || {})[0];
    if (!formId) return;
    let fieldId = Object.keys(errors?.[formId] || {})[0];
    if (!fieldId) return;

    const errorElement = document.querySelector(
      `[name="${formId}.${fieldId}"]`
    );

    if (errorElement) {
      setTimeout(() => {
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 100);
    }
  };

  return (
    <div>
      <Form
        onSubmit={submitForm}
        render={({ handleSubmit, submitting, errors }) => {
          return (
            <form onSubmit={handleSubmit}>
              {data.forms.map((form, idx) => {
                if (settings.steps && formStep !== idx) return null;
                return (
                  <div className="lf-form" key={form.id}>
                    <h2>{form.name}</h2>
                    <ContentPart
                      id={form.id}
                      fields={form.fields}
                      isFramed={settings.frame}
                    />
                  </div>
                );
              })}
              <div
                className="ls-actions"
                onClick={() => scrollToFirstError(errors)}
              >
                {settings.steps && formStep > 0 && (
                  <Button
                    className="ls-bright"
                    color="grey"
                    type="button"
                    disabled={submitting}
                    onClick={() => changeFormStep(formStep - 1)}
                  >
                    {settings.prevBtn || "Back"}
                  </Button>
                )}
                <div className="ls-submit-button">
                  <Button
                    primary
                    type="submit"
                    loading={submitting}
                    disabled={submitting}
                  >
                    {isFinalStep
                      ? settings.submitBtn || "Submit"
                      : settings.nextBtn || "Next"}
                  </Button>
                </div>
              </div>

              {isFinalStep && (
                <div className="ls-disclaimer">
                  <p>
                    Do not submit sensitive or confidential information here.
                  </p>
                </div>
              )}
            </form>
          );
        }}
      />
    </div>
  );
};

const createFormReply = (data, reply) => {
  return data.forms.map((form) => {
    const formReply = reply[form.id];
    return {
      name: form.name,
      fields: form.fields
        .map((field, idx) => {
          const fieldData = Object.values(field)[0];
          if (!fieldData.field) return null;
          return {
            field: fieldData.field,
            value: formReply?.[`f-${idx}`] || "",
          };
        })
        .filter((item) => item),
    };
  });
};
