import { observer } from "mobx-react-lite";
import { Form } from "react-final-form";
import { GeneralEdit } from "ui/common-styles";
import { FieldTextArea } from "ui/FieldTextArea";
import { PanelActions } from "../../PanelActions";

import { useTranslation } from "react-i18next";
import { T } from "util/Translation";

export const Description = observer(({ project, updateSettings }) => {
  const { t } = useTranslation();
  const projectId = project.id;
  const projectSettings = project.settings;

  const onSubmit = async (data) => {
    await updateSettings(projectId, data);
  };

  return (
    <GeneralEdit>
      <h4>
        <T id="description.title" />
      </h4>
      <p>
        <T id="description.intro" />
      </p>

      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, form }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FieldTextArea
                label={t("description.label")}
                finalFormProps={{
                  name: "desc",
                  initialValue: projectSettings.desc,
                }}
                semanticProps={{
                  disabled: submitting,
                }}
              />

              <PanelActions />
            </form>
          );
        }}
      />
    </GeneralEdit>
  );
});
