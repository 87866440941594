import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction,
} from "mobx";
import { auth as apiAuth, user as apiUser } from "../endpoints";

export class Account {
  currentAccount = null;
  isLoading = false;
  isFirstLoading = true;
  loginDetails = null;
  isLangModalOpen = false;
  subscriptionLead = null;

  constructor() {
    this.start();
    makeObservable(this, {
      isFirstLoading: observable,
      currentAccount: observable,
      isLoading: observable,
      isLangModalOpen: observable,
      subscriptionLead: observable,
      loginDetails: observable.ref,
      isAdmin: computed,
      login: action,
      register: action,
      registerWithGoogleData: action,
      logout: action,
      setIsLangModalOpen: action,
      setSubscriptionLead: action,
    });
  }

  async start() {
    try {
      if (localStorage.getItem("token")) {
        await this.getUserData();
      }
    } finally {
      runInAction(() => {
        this.isFirstLoading = false;
      });
    }
  }

  get isAdmin() {
    return this.currentAccount?.id === 1;
  }

  get isAuthanticated() {
    return this.currentAccount !== null;
  }

  async login({ email, password, remember = true }) {
    this.isLoading = true;
    try {
      const res = await apiAuth.login({
        email,
        password,
      });

      if (res.user) {
        this.loginDetails = null;
        this.currentAccount = res.user;
        localStorage.setItem("token", res.token);
        localStorage.setItem("account", JSON.stringify(res.user));
        return { status: "success" };
      }
      this.loginDetails = { email, password };
      return res;
    } catch (e) {
      return { error: e };
    } finally {
      this.isLoading = false;
    }
  }

  async loginGoogle(token) {
    this.isLoading = true;
    const res = await apiAuth.loginGoogle({
      token,
    });
    if (res.user) {
      this.currentAccount = res.user;
      localStorage.setItem("token", res.token);
      localStorage.setItem("account", JSON.stringify(res.user));
    }
    this.isLoading = false;
    return res;
  }

  async register({
    first_name,
    last_name,
    username,
    email,
    password,
    password_confirmation,
  }) {
    this.isLoading = true;
    const res = await apiAuth.register({
      first_name,
      last_name,
      username,
      email,
      password,
      password_confirmation,
    });
    if (res.status === "require_email_verification") {
      this.loginDetails = { first_name, last_name, username, email, password };
      this.isLoading = false;
      return true;
    }

    this.isLoading = false;
    return res;
  }
  async requestVerifyEmail(email) {
    this.isLoading = true;
    const res = await apiAuth.requestEmailVerification({
      email,
    });
    this.isLoading = false;
    return res;
  }

  async verifyEmail({ code, email }) {
    this.isLoading = true;
    const res = await apiAuth.registerVerification({
      email,
      code,
    });
    if (res.status === "success" && this.loginDetails) {
      if (await this.login(this.loginDetails)) {
        return "enter";
      }
    }
    this.isLoading = false;
    return res;
  }

  async requestResetPassword({ email }) {
    this.isLoading = true;
    const res = await apiAuth.requestResetPassword({
      email,
    });
    this.isLoading = false;
    return res;
  }

  async resetPassword(data) {
    this.isLoading = true;
    const res = await apiAuth.resetPassword(data);
    this.isLoading = false;
    return res;
  }

  async registerWithGoogleData(data) {
    this.isLoading = true;
    const res = await apiAuth.registerGoogle(data);
    if (res.user) {
      this.currentAccount = res.user;
      localStorage.setItem("token", res.token);
      localStorage.setItem("account", JSON.stringify(res.user));
      this.isLoading = false;
      return true;
    }
    this.isLoading = false;
    return false;
  }

  async logout() {
    this.isLoading = true;
    await apiAuth.logout();
    localStorage.removeItem("account");
    localStorage.removeItem("token");
    this.currentAccount = null;
    this.isLoading = false;
    return true;
  }

  async getUserData() {
    return apiUser.getUserData().then((res) => {
      if (res.data?.username) {
        this.currentAccount = res.data;
      }
    });
  }

  async updatePersonalDetails(data) {
    try {
      await apiUser.updatePersonalData(data);
      await this.getUserData();
      return true;
    } catch {
      return false;
    }
  }

  async updatePassword({ currentPass, newPass, confirmPass }) {
    try {
      const status = await apiUser.updatePassword({
        current_password: currentPass,
        new_password: newPass,
        new_password_confirmation: confirmPass,
      });
      return status;
    } catch {
      return false;
    }
  }

  async updateAvatar(selectedFile) {
    let formData = new FormData();
    formData.append("avatar", selectedFile);
    return apiUser
      .uploadAvatar(formData)
      .then(() => this.getUserData())
      .catch((e) => console.error(e));
  }

  async deleteAvatar() {
    return apiUser
      .deleteAvatar()
      .then(() => this.getUserData())
      .catch((e) => console.error(e));
  }

  checkUsernameExists(username) {
    return apiUser.checkUsername(username);
  }

  checkEmailExists(email) {
    return apiUser.checkEmail(email);
  }

  setIsLangModalOpen(bool) {
    this.isLangModalOpen = bool;
  }

  setSubscriptionLead(lead) {
    this.subscriptionLead = lead;
  }
}
