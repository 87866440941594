import { observer } from "mobx-react-lite";
import { Form } from "react-final-form";
import { FieldText } from "ui/FieldText";
import { GeneralEdit } from "ui/common-styles";
import { useStore } from "structure";
import { max, min, multiValidators, required } from "util/validators";
import { PanelActions } from "../../PanelActions";

import { T } from "util/Translation";
import { useTranslation } from "react-i18next";

export const Slug = observer(({ project }) => {
  const { projectsStore } = useStore();
  const { t } = useTranslation();
  const projectId = project.id;

  const onSubmit = async (data) => {
    const success = await projectsStore
      .update(projectId, { slug: data.slug })
      .then(() => projectsStore.fetchProjects());
    if (!success) {
      return {
        slug: t("slug.error"),
      };
    }
  };

  return (
    <GeneralEdit>
      <h4>
        <T id="slug.title" />
      </h4>
      <p>
        <T id="slug.description" />
      </p>

      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FieldText
                label={t("slug.label")}
                finalFormProps={{
                  name: "slug",
                  initialValue: project.slug,
                  validate: multiValidators([required, min(3), max(255)]),
                }}
                semanticProps={{
                  disabled: submitting,
                }}
              />

              <PanelActions />
            </form>
          );
        }}
      />
    </GeneralEdit>
  );
});
