import { Icon } from "assets/icons/Icon";
import StyleFields from "components/Dashboard/Project/ProjectPreview/settingsSections/StyleFields";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useForm, useFormState } from "react-final-form";
import { Button } from "semantic-ui-react";
import { makeid } from "util/helpers";

export const LinksSettings = observer(({ name }) => {
  const form = useForm();
  const { values } = useFormState();

  const [linkIds, setLinkIds] = useState(() => values[name]?.linkIds || []);

  useEffect(() => {
    if (linkIds.length === 0) {
      handleAdd();
    }
    // eslint-disable-next-line
  }, [name]);

  const handleAdd = () => {
    const base = linkIds || [];
    const newArr = [...base, makeid()];
    form.change(`${name}.linkIds`, newArr);
    setLinkIds(newArr);
  };

  const handleRemove = (id) => {
    const newArr = linkIds.filter((linkId) => linkId !== id);
    form.change(`${name}.linkIds`, newArr);
    form.change(`${name}.linksContent.${id}`, {});
    setLinkIds(newArr.length === 0 ? [makeid()] : newArr);
  };

  return (
    <div className="ls-group">
      <StyleFields.Text sub name={`${name}.title`} labelIntl="Button text" />
      <ul>
        {linkIds.map((linkId) => (
          <li key={linkId}>
            <StyleFields.Text
              sub
              name={`${name}.linksContent.${linkId}.text`}
              labelIntl="Link text"
            />
            <StyleFields.Text
              sub
              name={`${name}.linksContent.${linkId}.link`}
              labelIntl="Link URL"
            />
            <Button
              className="ls-icon ls-bright"
              color="red"
              onClick={() => handleRemove(linkId)}
            >
              <Icon name="delete" />
            </Button>
          </li>
        ))}
      </ul>

      <Button className="ls-bright" primary onClick={handleAdd}>
        Add new link
      </Button>
    </div>
  );
});
