import { Container } from "./styles";
import { useState } from "react";
import { Header } from "./parts/Header";
import { SuccessPage } from "./parts/SuccessPage";
import { MainSection } from "./parts/MainSection";
import { Footer } from "./parts/Footer";
import { ErrorPage } from "./parts/ErrorPage";

const PAGE_STATUSES = {
  FORM: 0,
  SUCCESS: 1,
  ERROR: 2,
};

export const Content = ({ data, setReplyContent = null }) => {
  const [pageStatus, setPageStatus] = useState(PAGE_STATUSES.FORM);

  const settings = data.settings || {};

  return (
    <>
      <Container customUI={settings.custom_ui} dir={settings.direction}>
        <Header data={settings.header} />
        {pageStatus === PAGE_STATUSES.ERROR && (
          <ErrorPage data={settings.error} />
        )}
        {pageStatus === PAGE_STATUSES.SUCCESS && (
          <SuccessPage data={settings.success} />
        )}
        {pageStatus === PAGE_STATUSES.FORM && (
          <MainSection
            data={data}
            setPageStatus={setPageStatus}
            setReplyContent={setReplyContent}
          />
        )}
        <Footer data={settings.footer} />
      </Container>
    </>
  );
};

// const getUniqueFonts = (customSettings) => {
//   let fonts = [];
//   if (customSettings?.global?.titleStyle?.font)
//     fonts.push(customSettings?.global?.titleStyle?.font);
//   if (customSettings?.global?.textStyle?.font)
//     fonts.push(customSettings?.global?.textStyle?.font);
//   if (customSettings?.button?.fontStyle?.font)
//     fonts.push(customSettings?.button?.fontStyle?.font);

//   return [...new Set(fonts.filter((font) => font))]; // Remove null/undefined and duplicates
// };

// const FontLoader = ({ uniqueFonts }) => {
//   useEffect(() => {
//     uniqueFonts.forEach((font) => {
//       const link = document.createElement("link");
//       link.href = `https://fonts.googleapis.com/css2?family=${font.replace(
//         / /g,
//         "+"
//       )}&display=swap`;
//       link.rel = "stylesheet";
//       document.head.appendChild(link);
//     });
//   }, [uniqueFonts]);

//   return null;
// };
