import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { useStore } from "structure";
import { Button, Dropdown } from "semantic-ui-react";
import { Icon } from "assets/icons/Icon";

import { useTranslation } from "react-i18next";
import { LIVE_URL } from "util/consts";

export const MenuActions = observer(
  ({
    project,
    setOpenDuplicateModal,
    setIsDeleteModalOpen,
    setIsPublishConfirmOpen,
    setIsDraftConfirmOpen,
  }) => {
    const { accountStore } = useStore();
    const { currentAccount } = accountStore;
    const { t } = useTranslation();

    const projectURL = project
      ? `${LIVE_URL}${currentAccount.username}/${project.slug}`
      : "/";

    return (
      <Dropdown
        trigger={
          <Button className="ls-bright ls-icon" color="grey">
            <Icon name="menu" />
          </Button>
        }
        loading={currentAccount?.isLoading}
        floating
        direction="left"
        icon={null}
      >
        <Dropdown.Menu>
          {project?.isPublic ? (
            <Dropdown.Item
              className="ls-show-s"
              text={t("projects.menu.draft")}
              icon={<Icon name="unpublished" height={20} />}
              onClick={() => setIsDraftConfirmOpen(true)}
            />
          ) : (
            <Dropdown.Item
              className="ls-show-s"
              text={t("projects.menu.publish")}
              icon={<Icon name="published" height={20} />}
              onClick={() => setIsPublishConfirmOpen(true)}
            />
          )}

          <Dropdown.Item
            text={t("project.menuActions.share")}
            icon={<Icon name="share" height={20} />}
            as={Link}
            to={{ pathname: projectURL }}
            target="_blank"
          />

          <Dropdown.Item
            text={t("project.menuActions.duplicate")}
            icon={<Icon name="duplicate" height={20} />}
            onClick={() => setOpenDuplicateModal(true)}
          />
          <Dropdown.Item
            text={t("project.menuActions.delete")}
            icon={<Icon name="delete" height={20} />}
            onClick={() => setIsDeleteModalOpen(true)}
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  }
);
