import StyleFields from "./StyleFields";

const FormFrameSettings = () => {
  return (
    <>
      <StyleFields.Allignment
        name="formFrame.alignment"
        labelIntl="projectPreview.design.form_frame.position"
        sub
      />
      <StyleFields.Width
        name="formFrame.width"
        labelIntl="projectPreview.design.form_frame.width"
        smallSize={400}
      />
      <StyleFields.Background
        name="formFrame.background"
        labelIntl="projectPreview.design.form_frame.background"
      />
      <StyleFields.Border
        name="formFrame.border"
        labelIntl="projectPreview.design.form_frame.border"
      />
      <StyleFields.Radius
        name="formFrame.radius"
        labelIntl="projectPreview.design.form_frame.radius"
      />
      <StyleFields.Shadow
        name="formFrame.shadow"
        labelIntl="projectPreview.design.form_frame.shadow"
      />
    </>
  );
};

export default FormFrameSettings;
