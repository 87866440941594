import { Field } from "react-final-form";
import * as S from "./styles";
import { Input } from "semantic-ui-react";
import { formatSystemDate } from "util/helpers";

export const DateEl = ({ blockId, data }) => {
  const now = new Date();
  const initialDate =
    !data.defaultDate || data.defaultDate === "current"
      ? formatSystemDate(now, data.type)
      : formatSystemDate(data.defaultDate, data.type);
  const minDate =
    data.minDate === "none"
      ? null
      : data.minDate === "current"
      ? formatSystemDate(now, data.type)
      : formatSystemDate(data.minDate, data.type);
  const maxDate =
    data.maxDate === "none"
      ? null
      : data.maxDate === "current"
      ? formatSystemDate(now, data.type)
      : formatSystemDate(data.maxDate, data.type);

  return (
    <S.Block>
      {data.field && (
        <p className={"lf-field" + (data.required ? " lf-required" : "")}>
          {data.field}
        </p>
      )}
      {data.desc && <p>{data.desc}</p>}
      <Field
        name={`${blockId}`}
        className="selectField"
        initialValue={initialDate}
      >
        {(props) => (
          <S.Field className="pickerField">
            <Input
              type={data.type}
              name={props.input.name}
              value={props.input.value}
              min={minDate}
              max={maxDate}
              onChange={(e, data) => {
                props.input.onChange(data.value);
              }}
            />
          </S.Field>
        )}
      </Field>
    </S.Block>
  );
};
