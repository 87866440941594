import styled from "styled-components";

export const PaneWrapper = styled.div`
  margin-top: 24px;
  animation: fadeIn 0.3s;
  display: flex;
  flex-direction: column;

  .ui.button.basic.ls-nav-back {
    position: fixed;
    top: 16px;
    left: 4px;
    width: 50%;
    color: var(--grey-600) !important;
    background: var(--grey-50) !important;
    z-index: 11;
  }

  .ls-option-list {
    position: sticky;
    top: 24px;
    margin: 0;
    padding: 8px 0;
    min-width: 320px;
    width: 40%;
    border-right: 1px solid var(--primary-700);
    height: calc(-280px + 100dvh);
    overflow-y: auto;

    > li {
      border-bottom: 1px solid var(--grey-300);
      &.ls-selected {
        > button,
        > button:hover {
          background: var(--grey-100);
          .ls-tag:not(.ls-active):not(.ls-inherit-bg) {
            background: var(--grey-200) !important;
          }
        }
      }
      > button {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        padding: 12px 40px;
        border-radius: 0;
        width: 100%;
        text-align: left;
        min-height: 48px;
        > span:first-child {
          color: var(--grey-800);
          font-weight: 600;
        }

        > span:first-child {
          color: var(--grey-800);
          font-weight: 600;
        }

        > span:last-child {
          color: var(--grey-600);
        }

        > span:last-child,
        > div:last-child {
          > .ls-tag {
            display: inline-block;
            padding: 4px 10px;
            border-radius: 4px;
            background: var(--grey-100);
            font-weight: 600;
            color: ver(--grey-700);

            &.ls-active {
              background: var(--green-100);
              color: var(--green-700);
            }
          }
        }

        :hover {
          background: var(--grey-100);
          opacity: 1;
          .ls-tag:not(.ls-active):not(.ls-inherit-bg) {
            background: var(--grey-200) !important;
          }
        }
      }
    }
  }

  .ls-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 75%;
    min-width: 400px;
    max-width: 594px;
    margin: 24px 40px;

    > * {
      animation: fadeIn 0.3s;
    }
  }

  h3 {
    font-family: var(--title-font);
    color: var(--primary);
    font-weight: 700;
    font-size: 48px;
  }

  .ls-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  ul {
    list-style-type: none;
    padding-inline-start: 0;
    display: flex;
    flex-direction: column;

    > li {
      > span:first-child {
        display: inline-block;
        min-width: 140px;
        font-weight: 500;
      }
    }

    li.ls-one-line {
      margin-bottom: 16px;
    }
  }

  .ls-header {
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
  }

  .ui.input.ls-color-picker > input {
    padding: 0px;
    max-width: 60px;
    border: none;
  }

  @media (max-width: 700px) {
    .ls-option-list {
      position: relative;
      margin: -48px 0;
      width: 100%;
      border: none;

      > li.ls-selected > button {
        ,
        &:hover {
          background: transparent;
        }
      }
    }

    .ls-content {
      width: calc(100vw - 10px);
      max-width: calc(100vw - 10px);
      margin: 24px auto;
      padding: 0 24px;
      border: none;
      border-radius: 0;
    }
  }
`;

export const ActionsWrapper = styled.div`
  display: none;
  align-items: center;
  justify-content: right;
  gap: 16px;
  animation: fadeIn 0.3s;
`;
