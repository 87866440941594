import { Link, useHistory, useLocation } from "react-router-dom";
import { useStore } from "structure";
import { observer } from "mobx-react-lite";

import { BackNavigation } from "./BackNavigation";
import { useState } from "react";
import { AsText } from "ui/common-styles";
import { MAIN_URL } from "util/consts";
import { UserAvatarView } from "components/shared/UserAvatarView";
import { Icon } from "assets/icons/Icon";
import { T } from "util/Translation";

import * as S from "./styles";
import { Button } from "semantic-ui-react";

export const MobileHeader = observer(() => {
  const [isOpen, setIsOpen] = useState(false);
  const [indexActive, setIndexActive] = useState(0);

  return (
    <>
      <S.HeaderWrapper onClick={() => setIsOpen(false)}>
        <BackNavigation />
        <Button
          basic
          className="ls-icon"
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen((prev) => !prev);
          }}
        >
          {isOpen ? <Icon name={"close"} /> : <Icon name={"menuBar"} />}
        </Button>
      </S.HeaderWrapper>
      {isOpen && <S.MobileOverlay />}
      <S.MobileNavigationWrapper style={{ left: isOpen ? 0 : "100%" }}>
        <NavigationBox
          isOpen={indexActive === 0}
          handleClose={() => setIndexActive((prev) => (prev === 0 ? null : 0))}
          handleCloseNavigation={() => setIsOpen(false)}
        />
        <AccountBox
          isOpen={indexActive === 1}
          handleClose={() => setIndexActive((prev) => (prev === 1 ? null : 1))}
          handleCloseNavigation={() => setIsOpen(false)}
        />
      </S.MobileNavigationWrapper>
    </>
  );
});

const NavigationBox = ({ isOpen, handleClose, handleCloseNavigation }) => {
  const location = useLocation();

  return (
    <S.BoxWrapper className={isOpen ? "ls-open" : "ls-close"}>
      <AsText className="ls-header" onClick={handleClose}>
        <h3>
          <Icon name="list" />
          <T id="navigation.title" />
        </h3>
        <Icon name="right" />
      </AsText>
      <div className="ls-body">
        <AsText
          as={Link}
          to="/"
          onClick={handleCloseNavigation}
          className={
            location.pathname === "" || location.pathname === "/"
              ? "ls-active"
              : ""
          }
        >
          <p>
            <Icon name="home" />
            <T id="navigation.home" />
          </p>
          <Icon name="right" />
        </AsText>
        <AsText
          as={Link}
          to="/projects"
          onClick={handleCloseNavigation}
          className={
            location.pathname.startsWith("/project") ? "ls-active" : ""
          }
        >
          <p>
            <Icon name="tactic" />
            <T id="navigation.projects" />
          </p>
          <Icon name="right" />
        </AsText>
        <AsText
          as={Link}
          to="/media"
          onClick={handleCloseNavigation}
          className={location.pathname === "/media" ? "ls-active" : ""}
        >
          <p>
            <Icon name="gallery" />
            <T id="navigation.media" />
          </p>
          <Icon name="right" />
        </AsText>
        <AsText
          as={Link}
          to="/submissions"
          onClick={handleCloseNavigation}
          className={location.pathname === "/submissions" ? "ls-active" : ""}
        >
          <p>
            <Icon name="send" />
            <T id="navigation.replies" />
          </p>
          <Icon name="right" />
        </AsText>
        <AsText
          as={Link}
          to="/statistics"
          onClick={handleCloseNavigation}
          className={location.pathname === "/statistics" ? "ls-active" : ""}
        >
          <p>
            <Icon name="chartBar" />
            <T id="navigation.statistics" />
          </p>
          <Icon name="right" />
        </AsText>
        <AsText
          as={Link}
          to={{ pathname: `${MAIN_URL}help-center/` }}
          target="_blank"
          onClick={handleCloseNavigation}
        >
          <p>
            <Icon name="helpCenter" />
            <T id="navigation.help_center" />
          </p>
          <Icon name="right" />
        </AsText>
      </div>
    </S.BoxWrapper>
  );
};

const AccountBox = ({ isOpen, handleClose, handleCloseNavigation }) => {
  const history = useHistory();
  const location = useLocation();
  const { accountStore } = useStore();

  const logout = async () => {
    await accountStore.logout();
    history.push("/login");
  };
  return (
    <S.BoxWrapper className={isOpen ? "ls-open" : "ls-close"}>
      <AsText className="ls-header" onClick={handleClose}>
        <h3>
          <UserAvatarView />
          <T id="user_button.account" />
        </h3>
        <Icon name="right" />
      </AsText>
      <div className="ls-body">
        <AsText
          as={Link}
          to="/account"
          onClick={handleCloseNavigation}
          className={location.pathname.startsWith("account") ? "ls-active" : ""}
        >
          <p>
            <Icon name="profile" />
            <T id="user_button.manage" />
          </p>
          <Icon name="right" />
        </AsText>

        <AsText as={Link} to="/" onClick={handleCloseNavigation}>
          <p>
            <Icon name="support" />
            <T id="user_button.support" />
          </p>
          <Icon name="right" />
        </AsText>
        <AsText
          onClick={() => {
            accountStore.setIsLangModalOpen(true);
            handleCloseNavigation();
          }}
        >
          <p>
            <Icon name="lang" />
            <T id="user_button.language" />
          </p>
          <Icon name="right" />
        </AsText>
        <AsText as={Link} to="/" onClick={logout}>
          <p>
            <Icon name="signOut" />
            <T id="user_button.sign_out" />
          </p>
          <Icon name="right" />
        </AsText>
      </div>
    </S.BoxWrapper>
  );
};
