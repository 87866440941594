import Lottie from "react-lottie";
import lumoMainLoader from "assets/lotties/lumo-loader";
import lumoLoader from "assets/lotties/icon-constant-primary";
import { FullPageWrapper, Wrapper } from "./styles";

export const Loading = ({ text }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lumoLoader,
  };

  return (
    <Wrapper>
      {text && <p>{text}</p>}
      <Lottie options={defaultOptions} height={88} width={180} />
    </Wrapper>
  );
};

export const FullPageLoading = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: lumoMainLoader,
  };

  return (
    <FullPageWrapper>
      <Lottie options={defaultOptions} height={88} width={180} />
    </FullPageWrapper>
  );
};
