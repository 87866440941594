import { FieldButtonSelect } from "ui/FieldButtonSelect";
import { multiValidators, required } from "util/validators";
import * as S from "./styles";

export const ButtonSelect = ({ blockId, data }) => {
  if (!data.options) return null;

  const validatorsArr = data.required ? [required] : [];

  const options = (data.options || []).map((option, idx) => ({
    key: `${idx}-${option}`,
    value: option,
    content: option,
  }));

  return (
    <S.Block>
      {data.field && (
        <p className={"lf-field" + (data.required ? " lf-required" : "")}>
          {data.field}
        </p>
      )}
      {data.desc && <p>{data.desc}</p>}

      <div>
        <FieldButtonSelect
          finalFormProps={{
            name: `${blockId}`,
            validate: multiValidators(validatorsArr),
          }}
          options={options}
          multiple={data.selection === "multi"}
        />
      </div>
    </S.Block>
  );
};
