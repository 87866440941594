import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

import { T } from "util/Translation";
import { MessagePage } from "components/shared/pageLayouts/MessagePage";

export const SubscriptionCanceled = () => {
  return (
    <MessagePage>
      <h2>
        <T id="subscription_failed.canceled_title" />
      </h2>
      <h5>
        <T id="subscription_failed.canceled_message" />
      </h5>
      <div className="ls-info">
        <p>
          <T id="subscription_failed.what_can_do" />
        </p>
        <ul>
          <li>
            <T id="subscription_failed.op1" />
          </li>
          <li>
            <T id="subscription_failed.op2" />
          </li>
        </ul>
      </div>
      <br />
      <p>
        <T id="subscription_failed.note" />
      </p>
      <Button as={Link} to="/" primary size="big">
        <T id="subscription_failed.go_back_home" />
      </Button>
    </MessagePage>
  );
};
