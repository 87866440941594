import { FieldCheckbox } from "ui/FieldCheckbox";
import * as S from "./styles";
import { multiValidators, requiredCheck } from "util/validators";

export const Checkbox = ({ blockId, data }) => {
  const validatorsArr = data.required ? [requiredCheck] : [];
  return (
    <S.Block>
      <FieldCheckbox
        finalFormProps={{
          name: blockId,
          validate: multiValidators(validatorsArr),
        }}
        semanticProps={{
          label: (
            <label>
              <span className={data.required ? "lf-required-sign" : ""}>
                {data.field}
              </span>
            </label>
          ),
          ...(data.type === "toggle" ? { toggle: true } : {}),
        }}
      />
    </S.Block>
  );
};
