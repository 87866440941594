import { Link, useHistory } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { T } from "util/Translation";

import { useStore } from "structure";
import { Dropdown } from "semantic-ui-react";
import { Icon } from "assets/icons/Icon";
import { UserAvatarView } from "components/shared/UserAvatarView";

import { useTranslation } from "react-i18next";

import * as S from "./styles";

export const UserButton = observer(() => {
  const history = useHistory();
  const { accountStore } = useStore();
  const { currentAccount } = accountStore;
  const { t } = useTranslation();

  const logout = async () => {
    await accountStore.logout();
    history.push("/login");
  };

  return (
    <Dropdown
      trigger={
        <S.UserButtonWrapper>
          <UserAvatarView />
          <span>
            <T id="user_button.account" />
          </span>
        </S.UserButtonWrapper>
      }
      loading={currentAccount?.isLoading}
      floating
      direction="left"
      icon={null}
    >
      <Dropdown.Menu>
        <Dropdown.Item
          text={t("user_button.manage")}
          icon={<Icon name="profile" height={20} />}
          as={Link}
          to="/account"
        />
        <Dropdown.Item
          text={t("user_button.support")}
          icon={<Icon name="support" height={20} />}
          as={Link}
          to="/"
        />
        <Dropdown.Item
          text={t("user_button.language")}
          icon={<Icon name="lang" height={20} />}
          onClick={() => accountStore.setIsLangModalOpen(true)}
        />
        <Dropdown.Item
          text={t("user_button.sign_out")}
          icon={<Icon name="signOut" height={20} />}
          onClick={logout}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
});
