import { Field, useFormState } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";

import { useTranslation } from "react-i18next";

import { TextAreaWrapper } from "ui/common-styles";
import * as S from "./styles";

export const TextArea = ({ blockId }) => {
  const { t } = useTranslation();
  const values = useFormState()?.values?.[blockId]?.["tool-3"] || {};

  return (
    <S.Block>
      <Field name={`${blockId}.tool-3.field`} initialValue={values.field || ""}>
        {(props) => (
          <TextareaAutosize
            name={props.input.name}
            value={props.input.value}
            onChange={props.input.onChange}
            className="ls-contentEditable"
            placeholder={t("block.textarea.field_placeholder")}
          />
        )}
      </Field>
      <Field name={`${blockId}.tool-3.desc`} initialValue={values.desc || ""}>
        {(props) => (
          <S.Field>
            <TextareaAutosize
              name={props.input.name}
              value={props.input.value}
              onChange={props.input.onChange}
              className="ls-contentEditable"
              placeholder={t("block.textarea.desc_placeholder")}
            />
          </S.Field>
        )}
      </Field>
      <TextAreaWrapper>
        <TextareaAutosize
          name={`${blockId}-preview`}
          placeholder={values.placeholder}
          minRows={values.rows || 4}
        />
      </TextAreaWrapper>
    </S.Block>
  );
};
