import { Checkbox } from "semantic-ui-react";
import { Field } from "react-final-form";
import { FormField } from "./common-styles";
import { useEffect, useState } from "react";

export const FieldCheckbox = ({
  finalFormProps,
  semanticProps,
  label,
  initialChecked,
}) => {
  const [isChecked, setIsChecked] = useState(initialChecked || false);

  useEffect(() => {
    if (finalFormProps?.value === "") {
      setIsChecked(initialChecked || false);
    }
  }, [finalFormProps?.value, initialChecked]);

  return (
    <Field {...finalFormProps} value={isChecked}>
      {({ input, meta }) => {
        const hasError =
          meta.touched &&
          // (meta.error?.[input.name] || meta.submitError?.[input.name]);
          (meta.error || (meta.submitError && !meta.modifiedSinceLastSubmit));

        return (
          <FormField>
            <Checkbox
              label={label}
              name={input.name}
              error={hasError ? meta.error || meta.submitError : null}
              onChange={(e, data) => {
                setIsChecked(data.checked);
                input.onChange(data.checked);
              }}
              checked={input.value}
              {...semanticProps}
            />
            {hasError && (
              <p className="ls-error">
                {meta.error || meta.submitError?.[input.name]}
              </p>
            )}
          </FormField>
        );
      }}
    </Field>
  );
};
