import styled from "styled-components";
import { BasicButton } from "ui/common-styles";

export const SideNavigation = styled.aside`
  height: 100%;
  width: 200px;
  background: var(--grey-50);
  border-right: 1px solid var(--secondary-200);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .ls-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 32px !important;
    width: 100%;
  }

  ul {
    list-style-type: none;
    padding-inline-start: unset;
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 32px 0;

    li {
      display: flex;
      width: 100%;
      a {
        padding: 12px 32px;
        display: flex;
        gap: 8px;
        width: 100%;
        font-size: 16px;
        font-family: var(--title-font);
        line-height: 24px;
        color: var(--grey-800);
        text-decoration: none;
        font-weight: 500;
        transition: 0.3s;

        & > svg {
          height: 24px;
          width: 24px;
          fill: var(--grey-700);
          transition: 0.2s;
        }

        &:hover > svg {
          margin-right: 12px;
        }

        &.active {
          color: var(--white);
          background: var(--secondary-500);
          font-weight: 700;

          > svg {
            fill: var(--grey-100);
          }
        }
      }
    }
  }

  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

export const UserButtonWrapper = styled(BasicButton)`
  padding: 12px 32px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;

  > img,
  .ls-avatar {
    height: 48px;
    width: 48px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: 400;
    background: var(--secondary-500);
    color: var(--secondary-50);
    object-fit: cover;
  }

  > span {
    font-weight: 600;
    font-family: var(--title-font);
    font-size: 18px;
  }

  @media (max-width: 700px) {
    padding: 0;
    margin: 0px 24px;

    > img,
    .ls-avatar {
      height: 36px;
      width: 36px;
      font-size: 14px;
    }

    > span {
      display: none;
    }
  }
`;
