import { useEffect } from "react";
import { useHistory, Route, Switch } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "structure";
import { Navigation } from "./Navigation";
import { Home } from "./Home";
import { Project } from "./Project";
import { CreateForm } from "./CreateForm";

import { Container } from "./styles";
import { Statistics } from "./Statistics";
import { Media } from "./Media";
import { Projects } from "./Projects";
import { Submissions } from "./Submissions/Submissions";
import { Loading } from "components/shared/Loading";
import { Sidebar } from "components/shared/Sidebar";
import { Upgrade } from "./Account/innerPages/Upgrade";
import { Payment } from "./Account/innerPages/Payment";
import { Account } from "./Account";
import { ErrorPage } from "components/ErrorPage";
import { FullPageLoading } from "components/shared/Loading/Loading";
import { LangModal } from "./LangModal";
import { useTranslation } from "react-i18next";
import { MobileHeader } from "./MobileHeader";

export const Dashboard = observer(() => {
  const { i18n } = useTranslation();
  const history = useHistory();
  const { accountStore } = useStore();
  const { currentAccount, isFirstLoading } = accountStore;

  useEffect(() => {
    if (!currentAccount && !isFirstLoading) {
      history.push("/login");
    }
    // eslint-disable-next-line
  }, [isFirstLoading]);

  if (!currentAccount) {
    return <FullPageLoading />;
  }

  return (
    <Sidebar>
      <Container key={i18n.resolvedLanguage}>
        <Navigation />
        <MobileHeader />
        <section>
          <div className="ls-main-content">
            {isFirstLoading ? (
              <Loading />
            ) : (
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/projects">
                  <Projects />
                </Route>
                <Route exact path="/project/:projectId">
                  <Project />
                </Route>
                <Route exact path="/project/:projectId/:partType/:partId">
                  <CreateForm />
                </Route>
                <Route exact path="/submissions">
                  <Submissions />
                </Route>
                <Route exact path="/statistics">
                  <Statistics />
                </Route>
                <Route exact path="/media">
                  <Media />
                </Route>
                <Route exact path="/account/upgrade">
                  <Upgrade />
                </Route>
                <Route exact path="/account/payment">
                  <Payment />
                </Route>
                <Route path="/account">
                  <Account />
                </Route>

                <Route path="*">
                  <ErrorPage />
                </Route>
              </Switch>
            )}
          </div>
        </section>
      </Container>
      <LangModal />
    </Sidebar>
  );
});
