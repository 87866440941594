import { useRef } from "react";
import { Field } from "react-final-form";
import { Icon } from "assets/icons/Icon";

import { T } from "util/Translation";

import { ButtonWithIcon, FormField } from "./common-styles";

export const FieldFile = ({
  label,
  finalFormProps,
  icon,
  btnText,
  btnProps = {},
  customOnChange,
}) => {
  const inputRef = useRef();

  return (
    <Field {...finalFormProps}>
      {({ meta, input }) => {
        const hasError =
          meta.touched &&
          (meta.error || (meta.submitError && !meta.modifiedSinceLastSubmit));

        return (
          <FormField>
            {typeof label === "string" ? <label>{label}</label> : label}
            <input
              type="file"
              ref={inputRef}
              style={{ display: "none" }}
              accept=".jpeg, .png, .jpg, .gif"
              onChange={(e) => {
                const file = e.target.files[0];
                input.onChange(file);
                customOnChange && customOnChange(file);
              }}
            />
            <ButtonWithIcon
              {...btnProps}
              type="button"
              onClick={() => inputRef?.current.click()}
            >
              {icon && <Icon name={icon} height={18} />}
              {btnText || <T id="field_file.upload_btn" />}
            </ButtonWithIcon>
            {hasError && (
              <p className="ls-error">{meta.error || meta.submitError}</p>
            )}
          </FormField>
        );
      }}
    </Field>
  );
};
