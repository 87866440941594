import ReactPlayer from "react-player";
import { PlayerWrapper } from "./common-styles";

export const Player = ({ url }) => {
  return (
    <PlayerWrapper>
      <ReactPlayer
        url={url}
        config={{ vimeo: { playerOptions: { controls: true } } }}
      />
    </PlayerWrapper>
  );
};
