import { Field } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";
import { Input, Checkbox } from "semantic-ui-react";

import { useTranslation } from "react-i18next";
import { T } from "util/Translation";

import * as S from "./styles";

export const TextAreaSettings = ({ blockId }) => {
  const { t } = useTranslation();

  return (
    <S.SectionWrapper>
      <li>
        <Field name={`${blockId}.tool-3.placeholder`}>
          {(props) => (
            <S.Field className="ls-field">
              <label>
                <T id="text_area_settings.placeholder" />
              </label>
              <TextareaAutosize
                name={props.input.name}
                value={props.input.value}
                onChange={props.input.onChange}
                className="ls-contentEditable"
                placeholder={t("text_area_settings.placeholder_optional")}
              />
            </S.Field>
          )}
        </Field>
      </li>
      <li>
        <Field name={`${blockId}.tool-3.required`}>
          {(props) => (
            <S.Field>
              <Checkbox
                name={props.input.name}
                checked={props.input.value || false}
                onChange={(e, data) => {
                  props.input.onChange(data.checked);
                }}
                label={t("text_area_settings.required")}
              />
            </S.Field>
          )}
        </Field>
      </li>
      <li>
        <Field name={`${blockId}.tool-3.rows`}>
          {(props) => (
            <S.Field>
              <label>
                <T id="text_area_settings.rows" />
              </label>
              <Input
                name={props.input.name}
                value={props.input.value || 4}
                onChange={props.input.onChange}
                type="number"
                min={1}
                max={30}
                step={1}
              />
            </S.Field>
          )}
        </Field>
      </li>
    </S.SectionWrapper>
  );
};
