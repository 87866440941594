import {
  footerWebsiteStyling,
  headerWebsiteStyling,
} from "components/shared/website/styles";
import styled from "styled-components";

export const PageWrapper = styled.div`
  > .ls-page-wrapper {
    width: 100%;
    max-width: 800px;
    display: flex;
    align-items: center !important;
    justify-content: center;
    gap: 24px;
    flex-direction: column;
    padding: 48px 24px;
    text-align: center;
  }

  .ls-ops {
    display: flex;
    flex-direction: row;
    gap: 24px;
    margin-top: 24px;
  }
`;

export const HeaderWrapper = styled.header`
  padding: 8px 24px;
  display: flex;
  justify-content: space-between;
  ${({ frame }) => headerWebsiteStyling(frame)}

  >div {
    width: fit-content;
  }
`;

export const FooterWrapper = styled.div`
  padding: 8px 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${({ frame }) => footerWebsiteStyling(frame)}

  .ls-links {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 16px;
    height: 100%;
    min-width: 50%;

    > .ls-empty {
      min-width: 100px;
    }

    ul {
      list-style-type: none;
      padding-inline-start: 0;
      margin: 8px;

      :first-child {
        margin-left: auto;
      }

      li {
        min-width: 100px;
      }
    }
  }

  @media (max-width: 700px) {
    flex-direction: column;

    .ls-links {
      min-width: 0;

      ul:first-child {
        margin-left: 0;
      }
    }
  }
`;

export const Title = styled.div`
  ${({ size }) =>
    size &&
    `
    h4 {
  line-height: ${size}px;
  font-size: ${size * 0.8}px;
    }
  img {
  height: ${size}px;
  
  }
`}
`;
