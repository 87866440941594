import { T } from "util/Translation";
import * as S from "./styles";
import { useEffect, useState } from "react";
import { useStore } from "structure";

export const Replies = () => {
  const { repliesStore } = useStore();
  const [data, setData] = useState(null);

  useEffect(() => {
    repliesStore.getUsage().then((res) => setData(res));
    // eslint-disable-next-line
  }, []);

  const status =
    data?.percentage_used < 50
      ? "good"
      : data?.percentage_used < 80
      ? "warning"
      : "bad";

  return (
    <>
      <S.CountWrapper className="ls-center">
        {data ? (
          <>
            <h2>{data?.current_usage}</h2>
            <p>
              <T id="dashboard.home.replies.total" />
            </p>
          </>
        ) : (
          <div className="ls-loader"></div>
        )}
      </S.CountWrapper>
      <S.CountWrapper>
        {data ? (
          <>
            <h3 className={`ls-${status}`}>
              {data.percentage_used}%{" "}
              <span>
                <T id="dashboard.home.replies.left" />
              </span>
            </h3>
            <p>
              <T
                id="dashboard.home.replies.limit"
                values={{ limit: data.max_limit }}
                components={{ br: <br /> }}
              />
            </p>
          </>
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="ls-loader"></div>
          </div>
        )}
      </S.CountWrapper>
    </>
  );
};
