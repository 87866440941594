import { observer } from "mobx-react-lite";
import { useStore } from "structure";
import { API_URL } from "util/consts";

export const UserAvatarView = observer(() => {
  const { accountStore } = useStore();
  const { currentAccount } = accountStore;

  if (currentAccount?.avatar) {
    const src = currentAccount.avatar?.includes("https://")
      ? currentAccount.avatar
      : `${API_URL}media/${currentAccount.avatar}`;
    return <img src={src} referrerPolicy="no-referrer" alt="avatar" />;
  }

  return (
    <span className="ls-avatar">{currentAccount?.username?.slice(0, 2)}</span>
  );
});
