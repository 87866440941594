import { MediaGalleryModal } from "components/shared/MediaGalleryModal";
import { Field } from "react-final-form";
import { InputImage } from "./common-styles";
import { useTranslation } from "react-i18next";
import imgPlaceholder from "assets/imgs/imgPlaceholder.png";

export const FieldImage = ({ label, finalFormProps }) => {
  const { t } = useTranslation();

  return (
    <Field {...finalFormProps}>
      {(props) => (
        <>
          {label && <label>{label}</label>}
          <InputImage>
            <input
              name={props.input.name}
              value={props.input.value}
              onChange={props.input.onChange}
              type="hidden"
            />
            <MediaGalleryModal
              onSelect={(data) => {
                props.input.onChange(data);
              }}
              initialValue={props.input.value}
              trigger={
                <img
                  className="ls-input-element"
                  alt={t("image_settings.alt_text")}
                  src={
                    Array.isArray(props.input.value) &&
                    props.input.value.length > 0
                      ? props.input.value
                      : imgPlaceholder
                  }
                  width="100%"
                />
              }
            />
          </InputImage>
        </>
      )}
    </Field>
  );
};
