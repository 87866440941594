import styled from "styled-components";
import { scrollElement } from "ui/common-styles";
import bottom from "assets/illustrations/bottom.svg";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .main-section {
    padding-bottom: 32px;
  }

  .ui.button.ls-nav-back {
    position: fixed;
    top: 16px;
    left: 4px;
    width: 50%;
    color: var(--grey-600) !important;
    background: var(--grey-50) !important;
    z-index: 11;
    display: none;
  }

  .ls-filters {
    padding: 16px 48px;
    border-top: 1px solid var(--grey-200);
    border-bottom: 1px solid var(--grey-200);
    background: var(--grey-50);
    position: sticky;
    top: 0;

    form {
      display: grid;
      grid-template-columns: 200px 200px 200px;
      gap: 24px;
      align-items: end;

      > div {
        margin-bottom: 0;
      }
    }

    .ls-actions {
      display: flex;
      gap: 10px;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  .ui.table {
    border-radius: 8px;
    max-width: 600px;

    thead tr {
      th:first-child {
        border-radius: 8px 0 0 0;
      }
      th:last-child {
        border-radius: 0 8px 0 0;
      }
    }
  }

  @media (max-width: 700px) {
    .ui.button.ls-nav-back {
      display: flex;
    }
  }
`;

export const SidebarContent = styled.div`
  flex-grow: 1;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--grey-200);
  min-height: 100%;
  border-radius: 8px;

  > div {
    border-top: 1px solid var(--grey-200);
    padding: 32px;
    margin: -32px;
  }

  .ls-form-response {
    list-style-type: none;
    padding-inline-start: 0;
    display: flex;
    gap: 20px;
    margin: 0;
    overflow: auto;
    flex-direction: column;
    animation: fadeIn 0.3s;

    > li {
      > span {
        font-size: 1.1em;
        font-weight: 700;
      }
      .project-reply-content {
        padding-inline-start: 0;

        > li {
          display: flex;
          gap: 16px;
          > span:first-child {
            font-weight: 500;
            min-width: 100px;
            max-width: 180px;
          }
          > span:last-child {
            min-width: 200px;
            max-width: 400px;
          }
        }
      }

      .project-reply-meta {
        display: flex;
        flex-wrap: wrap;
        gap: 8px 30px;
        padding-inline-start: 0;
        list-style-type: disc;

        li {
          color: var(--grey-500);
          text-wrap: nowrap;

          span {
            background: var(--secondary-100);
            color: var(--secondary-800);
            font-weight: 600;
            padding: 1px 8px;
            border-radius: 4px;
            margin-left: 4px;
          }
        }
      }

      &.project-reply-title.project-reply-title {
        padding-bottom: 32px;
        border-bottom: 1px solid var(--secondary-300);
      }

      :not(:last-child) {
        padding-bottom: 20px;
        border-bottom: 1px solid var(--secondary-200);
      }
    }
  }
`;

export const PanelWrapper = styled.div`
  display: flex;
  gap: 24px;
  margin: 24px 40px;
  background: white;
  border-radius: 8px;
  height: calc(100dvh - 336px);
  overflow: hidden;

  .ls-results {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid var(--grey-200);
    min-height: 100%;
    margin-bottom: 16px;
    width: clamp(260px, 40%, 400px);

    .ui.pagination.menu {
      justify-content: center;
      gap: 8px;
      border: none;
      box-shadow: none;
      padding: 8px;
      background: var(--secondary-50);
      border-top: 1px solid var(--secondary-200);
      border-radius: 0;

      .item {
        font-weight: 500;
        font-family: var(--title-font);
        padding: 8px;
        border-radius: 4px !important;
        height: 40px;
        min-width: 40px;
        border: none;
        color: var(--grey-700);
        justify-content: center;
        box-shadow: none;

        :before {
          width: 0;
        }

        :hover {
          background: var(--secondary-200);
        }

        &.active {
          background: var(--secondary-500);
          color: white;
        }

        svg {
          fill: var(--grey-700);
        }

        &[type="prevItem"] {
          margin-right: auto;
        }

        &[type="nextItem"] {
          margin-left: auto;
        }
      }
    }

    > ul {
      display: flex;
      flex-direction: column;
      padding-inline-start: 0;
      list-style-type: none;
      margin: 0;
      height: calc(100% - 56px);
      overflow: auto;

      ${scrollElement}
    }

    > .ls-empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-image: url(${bottom});
      background-position: bottom;
      background-size: cover;
      background-repeat: no-repeat;
      > h4 {
        margin-bottom: 60%;
      }
    }

    .ls-info {
      border-top: 1px solid var(--grey-500);
      padding-top: 4px;
      margin-top: 16px;
    }
  }

  @media (max-width: 700px) {
    margin: 0;
    .ls-results {
      width: 100%;
      border-radius: 0;
      border: none;

      .ui.pagination.menu {
        position: fixed;
        bottom: env(safe-area-inset-bottom);
        width: 100%;
        border-top: 1px solid var(--secondary-600);
      }

      .ls-active button {
        background: transparent;
      }
    }

    .ls-dynamic-view {
      position: fixed;
      right: -100vw;
      top: 54px;
      background: var(--grey-50);
      width: 100%;
      border-radius: 0;
      border: none;
      transition: 0.3s;

      &.ls-active-view {
        right: 0;
      }
    }
  }
`;

export const SubmissionItemWrapper = styled.div`
  :not(:last-child) {
    border-bottom: 1px solid var(--grey-200);
  }

  &.ls-active button {
    &,
    :hover {
      background: var(--grey-100);
    }
  }

  button {
    background: transparent;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 32px;
    border-radius: 0;
    gap: 8px;

    :hover {
      opacity: 1;
      background: var(--grey-50);
    }

    h5 {
      font-size: 1.1em;
      text-transform: capitalize;
      margin-bottom: 0;
    }

    .ls-details {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: flex-end;
      width: 100%;

      span:first-child {
        font-size: 0.9em;
      }

      span:last-child {
        font-size: 0.8em;
      }
    }
  }
`;
