import { Button } from "semantic-ui-react";
import styled from "styled-components";
import b1 from "assets/illustrations/b1.svg";
import { ActionsWrapper } from "components/Dashboard/Project/Panes/styles";

export const Title = styled.h1`
  text-align: center;
  padding: 12px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const BasicButton = styled.button`
  background: transparent;
  border: 0px;
  cursor: pointer;
  font-family: inherit;
  color: inherit;

  :hover {
    background: transparent;
    color: inherit;
    opacity: 0.8;
  }
`;

export const AsLink = styled.button`
  background: transparent;
  border: 0px;
  cursor: pointer;
  font-family: inherit;
  color: var(--secondary-500);
  font-size: 16px;
  padding: 0;
  text-decoration: underline;

  :hover {
    color: var(--secondary-700);
  }
`;

export const AsText = styled.button`
  padding: 0px;
  border: 0px;
  font-family: inherit;
  background: transparent;
  color: inherit;
  display: flex;
  align-items: center;
  gap: 2px;

  &:hover {
    background: transparent;
    color: inherit;
    opacity: 0.8;

    svg {
      opacity: 0.8;
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
    &:hover {
      opacity: 0.5;

      svg {
        opacity: 1;
      }
    }
  }

  svg {
    height: 1.4em;
    width: 1.4em;
  }
`;

export const ButtonWithIcon = styled(Button)`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
`;

export const scrollElement = `
  ::-webkit-scrollbar {
    width: 24px; 
    border-radius: 16px;
  }
  
  ::-webkit-scrollbar-track {
    background: var(--grey-300);
    border: 8px solid transparent; 
    background-clip: content-box;
    border-radius: 16px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: var(--grey-500);
    border-radius: 8px; 
    border: 8px solid transparent; 
    background-clip: content-box;
    border-radius: 16px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--grey-700);
    border-radius: 8px; 
    border: 8px solid transparent; 
    background-clip: content-box;
    border-radius: 16px;
  }
  `;

export const hiddenScroll = `
  ::-webkit-scrollbar {
    width: 8px; 
  }
  
  ::-webkit-scrollbar-track {
    background: transparent;
    background-clip: content-box;
  }
  
  ::-webkit-scrollbar-thumb {
    background: var(--grey-500);
    border-right: 4px solid white; 
    border-top: 4px solid white; 
    border-bottom: 4px solid white; 
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: var(--grey-700);
    border-top: 4px solid white; 
    border-right: 4px solid white; 
    border-bottom: 4px solid white; 
    background-clip: content-box;
  }
  `;

export const CaruselWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 16px 16px 8px;
  border-radius: 4px;

  .alice-carousel__dots-item:not(.__custom) {
    height: 12px;
    width: 12px;
    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  .alice-carousel__prev-btn-wrapper,
  .alice-carousel__next-btn-wrapper {
  }

  .alice-carousel__prev-btn,
  .alice-carousel__next-btn {
    padding: 0;
    height: 30px;
    width: 30px;
    cursor: pointer;

    position: absolute;
    top: calc(50% - 28px);

    border-radius: 4px;
    background-color: #6e7ebc;
    background-position: 50% 50%;
    background-repeat: no-repeat;

    z-index: 1;
  }

  .alice-carousel__dots {
    margin-top: 8px;
  }

  .alice-carousel__prev-btn {
    background-image: url('data:image/svg+xml;charset=utf-8,<svg width="18" height="18" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg"><title>Shape</title><path d="M33.6 14.7H8.043L19.782 2.961 16.8 0 0 16.8l16.8 16.8 2.961-2.961L8.043 18.9H33.6z" fill="%23FFF" fill-rule="evenodd"/></svg>');
    left: -10px;
  }

  .alice-carousel__next-btn {
    background-image: url('data:image/svg+xml;charset=utf-8,<svg width="18" height="18" viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg"><title>Shape</title><path d="M25.557 14.7L13.818 2.961 16.8 0l16.8 16.8-16.8 16.8-2.961-2.961L25.557 18.9H0v-4.2z" fill="%23FFF" fill-rule="evenodd"/></svg>');
    right: -10px;
  }

  .alice-carousel__next-btn [data-area]::after,
  .alice-carousel__prev-btn [data-area]::after {
    content: "";
  }

  .alice-carousel__prev-btn-wrapper,
  .alice-carousel__next-btn-wrapper,
  .alice-carousel__prev-btn-item,
  .alice-carousel__next-btn-item {
    width: 100%;
    height: 100%;

    &.__inactive {
      background: white;
      border-radius: 4px;
      opacity: 0.7;
    }
  }
`;

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  .ui.input input:focus,
  textarea:focus {
    background: var(--white);
  }

  .ui.checkbox[error] {
    input ~ label::before {
      border: 1px solid var(--red-200);
      background: var(--red-50);
    }
  }

  &.error textarea {
    border: 1px solid var(--red-200);
    background: var(--red-50);
  }

  .ls-error {
    color: var(--red-500);
  }
`;

export const FitContentWrapper = styled.div`
  width: fit-content;
  height: fit-content;
`;

export const GalleryItem = styled(FitContentWrapper)`
  padding: 8px;
  img {
    object-fit: cover;
    border-radius: 8px;
  }
`;

export const PlayerWrapper = styled.div`
  width: 100%;
  > div {
    background: black;
    max-width: calc(100vw - 120px);
    border-radius: 16px;
    overflow: hidden;
  }
`;

export const GeneralEdit = styled.div`
  h4 {
    font-size: 24px;
    color: var(--grey-800);
    line-height: 1.2;
    margin-bottom: 4px;
  }

  p {
    color: var(--grey-600);
  }

  form,
  .ls-main-block {
    .ls-actions {
      padding: 0;
      > .button {
        margin: 0;
      }
    }
  }

  &:focus,
  &:focus-within,
  &:focus-visible {
    ${ActionsWrapper} {
      display: flex;
    }
  }
`;

export const TextAreaWrapper = styled(FormField)`
  width: 100%;
  > textarea {
    margin: 0;
    width: 100%;
    max-width: 100%;
    flex: 1 0 auto;
    outline: 0;
    text-align: left;
    line-height: 1.21428571em;
    font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
    padding: 0.67857143em 1em;
    background: #fff;
    border: 1px solid rgba(34, 36, 38, 0.15);
    color: rgba(0, 0, 0, 0.87);
    border-radius: 4px;
    transition: box-shadow 0.1s ease, border-color 0.1s ease;
    box-shadow: none;
    resize: none;
  }
`;

export const TopMenu = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 40px;
  border-bottom: 1px solid var(--grey-200);
  height: 54px;
  min-height: 54px;
  position: sticky;
  top: 0px;
  background: var(--white);
  z-index: 1;

  .nav {
    .ui.button {
      padding: 8px;
      color: var(--grey-600) !important;
      margin-left: -12px;

      :hover {
        color: var(--grey-700) !important;
      }
    }
  }

  ul {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-inline-start: 0;
    list-style-type: none;
    margin: 0;

    li {
      display: flex;
      align-items: center;
      fill: var(--grey-400);
    }
  }

  + .main-section {
    padding-top: 32px;
  }

  @media only screen and (max-width: 700px) {
    height: 0;
    min-height: 0;
    padding: 0;
    z-index: 11;
    border: none;

    .nav {
      position: fixed;
      top: 16px;
      left: 16px;
      width: 50%;
      background: var(--grey-50) !important;
      z-index: 11;
    }

    .ls-actions-menu {
      padding: 16px 32px;
      width: 100%;
      grid-template-columns: 1fr 40px;
      position: fixed;
      left: 200px;
      bottom: env(safe-area-inset-bottom);
      display: none;
      gap: 16px;
      background: #ffffff88;
      box-shadow: var(--shadow-200);
      backdrop-filter: blur(8px);
      border-top: 1px solid var(--grey-200);

      &.ls-two-actions {
        grid-template-columns: 1fr 1fr 40px;
      }

      .ui.button {
        justify-content: center;
      }

      li {
        flex-direction: column;
        align-items: stretch;
      }
    }
  }

  @media (max-width: 700px) {
    .ls-actions-menu {
      display: grid;
      left: 0px;
      width: 100%;
    }
  }
`;

export const ModalContentWrapper = styled.div`
  display: flex;
  padding: 32px 36px;
  flex-direction: column;
  gap: 24px;
  align-items: stretch;

  h3 {
    text-align: center;
    color: var(--grey-800);
  }

  p,
  label,
  input {
    font-size: 16px;
    text-align: center !important;
    color: var(--grey-800);
    text-wrap-style: pretty;
  }

  label {
    margin-bottom: 4px;
  }

  .ls-mark {
    background: var(--grey-100);
    padding: 16px 24px;
    border-radius: 8px;
    > div:last-child {
      margin-bottom: 0;
    }
  }

  .ls-content {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .ls-actions {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .ui.button {
      font-family: var(--font-regular);
    }
  }

  .ls-options {
    display: grid;
    gap: 16px;

    .ui.button {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      padding: 12px 24px;
      justify-content: space-between;
    }
  }
`;

export const ModalCloseWrapper = styled(AsText)`
  padding: 16px 12px;
  float: right;
  position: absolute;
  right: 0;
  svg {
    fill: var(--grey-800);
  }
`;

export const ExistFieldIndicator = styled.div`
  position: absolute;
  right: 6px;
  color: var(--red-600);
  display: flex;
  align-items: center;
  padding: 5px;
  z-index: 1;
  margin-top: 28px;
  ${({ exist }) =>
    exist
      ? `
  fill: var(--red-600);
  `
      : `
  fill: var(--green-600);
  `}
`;

export const PaymentSummaryWrapper = styled.ul`
  padding-inline-start: 0;
  margin: 0 0 8px;
  border-bottom: 1px solid var(--grey-200);

  li {
    display: grid;
    grid-template-columns: 1fr 80px;
    gap: 24px;

    span {
      font-weight: 600;
      color: var(--secondary-600);
      margin: 0 4px;
    }

    &.ls-sum > p {
      font-weight: 700;
    }
  }
`;

export const PlanOptionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;

  .ui.button {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid var(--grey-200);
    background: white;
    color: var(--grey-500);
    width: 200px;
    box-shadow: var(--shadow-200);

    .price {
      color: var(--grey-800);
      display: flex;
      align-items: flex-end;
      gap: 2px;
      height: 48px;
      margin: 16px 0 0;
      > span {
        font-weight: 700;
      }
      .p-s {
        font-size: 16px;
        line-height: 2;
      }

      .p-m {
        font-size: 32px;
        line-height: 1.2;
      }

      .p-l {
        font-size: 48px;
        line-height: 1;
      }
    }

    h3 {
      margin: 0;
      line-height: 1;
      color: var(--secondary-600);
    }

    h5 {
      margin: 0;
      color: var(--secondary-500);
    }

    .notes {
      height: 48px;
      align-content: center;
    }

    :focus {
      background: var(--white);
    }

    &.ls-selected {
      border: 1px solid var(--tertiary-500);
    }
  }
`;

export const EmptyWrapper = styled.div`
  min-height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${b1});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  > h4 {
    padding: 16px 48px;
    border-radius: 44px;
    backdrop-filter: blur(3px);
    background: #ffffff95;
    filter: drop-shadow(0px 0px 8px white);
  }
`;

export const InputImage = styled.div`
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  .ls-input-element {
    border: 1px solid transparent;
    height: 80px;
    cursor: pointer;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  .ls-input-element:hover {
    border-color: #94c0ca;
  }
  .ls-input-element:focus-visible {
    border-color: #31a5be;
  }
`;

export const ButtonsSelect = styled.div`
  ${({ columns }) => {
    if (columns === 0) {
      return `
    display: flex;
    flex-wrap: wrap;
    `;
    } else {
      return `
    display: grid;
    grid-template-columns: repeat(${columns || 2}, 1fr);
    `;
    }
  }}

  gap: 8px;

  .ui.button {
    height: 40px;
  }

  .ui.ui.ui.button.ls-active {
    &,
    &:hover {
      background: var(--primary-50);
      border-color: var(--primary-500);
      color: var(--primary-700);
    }
  }
`;
