import styled from "styled-components";

export const Container = styled.div`
  height: calc(100dvh - 1px);
  flex-direction: row;
  display: flex;
  align-items: center;
  background-color: var(--white);

  & > section {
    width: calc(100vw - 200px);
    height: 100dvh;
    overflow: auto;

    .ls-main-content {
      height: 100%;
    }

    ::-webkit-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-track {
      background: var(--grey-300);
      background-clip: content-box;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--grey-500);
      border-radius: 26px;
      background-clip: content-box;
      border-radius: 16px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: var(--grey-700);
      border-radius: 8px;
      background-clip: content-box;
      border-radius: 16px;
    }
  }

  .ls-dashboard-panel {
    display: flex;
    flex: 1;
    width: 100%;
    & > div {
      padding: 32px 48px;
      overflow: auto;
    }
    & > div:first-child {
      width: 250px;
      background: #6e917d;
    }
    & > div:last-child {
      flex: 1;
    }
  }

  .main-section {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 48px 48px 20px;

    .header {
      display: flex;
      flex-direction: column;
      gap: 8px;
      h1 {
        color: var(--secondary-500);
      }
      p {
        text-wrap: pretty;
        max-width: 600px;
      }
    }
  }

  @media only screen and (max-width: 700px) {
    flex-direction: column;

    & > section {
      width: 100%;
      height: 100%;

      > .ls-main-content {
        padding-bottom: 120px;
        min-height: max-content;
      }

      ::-webkit-scrollbar {
        width: 0px;
      }
    }

    .main-section {
      padding: 32px 24px;
    }
  }
`;
