import { computed, makeObservable, observable, runInAction } from "mobx";
import { replies as apiReplies } from "../endpoints";

export class Replies {
  replies = [];
  paginationMeta = {
    current_page: 1,
  };
  isLoading = false;

  constructor(root) {
    this.rootStore = root;
    makeObservable(this, {
      replies: observable.ref,
      paginationMeta: observable.ref,
      isLoading: observable,
      isEmpty: computed,
    });
  }

  async fetchReplies(params = {}) {
    runInAction(() => {
      this.isLoading = true;
    });
    const res = await apiReplies.get(params);
    if (res.data) {
      runInAction(() => {
        this.replies = res.data;
        this.paginationMeta = res.meta;
      });
    }

    runInAction(() => {
      this.isLoading = false;
    });
  }

  getById(replyId) {
    return apiReplies.getById(replyId);
  }

  getUsage() {
    return apiReplies.usage();
  }

  getAnalytics(fitlers) {
    return apiReplies.analytics(fitlers);
  }

  get isEmpty() {
    return this.paginationMeta?.total === 0;
  }

  async delete(replyId) {
    const res = await apiReplies.delete(replyId);
    await this.fetchReplies();
    return res;
  }
}
