import { observer } from "mobx-react-lite";
import { useStore } from "structure";

import { Button } from "semantic-ui-react";
import { Icon } from "assets/icons/Icon";
import { useState } from "react";
import { Form } from "react-final-form";
import { FieldText } from "ui/FieldText";
import { required } from "util/validators";

import { useTranslation } from "react-i18next";
import { T } from "util/Translation";

import { ButtonWithIcon } from "ui/common-styles";
import * as S from "./styles";

export const PersonalDetails = observer(() => {
  const { t } = useTranslation();
  const { accountStore } = useStore();
  const { currentAccount } = accountStore;
  const [isEditing, setIsEditing] = useState(false);
  const onSubmit = async (data) => {
    await accountStore.updatePersonalDetails({
      first_name: data.first_name,
      last_name: data.last_name,
      personalData: {
        phone: data.phone || "",
        companyName: data.companyName || "",
        address: data.address || "",
      },
    });
    setIsEditing(false);
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <S.FieldsSectionWrapper>
              <h4>
                <T id="personal_details.title" />
              </h4>
              <ul>
                <li>
                  <label>
                    <T id="personal_details.username" />
                  </label>
                  <p>{currentAccount.username}</p>
                </li>

                <li>
                  <label>
                    <T id="personal_details.email" />
                  </label>
                  <p>{currentAccount.email}</p>
                </li>

                <li>
                  <label>
                    <T id="personal_details.first_name" />
                  </label>
                  {isEditing ? (
                    <FieldText
                      finalFormProps={{
                        name: "first_name",
                        initialValue: currentAccount.first_name,
                        validate: required,
                      }}
                      semanticProps={{
                        placeholder: t("personal_details.first_name"),
                      }}
                    />
                  ) : (
                    <p>{currentAccount.first_name}</p>
                  )}
                </li>

                <li>
                  <label>
                    <T id="personal_details.last_name" />
                  </label>
                  {isEditing ? (
                    <FieldText
                      finalFormProps={{
                        name: "last_name",
                        initialValue: currentAccount.last_name,
                        validate: required,
                      }}
                      semanticProps={{
                        placeholder: t("personal_details.last_name"),
                      }}
                    />
                  ) : (
                    <p>{currentAccount.last_name}</p>
                  )}
                </li>

                <li>
                  <label>
                    <T id="personal_details.company_name" />
                  </label>
                  {isEditing ? (
                    <FieldText
                      finalFormProps={{
                        name: "companyName",
                        initialValue: currentAccount.personal_data?.companyName,
                      }}
                      semanticProps={{
                        placeholder: t("personal_details.company_name"),
                      }}
                    />
                  ) : (
                    <p>{currentAccount.personal_data?.companyName}</p>
                  )}
                </li>

                <li>
                  <label>
                    <T id="personal_details.phone" />
                  </label>
                  {isEditing ? (
                    <FieldText
                      finalFormProps={{
                        name: "phone",
                        initialValue: currentAccount.personal_data?.phone,
                      }}
                      semanticProps={{
                        placeholder: t("personal_details.phone"),
                      }}
                    />
                  ) : (
                    <p>{currentAccount.personal_data?.phone}</p>
                  )}
                </li>

                <li>
                  <label>
                    <T id="personal_details.address" />
                  </label>
                  {isEditing ? (
                    <FieldText
                      finalFormProps={{
                        name: "address",
                        initialValue: currentAccount.personal_data?.address,
                      }}
                      semanticProps={{
                        placeholder: t("personal_details.address"),
                      }}
                    />
                  ) : (
                    <p>{currentAccount.personal_data?.address}</p>
                  )}
                </li>
              </ul>
              <div className="ls-actions">
                {isEditing ? (
                  <>
                    <Button
                      type="button"
                      className="ls-bright"
                      color="grey"
                      disabled={submitting}
                      onClick={() => setIsEditing(false)}
                    >
                      <T id="personal_details.cancel" />
                    </Button>

                    <Button type="submit" primary disabled={submitting}>
                      <T id="personal_details.save" />
                    </Button>
                  </>
                ) : (
                  <ButtonWithIcon
                    basic
                    primary
                    type="button"
                    onClick={() => setIsEditing(true)}
                  >
                    <Icon name="edit" height={18} />
                    <T id="personal_details.edit" />
                  </ButtonWithIcon>
                )}
              </div>
            </S.FieldsSectionWrapper>
          </form>
        );
      }}
    />
  );
});
