import styled, { createGlobalStyle } from "styled-components";
import { basicTemplate } from "./templates/basic";
import {
  createBackgroundStyle,
  createShadowStyle,
  fillColor,
} from "components/shared/website/styles";

export const GlobalStyle = createGlobalStyle`
  ${({ uniqueFonts }) => {
    return uniqueFonts
      .map(
        (font) => `
          @import url('https://fonts.googleapis.com/css2?family=${font.replace(
            / /g,
            "+"
          )}:wght@400;700&display=swap');
        `
      )
      .join("\n");
  }}
`;

export const Container = styled.div`
  ${({ customUI }) =>
    customUI?.global?.primaryColor
      ? fillColor("primary", customUI?.global?.primaryColor)
      : ""}

  display: flex;
  flex-direction: column;
  min-height: 100dvh;
  max-height: 100dvh;
  overflow: auto;
  gap: 32px;
  animation: fadeIn 0.4s;

  &,
  * {
    transition: width 0.3s, background 0.3s, color 0.3s, border 0.3s,
      filter 0.3s;
  }

  a {
    color: var(--primary-600);
    :hover {
      color: var(--primary-800);
    }
  }

  p {
    margin-bottom: 4px;
  }

  > div {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 16px;

    > form,
    .ls-page-wrapper {
      width: min(760px, 100%);
      margin: 48px auto;
      display: flex;
      flex-direction: column;
      -webkit-box-align: center;
      align-items: flex-start;
      padding: 48px 32px 60px;
      border-radius: 16px;
      gap: 48px;
    }
  }

  .lf-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    textarea,
    .ui.dropdown,
    .ui.input {
      min-width: min(200px, 100%);
    }

    .ui.input {
      width: min(400px, 100%);
    }

    .ls-error {
      color: var(--red-500) !important;
    }

    .error textarea {
      border: 1px solid var(--red-200) !important;
    }
  }

  .ls-actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 24px;
    align-items: flex-start;

    .ls-submit-button {
      display: flex;
      flex-direction: column;
      button {
        width: fit-content;
        margin-left: auto;
      }
    }
  }

  > footer {
    .ls-footer {
      padding: 8px 0;
      width: calc(100% - 64px);
      margin: 0 auto;
      padding-bottom: 8px;
    }

    .ls-lumo-pages-footer {
      display: flex;
      flex-wrap: wrap;
      padding: 8px 32px;
      gap: 16px 32px;
      align-items: center;
      justify-content: space-between;
      background: var(--grey-100);
      border-top: 1px solid var(--grey-300);

      a,
      p,
      span {
        font-family: var(--primary-font) !important;
        font-size: 14px !important;
        margin: 0;
      }
      > .lp-logo {
        margin-bottom: -8px;
        display: flex;
        align-items: flex-start;
        gap: 4px;
        span {
          line-height: 24px;
        }
      }
      a {
        color: var(--secondary-600) !important;
        :hover {
          color: var(--secondary-800) !important;
        }
      }

      ul {
        display: none;
        align-items: center;
        gap: 16px 28px;
        margin: 0;
        padding-inline-start: 0;
        list-style-color: var(--grey-400) !important;

        li {
          color: var(--grey-400) !important;
          :first-child {
            color: var(--grey-700) !important;
            list-style-type: none;
          }
        }
      }
    }
  }

  .ls-disclaimer {
    margin-top: -32px;
    margin-bottom: -16px;
    margin-left: auto;
    p {
      text-align: center;
      color: var(--grey-400);
    }
  }

  ${({
    customUI: { global = {}, input = {}, formFrame = {}, button = {} },
  }) => {
    let style = "";

    // Global styles
    if (global) {
      if (global.background) {
        style += createBackgroundStyle(global.background);
      }
      if (global.titleStyle) {
        style += `
        h1, h2, h3, h4, h5, h6 {
          font-family: ${global.titleStyle.font || "inherit"} !important;
          color: ${global.titleStyle.color} !important;
        }
        
        h1, h2, h3, h4 {
          color: ${global.titleStyle.color} !important;
        }
        `;
      }
      if (global.titleSize) {
        style += `
        h1, h2, h3, h4 {
          font-size: ${global.titleSize.size}${
          global.titleSize.unit
        } !important;
          font-weight: ${global.titleSize.weight || "normal"} !important;
        }
        `;
      }
      if (global.textStyle) {
        style += `
        p, span, li, label, input {
          font-family: ${global.textStyle.font || "inherit"} !important;
          color: ${global.textStyle.color} !important;
        }
        `;
      }
      if (global.textSize) {
        style += `
        p, span, li, label, input {
          font-size: ${global.textSize.size}${global.textSize.unit} !important;
          font-weight: ${global.textSize.weight || "normal"} !important;
        }
        `;
      }
    }

    if (formFrame) {
      if (formFrame.background) {
        let bg = createBackgroundStyle(formFrame.background);
        style += `
        >div>form, .ls-page-wrapper {
          ${bg}
        }
        `;
      }
      if (formFrame.width?.value) {
        style += `
        >div>form, .ls-page-wrapper {
          width: ${formFrame.width.value}${formFrame.width.unit} !important;
          min-width: 200px;
          max-width: 100%;
        }
        `;
      }
      if (formFrame.alignment) {
        style += `
        @media only screen and (min-width: 860px) {
          >div>form, .ls-page-wrapper {
            ${
              formFrame.alignment === "left"
                ? "margin-left: max(5vw, 24px) !important;"
                : ""
            } 
            ${
              formFrame.alignment === "right"
                ? "margin-right: max(5vw, 24px) !important;"
                : ""
            } 
          }
        }
        `;
      }
      if (formFrame.border) {
        style += `
        >div>form, .ls-page-wrapper {
          border: ${formFrame.border.size}px ${formFrame.border.style} ${formFrame.border.color} !important;
        }
        `;
      }
      if (formFrame.radius) {
        style += `
        >div>form, .ls-page-wrapper {
          border-radius: ${formFrame.radius.value}${formFrame.radius.unit} !important;
        }
        `;
      }
      if (formFrame.shadow) {
        let s = createShadowStyle(formFrame.shadow);
        style += `
        >div>form, .ls-page-wrapper {
          ${s}
        }
        `;
      }
    }

    // Input styles
    if (input) {
      if (input.mark_require) {
        style += `
        .lf-field.lf-required:after {
            content: ' *';
            color: var(--red-500);
          } 
          .lf-required-sign:after {
            content: ' *';
            color: var(--red-500);
          }
        `;
      }
      if (input.width?.value) {
        style += `
        .ui.input, textArea {
          width: ${input.width.value}${input.width.unit} !important;
          min-width: 1px;
        }
        `;
      }
      if (input.alignment) {
        let direction = "margin-right: auto !important;";
        if (input.alignment === "center") {
          direction = "margin: auto !important;";
        }
        if (input.alignment === "right") {
          direction = "margin-left: auto !important;";
        }
        style += `
        .ui.input, textArea {
          ${direction}
        }
        `;
        style += `
        label, .lf-field {
          text-align: ${input.alignment} !important;
        }
        `;
      }
      if (input.border) {
        style += `
        .ui.input input, textArea {
          border: ${input.border.size}px ${input.border.style} ${input.border.color} !important;
        }
        `;
      }
      if (input.radius) {
        style += `
        .ui.input input, textArea {
          border-radius: ${input.radius.value}${input.radius.unit} !important;
        }
        `;
      }
      if (input.shadow) {
        let s = createShadowStyle(input.shadow);
        style += `
        .ui.input input, textArea {
          ${s}
        }
        `;
      }
    }

    // Button styles
    if (button) {
      if (button.color) {
        style += `
        .ls-actions .ui.button:not(.primary) {
          background: ${button.color};
        }
        `;
      }

      if (button.width) {
        style += `
        .ls-actions .ui.button, a.ui.button {
          width: ${
            button.width.value
              ? `${button.width.value}${button.width.unit}`
              : "fit-content"
          } !important;
        }
        `;
      }
      if (button.alignment) {
        let direction = `
        justify-content: space-between;
        .ls-submit-button {
            margin-left: auto;
         }
        `;
        if (button.alignment === "left") {
          direction = "justify-content: flex-start;";
        }
        if (button.alignment === "center") {
          direction = "justify-content: center;";
        }
        let disclaimer =
          button.alignment === "center" ? "margin-right: auto;" : "";
        style += `
        .ls-actions {
          ${direction}
        }
        .ls-disclaimer {
          ${disclaimer}
        }
        `;
      }

      if (button.fontStyle) {
        style += `
        .ui.button {
          font-family: ${button.fontStyle.font || "inherit"} !important;
        }
        .ls-actions .ui.button {
          color: ${button.fontStyle.color};
        }
        `;
      }
      if (button.fontSize) {
        style += `
        .ui.button {
          font-size: ${button.fontSize.size}${button.fontSize.unit} !important;
          font-weight: ${button.fontSize.weight || "normal"} !important;
        }
        `;
      }

      if (button.shape) {
        let size =
          button.shape.size === "S"
            ? "3px 8px"
            : button.shape.size === "L"
            ? "15px 32px"
            : "7px 16px";
        style += `
        .ls-actions .ui.button, a.ui.button  {
          padding: ${size} !important;

          // &:not(.primary) {
          // background: ${button.shape.color} !important;
          // }
        }
        `;
      }

      if (button.border) {
        style += `
        .ui.button {
          border: ${button.border.size}px ${button.border.style} ${button.border.color} !important;
        }
        `;
      }
      if (button.radius) {
        style += `
        .ui.button {
          border-radius: ${button.radius.value}${button.radius.unit} !important;
        }
        `;
      }
      if (button.shadow) {
        let s = createShadowStyle(button.shadow);
        style += `
        .ui.button {
         ${s}
        }
        `;
      }
    }

    return style;
  }}

  ${basicTemplate}
`;
