import { Button } from "semantic-ui-react";
import { useStore } from "structure";
import { Link } from "react-router-dom";

import { T } from "util/Translation";
import { useTranslation } from "react-i18next";

import { MessagePage } from "components/shared/pageLayouts/MessagePage";

export const SubscriptionCompleted = () => {
  const { t } = useTranslation();
  const { accountStore } = useStore();
  const { currentAccount } = accountStore;

  const planName = {
    BASIC: "subscription_success.plan_basic",
    PREMIUM: "subscription_success.plan_premium",
    PRO: "subscription_success.plan_pro",
  };

  return (
    <MessagePage>
      <h2>
        <T id="subscription_success.thank_you_title" />
      </h2>
      <h5>
        <T
          id="subscription_success.success_message"
          values={{ plan: t(planName[currentAccount.subscription_type]) }}
          components={{ strong: <strong /> }}
        />
      </h5>

      <br />
      <p>
        <T id="subscription_success.note" />
      </p>
      <Button as={Link} to="/" primary size="big">
        <T id="subscription_success.go_home_button" />
      </Button>
    </MessagePage>
  );
};
