import { FieldText } from "ui/FieldText";
import * as S from "./styles";
import { FieldDropdown } from "ui/FieldDropdown";
import { T } from "util/Translation";

const options = ["px", "%", "rem", "em", "vw"].map((item) => ({
  key: item,
  value: item,
  text: item,
}));

export const Size = ({ name, labelIntl, sub, minSize }) => {
  return (
    <S.FieldWrapper className={sub ? "ls-sub" : ""}>
      <div className="ls-h">
        {labelIntl && (
          <label>
            <T id={labelIntl} />
          </label>
        )}
      </div>
      <div className="ls-b">
        <div className="ls-row">
          <FieldText
            finalFormProps={{
              name: `${name}.value`,
            }}
            semanticProps={{
              type: "number",
              min: minSize || 0,
            }}
          />
          <FieldDropdown
            finalFormProps={{
              name: `${name}.unit`,
              defaultValue: "px",
            }}
            semanticProps={{
              options: options,
            }}
          />
        </div>
      </div>
    </S.FieldWrapper>
  );
};
