import { FieldText } from "ui/FieldText";
import { StyleFieldItem } from "./StyleFieldItem";
import { Icon } from "assets/icons/Icon";

const allColors = [
  "A50021",
  "AB274F",
  "DE3163",
  "FFA000",
  "E8AC41",
  "177245",
  "3AB09E",
  "72A0C1",
  "003262",
  "1560BD",
  "512888",
  "86608E",
  "8B8589",
  "2A3439",
  "1B1B1B",
  "010B13",
];

const softColorOptions = allColors.map((color, idx) => ({
  key: idx,
  value: `#${color}`,
  content: (
    <span
      style={{
        height: 34,
        width: 34,
        background: `#${color}`,
        margin: -14,
        borderRadius: 2,
      }}
    />
  ),
}));

const softShadowOptions = [
  {
    key: 1,
    value: {},
    content: <span>N</span>,
  },
  {
    key: 2,
    value: {
      x: 0,
      y: 2,
      blur: 6,
      color: "#33405518",
    },
    content: <Icon name="sizeSmall" />,
  },
  {
    key: 3,
    value: {
      x: 2,
      y: 6,
      blur: 16,
      color: "#33405528",
    },
    content: <Icon name="sizeMedium" />,
  },
  {
    key: 4,
    value: {
      x: 0,
      y: 8,
      blur: 32,
      color: "#33405548",
    },
    content: <Icon name="sizeLarge" />,
  },
];

export const Shadow = ({ name, labelIntl }) => {
  return (
    <StyleFieldItem
      name={`${name}`}
      labelIntl={labelIntl}
      options={softShadowOptions}
      columns={4}
      sub
    >
      <div>
        <div className="ls-row ls-row-border">
          <FieldText
            finalFormProps={{
              name: `${name}.x`,
            }}
            semanticProps={{
              type: "number",
              min: 0,
            }}
          />
          <FieldText
            finalFormProps={{
              name: `${name}.y`,
            }}
            semanticProps={{
              type: "number",
              min: 0,
            }}
          />
          <FieldText
            finalFormProps={{
              name: `${name}.blur`,
            }}
            semanticProps={{
              type: "number",
              min: 0,
            }}
          />
        </div>
        <StyleFieldItem
          name={`${name}.color`}
          labelIntl={"Shadow color"}
          options={softColorOptions}
          sub
          columns={0}
        >
          <div className="ls-row">
            <FieldText
              finalFormProps={{
                name: `${name}.color`,
                defaultValue: "#00000088",
              }}
              semanticProps={{
                type: "color",
                className: "ls-color-picker",
              }}
            />
          </div>
        </StyleFieldItem>
      </div>
    </StyleFieldItem>
  );
};
