import { T } from "util/Translation";
import * as S from "./styles";

export const DropIndicator = () => {
  return (
    <S.DropIndicatorWrapper>
      <span>
        <T id="dnd.drop" />
      </span>
    </S.DropIndicatorWrapper>
  );
};
