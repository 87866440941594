import { observer } from "mobx-react-lite";
import { useStore } from "structure";
import { useHistory } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import { FormPreview } from "./FormPreview.jsx";
import { ButtonWithIcon, GeneralEdit } from "ui/common-styles";
import { Confirm } from "ui/Confirm";

import { Icon } from "assets/icons/Icon";

import { useTranslation } from "react-i18next";
import { T } from "util/Translation";

import * as S from "./styles";
import { CreateFormModal } from "components/shared/CreateFormModal/index.js";

export const Forms = observer(({ lcoalStore, project }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const projectId = project?.id;
  const { projectsStore } = useStore();
  const freeBlockNavigation = useRef(false);
  const countStage = useRef(0);
  const baseOrder = useRef(JSON.stringify(lcoalStore.order));
  const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);
  const [isNewModalOpen, setIsNewModalOpen] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);
  const [hasChanges, setHasChanges] = useState(null);

  useEffect(() => {
    setHasChanges(JSON.stringify(lcoalStore.order) !== baseOrder.current);
  }, [lcoalStore.order]);

  useEffect(() => {
    baseOrder.current = JSON.stringify(lcoalStore.order);
    // Handle leave page
    const handleBeforeUnload = (e) => {
      if (JSON.stringify(lcoalStore.order) !== baseOrder.current) {
        const confirmationMessage = t("forms.unsaved_changes_warning");
        e.returnValue = confirmationMessage;
        return confirmationMessage;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    const unblock = history.block((nextLocation) => {
      if (
        JSON.stringify(lcoalStore.order) !== baseOrder.current &&
        !freeBlockNavigation.current
      ) {
        return handleBlockedNavigation(nextLocation.pathname);
      }
      return true;
    });
    return () => {
      unblock();
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
    // eslint-disable-next-line
  }, [projectId]);

  const handleBlockedNavigation = (nextLocation) => {
    setShowSaveChangesModal(true);
    setNextLocation(nextLocation);
    return false;
  };

  const handleStay = () => {
    setNextLocation(null);
    setShowSaveChangesModal(false);
  };

  const handleOut = () => {
    freeBlockNavigation.current = true;
    history.push(nextLocation);
  };

  const handleOutWithSaving = async () => {
    await saveOrderHandler();
    handleOut();
  };

  const createNewForm = async (name) => {
    const newId = await projectsStore.createNewForm(projectId, name);
    if (newId) {
      toast.success(t("forms.new_form_success"));
      baseOrder.current = JSON.stringify(lcoalStore.order);
      history.push(`/project/${projectId}/form/${newId}`);
    } else {
      toast.error(t("forms.new_form_error"));
    }
  };

  const saveOrderHandler = async () => {
    try {
      await projectsStore.saveFormsOrder(project, lcoalStore.order);
      baseOrder.current = JSON.stringify(lcoalStore.order);
      toast.success(t("forms.save_success"));
    } catch {
      toast.error(t("forms.save_error"));
    }
  };

  return (
    <GeneralEdit>
      <div className="ls-split">
        <h4>
          <T id="forms.title" />
        </h4>
        <ButtonWithIcon
          primary
          size="small"
          disabled={!hasChanges}
          onClick={saveOrderHandler}
        >
          <Icon name="save" height={18} color="white" />
          <T id="forms.save_button" />
        </ButtonWithIcon>
      </div>
      <p>
        <T id="forms.description" />
      </p>
      <div className="ls-main-block">
        <S.FormsWrapper>
          <S.NewFormButton basic onClick={() => setIsNewModalOpen(true)}>
            <Icon name="addNote" height={20} width={20} />
            <T id="forms.create_new_form" />
          </S.NewFormButton>
          {lcoalStore.order.map((item, idx) => {
            if (!item?.form) return null;
            if (idx === 0) countStage.current = 0;
            if (item.isVisible) countStage.current = countStage.current + 1;
            return (
              <FormPreview
                key={item.form.id}
                data={item.form}
                idx={idx}
                stage={countStage.current}
                projectId={projectId}
                isVisible={item.isVisible}
                lcoalStore={lcoalStore}
              />
            );
          })}
          {lcoalStore.order.length === 0 && (
            <p>
              <T id="forms.no_forms" />
            </p>
          )}
        </S.FormsWrapper>
      </div>

      <Confirm
        title={t("forms.unsaved_changes_title")}
        text={t("forms.unsaved_changes_text")}
        confirmButton={t("forms.confirm_save")}
        cancelButton={t("forms.discard_changes")}
        open={showSaveChangesModal}
        onCancel={handleOut}
        onConfirm={handleOutWithSaving}
        onClose={handleStay}
      />

      <CreateFormModal
        open={isNewModalOpen}
        setOpen={setIsNewModalOpen}
        handleCreate={createNewForm}
      />
    </GeneralEdit>
  );
});
