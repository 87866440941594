import { useEffect, useState } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { observable } from "mobx";
import { useStore } from "structure";
import { toast } from "react-toastify";

import { Loading } from "components/shared/Loading";
import { Icon } from "assets/icons/Icon";
import { DuplicateProjectModal } from "../../shared/DuplicateProjectModal";
import { NoData } from "components/shared/NoData";
import { Overview } from "./Panes/Overview";
import { Build } from "./Panes/Build/Build";
import { Submissions } from "./Panes/Submissions";
import { Button, MenuItem, Popup, Tab } from "semantic-ui-react";
import { Confirm } from "ui/Confirm";
import { TopMenu } from "ui/common-styles";

import { T } from "util/Translation";
import { useTranslation } from "react-i18next";

import * as S from "./styles";
import { CustomSections } from "./Panes/CustomSections";
import { MenuActions } from "./MenuActions";

export const Project = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  let { projectId } = useParams();
  const { projectsStore, viewStore } = useStore();
  const { currentTab } = projectsStore;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const [isDraftConfirmOpen, setIsDraftConfirmOpen] = useState(false);
  const [isPublishConfirmOpen, setIsPublishConfirmOpen] = useState(false);
  const [project, setProject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isNotFind, setIsNotFind] = useState(false);

  const handleTabChange = (e, { activeIndex }) =>
    projectsStore.setCurrentTab(activeIndex);

  const lcoalStore = useLocalObservable(
    () => ({
      order: project?.settings?.order || [],

      setOrder(order) {
        this.order = order;
      },

      replaceOrder(idx1, idx2) {
        const item1 = this.order?.[idx1];
        const item2 = this.order?.[idx2];
        let newOrder = [...this.order];
        newOrder[idx2] = item1;
        newOrder[idx1] = item2;
        this.setOrder(newOrder);
      },

      setVisibility(idx, isVisible) {
        let newOrder = [...this.order];
        newOrder[idx] = { form: this.order[idx].form, isVisible };
        this.setOrder(newOrder);
      },
    }),
    {
      order: observable.ref,
    }
  );

  useEffect(() => {
    projectsStore.getById(projectId).then((project) => {
      if (project) {
        setProject(project);
        lcoalStore.setOrder(project?.settings?.order || []);
        setIsLoading(false);
      } else {
        setIsNotFind(true);
      }
    });
    // eslint-disable-next-line
  }, [projectId, projectsStore.isLoading]);

  useEffect(() => {
    viewStore.setBackNavigation(t("project.nav_projects"), `/projects`);
    return () => viewStore.resetBackNavigation();
    // eslint-disable-next-line
  }, []);

  const handleDeleteProject = async () => {
    await projectsStore.delete(projectId);
    toast.success(t("project.deleted_successfully"));
    history.push(`/projects`);
  };

  const handlePublicProject = async (isPublic) => {
    await projectsStore
      .update(projectId, { isPublic })
      .then(() => projectsStore.fetchProjects());
    toast.success(isPublic ? t("project.published") : t("project.unpublished"));
  };

  const updateSettings = async (projectId, data) => {
    let projectSettingsData = {};
    let projectPublicSettingsData = {};
    PUBLIC_SETTING_PROPS.forEach((keyName) => {
      if (keyName in data) projectPublicSettingsData[keyName] = data[keyName];
    });
    PRIVATE_SETTING_PROPS.forEach((keyName) => {
      if (keyName in data) projectSettingsData[keyName] = data[keyName];
    });

    const project = await projectsStore.saveProjectSettings(
      projectId,
      projectSettingsData,
      projectPublicSettingsData
    );
    if (project) {
      setProject(project);
    } else {
      toast.error(t("error.generic_error"));
    }
  };

  if (isNotFind) {
    return (
      <NoData
        title={<T id="project.not_found" />}
        text={<T id="project.broken_link" />}
      />
    );
  }

  if (isLoading) {
    return <Loading />;
  }

  const panes = [
    {
      menuItem: (
        <MenuItem key="build">
          <Icon name="build" />
          <span>
            <T id="project.form" />
          </span>
        </MenuItem>
      ),
      render: () => <Build lcoalStore={lcoalStore} project={project} />,
    },
    {
      menuItem: (
        <MenuItem key="customize">
          <Icon name="customize" />
          <span>
            <T id="project.custom_sections" />
          </span>
        </MenuItem>
      ),
      render: () => (
        <CustomSections lcoalStore={lcoalStore} project={project} />
      ),
    },

    {
      menuItem: (
        <MenuItem key="webhook">
          <Icon name="webhook" />
          <span>
            <T id="project.submissions" />
          </span>
        </MenuItem>
      ),
      render: () => (
        <Submissions project={project} updateSettings={updateSettings} />
      ),
    },
    {
      menuItem: (
        <MenuItem key="overview">
          <Icon name="overview" />
          <span>
            <T id="project.settings" />
          </span>
        </MenuItem>
      ),
      render: () => (
        <Overview project={project} updateSettings={updateSettings} />
      ),
    },
  ];

  return (
    <S.Wrapper>
      <TopMenu>
        <ul className="nav ls-hide-s">
          <li>
            <Button basic className="ls-with-icon" as={Link} to={`/projects`}>
              <Icon name="left" color="var(--grey-600)" />
              <T id="project.nav_projects" />
            </Button>
          </li>
        </ul>
        <ul className="ls-actions-menu">
          <li>
            <Button
              primary
              basic
              as={Link}
              to={`/project/${projectId}/preview`}
              onClick={() => setIsDraftConfirmOpen(true)}
            >
              <T id="project.preview_design" />
            </Button>
          </li>

          <li className="ls-hide-s">
            {project?.isPublic ? (
              <Popup
                trigger={
                  <Button positive onClick={() => setIsDraftConfirmOpen(true)}>
                    <T id="project.status_public" />
                  </Button>
                }
                content={<T id="project.unpublish" />}
                basic
              />
            ) : (
              <Popup
                trigger={
                  <Button
                    color="grey"
                    onClick={() => setIsPublishConfirmOpen(true)}
                  >
                    <T id="project.status_draft" />
                  </Button>
                }
                content={<T id="project.publish" />}
                basic
              />
            )}
          </li>

          <li>
            <MenuActions
              project={project}
              setOpenDuplicateModal={setOpenDuplicateModal}
              setIsDeleteModalOpen={setIsDeleteModalOpen}
              setIsDraftConfirmOpen={setIsDraftConfirmOpen}
              setIsPublishConfirmOpen={setIsPublishConfirmOpen}
            />
          </li>
        </ul>
      </TopMenu>

      <div className="project-section">
        <ProjectName name={project?.name} />
        <div className="ls-show-s">
          {project?.isPublic ? (
            <Popup
              trigger={
                <Button
                  positive
                  size="tiny"
                  onClick={() => setIsDraftConfirmOpen(true)}
                >
                  <T id="project.status_public" />
                </Button>
              }
              content={<T id="project.unpublish" />}
              basic
            />
          ) : (
            <Popup
              trigger={
                <Button
                  color="grey"
                  size="tiny"
                  onClick={() => setIsPublishConfirmOpen(true)}
                >
                  <T id="project.status_draft" />
                </Button>
              }
              content={<T id="project.publish" />}
              basic
            />
          )}
        </div>
        <div className="project-details ls-hide-s"></div>
      </div>
      <Tab
        menu={{ secondary: true, pointing: true }}
        panes={panes}
        activeIndex={currentTab}
        onTabChange={handleTabChange}
        style={{ marginTop: -40 }}
      />

      <Confirm
        title={t("project.delete_title")}
        text={t("project.delete_confirmation")}
        confirmButton={t("project.confirm_delete")}
        color="red"
        open={isDeleteModalOpen}
        onCancel={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteProject}
      />

      <Confirm
        title={t("project.publish_title")}
        text={t("project.publish_confirmation")}
        confirmButton={t("project.confirm_publish")}
        open={isPublishConfirmOpen}
        onCancel={() => setIsPublishConfirmOpen(false)}
        onConfirm={() => handlePublicProject(true)}
      />

      <Confirm
        title={t("project.unpublish_title")}
        text={t("project.unpublish_confirmation")}
        confirmButton={t("project.confirm_unpublish")}
        open={isDraftConfirmOpen}
        onCancel={() => setIsDraftConfirmOpen(false)}
        onConfirm={() => handlePublicProject(false)}
      />

      <DuplicateProjectModal
        open={openDuplicateModal}
        setOpen={setOpenDuplicateModal}
        project={project}
      />
    </S.Wrapper>
  );
});

const PUBLIC_SETTING_PROPS = [
  "steps",
  "frame",
  "nextBtn",
  "submitBtn",
  "template",
  "branding",
  "color",
  "direction",
];

const PRIVATE_SETTING_PROPS = [
  "desc",
  "use_reply_email",
  "use_reply_webhook",
  "reply_email",
  "reply_webhook",
  "reply_webhook_headers",
];

const ProjectName = ({ name }) => {
  return (
    <h3 key={name} className="project-name">
      {name || <T id="project.untitled" />}
    </h3>
  );
};
