import { Icon } from "assets/icons/Icon";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { MAIN_URL } from "util/consts";
import { T } from "util/Translation";

export const TipsSection = () => {
  return (
    <>
      <div className="ls-header">
        <h4>
          <T id="home.tips.title" />
        </h4>
      </div>
      <div className="ls-content">
        <div className="ls-articles">
          <Article
            title="home.tips.getting_started_title"
            text="home.tips.getting_started_text"
            link={`${MAIN_URL}help-center/overview-of-lumo-pages/`}
          />
          <Article
            title="home.tips.faqs_title"
            text="home.tips.faqs_text"
            link={`${MAIN_URL}help-center/faqs/`}
          />
        </div>
        <Button secondary className="ls-bright ls-with-icon ls-split" fluid>
          <T id="home.tips.support" />
          <Icon name="help" />
        </Button>
      </div>
    </>
  );
};

const Article = ({ title, text, link }) => (
  <div className="ls-article">
    <h6>
      <T id={title} />
    </h6>
    <p>
      <T id={text} />
    </p>
    <Button
      secondary
      className="ls-with-icon ls-as-text"
      size="small"
      as={Link}
      to={{ pathname: link }}
      target="_blank"
    >
      <T id="home.tips.read_more" />
      <Icon name="right" height={20} />
    </Button>
  </div>
);
