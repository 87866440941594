import styled from "styled-components";

export const Wrapper = styled.div`
  width: clamp(200px, 40%, 320px);
  height: calc(100dvh - 54px);
  border-left: 1px solid var(--grey-200);
  background: var(--white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: clip;
  overflow-y: auto;
  gap: 16px;
  padding: 16px;
  position: sticky;
  top: 54px;
  color: var(--grey-800);

  .ls-header {
    display: flex;
    align-items: center;
    gap: 8px;
    color: var(--grey-800);
    fill: var(--grey-800);

    h4 {
      line-height: 40px;
    }
  }

  .ls-section {
    width: 100%;
    border-top: 1px solid var(--grey-300);
    padding: 16px 0px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    ul {
      padding-inline-start: 0;
      list-style-type: none;
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin: 0;
    }
  }
`;

export const WrapperEmpty = styled.div`
  width: clamp(200px, 40%, 320px);
  height: calc(100dvh - 54px);
  border-left: 1px solid var(--grey-200);
  background: var(--white);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow-x: clip;
  overflow-y: auto;
  gap: 16px;
  padding: 16px;
  position: sticky;
  top: 54px;
  color: var(--grey-800);
`;
