import styled from "styled-components";

export const LangOptions = styled.div`
  > div > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    justify-items: center;
    gap: 16px 64px;
    margin: 16px auto 24px;
  }
`;
