import { Allignment } from "./Allignment";
import { Background } from "./Background";
import { Border } from "./Border";
import { Color } from "./Color";
import { FontSize } from "./FontSize";
import { FontStyle } from "./FontStyle";
import { Radius } from "./Radius";
import { Shadow } from "./Shadow";
import { Size } from "./Size";
import { Switch } from "./Switch";
import { Text } from "./Text";
import { Width } from "./Width";

const StyleFields = {
  Width,
  Border,
  Radius,
  Shadow,
  Allignment,
  FontStyle,
  FontSize,
  Switch,
  Color,
  Background,
  Text,
  Size,
};

export default StyleFields;
