import { Field, useField } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";

import { useTranslation } from "react-i18next";
import { T } from "util/Translation";

import * as S from "./styles";
import { Dropdown, Input } from "semantic-ui-react";

export const ButtonSettings = ({ blockId }) => {
  const { t } = useTranslation();

  const sizeOptions = [
    {
      key: "default",
      text: t("button_settings.size.default"),
      value: "default",
    },
    {
      key: "px",
      text: t("button_settings.size.px"),
      value: "px",
    },
    {
      key: "percent",
      text: t("button_settings.size.percent"),
      value: "percent",
    },
  ];

  const alignOptions = [
    {
      key: "left",
      text: t("button_settings.align.left"),
      value: "start",
    },
    {
      key: "center",
      text: t("button_settings.align.center"),
      value: "center",
    },
    {
      key: "rigth",
      text: t("button_settings.align.right"),
      value: "end",
    },
  ];

  const { input: size } = useField(`${blockId}.tool-15.size`);
  const { input: width } = useField(`${blockId}.tool-15.width`);

  return (
    <S.SectionWrapper>
      <li>
        <Field name={`${blockId}.tool-15.text`}>
          {(props) => (
            <S.Field className="ls-field">
              <label>
                <T id="button_settings.text" />
              </label>
              <TextareaAutosize
                name={props.input.name}
                value={props.input.value}
                onChange={props.input.onChange}
                className="ls-contentEditable"
                placeholder={t("button_settings.placeholder_text")}
              />
            </S.Field>
          )}
        </Field>
      </li>
      <li>
        <Field name={`${blockId}.tool-15.link`}>
          {(props) => (
            <S.Field>
              <label>
                <T id="button_settings.link" />
              </label>
              <TextareaAutosize
                name={props.input.name}
                value={props.input.value}
                onChange={props.input.onChange}
                className="ls-contentEditable ls-field"
                placeholder={t("button_settings.link_placeholder")}
              />
            </S.Field>
          )}
        </Field>
      </li>
      <li>
        <Field
          name={`${blockId}.tool-15.size`}
          defaultValue={"default"}
          className="selectField"
        >
          {(props) => (
            <S.Field>
              <label>
                <T id="button_settings.size_title" />
              </label>
              <Dropdown
                name={props.input.name}
                value={props.input.value}
                onChange={(e, data) => {
                  props.input.onChange(data.value);
                  if (data.value === "percent") {
                    width.onChange(100);
                  } else {
                    width.onChange(200);
                  }
                }}
                fluid
                selection
                options={sizeOptions}
              />
            </S.Field>
          )}
        </Field>
      </li>
      {size.value && size.value !== "default" && (
        <li>
          <Field name={`${blockId}.tool-15.width`} defaultValue={300}>
            {(props) => (
              <S.Field>
                <label style={{ marginRight: 16 }}>
                  <T id="button_settings.width" />
                </label>
                <Input
                  type="number"
                  name={props.input.name}
                  value={props.input.value}
                  min={0}
                  onChange={(e, data) => {
                    props.input.onChange(data.value);
                  }}
                />
              </S.Field>
            )}
          </Field>
        </li>
      )}
      <li>
        <Field
          name={`${blockId}.tool-15.alignBtn`}
          defaultValue={"center"}
          className="selectField"
        >
          {(props) => (
            <S.Field>
              <label>
                <T id="button_settings.alignBtn" />
              </label>
              <Dropdown
                name={props.input.name}
                value={props.input.value}
                onChange={(e, data) => {
                  props.input.onChange(data.value);
                  if (data.value === "percent") {
                    width.onChange(100);
                  } else {
                    width.onChange(200);
                  }
                }}
                fluid
                selection
                options={alignOptions}
              />
            </S.Field>
          )}
        </Field>
      </li>
      <li>
        <Field
          name={`${blockId}.tool-15.alignText`}
          defaultValue={"center"}
          className="selectField"
        >
          {(props) => (
            <S.Field>
              <label>
                <T id="button_settings.alignText" />
              </label>
              <Dropdown
                name={props.input.name}
                value={props.input.value}
                onChange={(e, data) => {
                  props.input.onChange(data.value);
                  if (data.value === "percent") {
                    width.onChange(100);
                  } else {
                    width.onChange(200);
                  }
                }}
                fluid
                selection
                options={alignOptions}
              />
            </S.Field>
          )}
        </Field>
      </li>
    </S.SectionWrapper>
  );
};
