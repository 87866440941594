import { Button } from "semantic-ui-react";
import illustration from "assets/illustrations/No data-pana.png";
import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { T } from "util/Translation";

import { Wrapper } from "./styles";

export const NoData = ({ title, text }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Wrapper>
      <img src={illustration} alt={title || t("no_data.alt")} />
      <div>
        {title && <h2>{title}</h2>}
        {text && <p>{text}</p>}
        <Button primary onClick={() => history.push(-1)}>
          <T id="no_data.go_back_button" />
        </Button>
      </div>
    </Wrapper>
  );
};
