import { ContentPart } from "./ContentPart";
import * as S from "./styles";

export const SuccessPage = ({ data }) => {
  if (!data) return <DefaultPage />;

  return <ContentPart id="success" fields={data.fields} />;
};

const DefaultPage = () => {
  return (
    <S.PageWrapper>
      <div className="ls-page-wrapper">
        <h1>✅ Submission Successful!</h1>
        <p>
          Thank you!
          <br />
          Your submission has been received 🎉
        </p>
      </div>
    </S.PageWrapper>
  );
};
