import { useTranslation } from "react-i18next";
import { useStore } from "structure";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";

import { T } from "util/Translation";

import * as S from "./styles";

export const Statistics = observer(() => {
  const { t } = useTranslation();
  const { viewStore } = useStore();

  useEffect(() => {
    viewStore.setBackNavigation(t("navigation.home"), `/`);
    return () => viewStore.resetBackNavigation();
    // eslint-disable-next-line
  }, []);
  return (
    <S.Wrapper>
      <div className="main-section">
        <div className="header">
          <h1>
            <T id="statistics.title" />
          </h1>
          <p>
            <T id="statistics.intro" />
          </p>
        </div>
        <div className="animated-blur-background">
          <span>
            <T id="statistics.coming_soon" />
          </span>
        </div>
      </div>
    </S.Wrapper>
  );
});
