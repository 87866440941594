import { Block } from "../Block";

export const ContentPart = ({ id, fields, isFramed }) => {
  if (!Array.isArray(fields)) {
    return null;
  }
  return (
    <>
      {fields.map((field, idx) => {
        return (
          <div key={idx} className={isFramed ? "ls-frame" : ""}>
            {Object.entries(field).map(([type, data]) => (
              <Block
                key={`${id}.f-${idx}`}
                type={type}
                data={data}
                blockId={`${id}.f-${idx}`}
              />
            ))}
          </div>
        );
      })}
    </>
  );
};
