import {
  makeObservable,
  observable,
  action,
  runInAction,
  computed,
} from "mobx";
import { makeid } from "util/helpers";
import { arrayMove } from "@dnd-kit/sortable";
import { TOOLS } from "util/consts";

export class FormEditor {
  isLoading = true;
  formName = "";

  blocks = new Map();
  blocksOrder = [];

  activeElementIndex = null;
  activeToolId = null;
  blockEditId = null;

  blocksOrderSaved = [];
  /*
  detect change => blocks order changed since last save 
  or some value in formRef is changed since last submit
  */

  constructor(root) {
    this.rootStore = root;
    makeObservable(this, {
      blocksOrder: observable.ref,
      isLoading: observable,
      formName: observable,
      activeElementIndex: observable,
      activeToolId: observable,
      blockEditId: observable,
      setIsLoading: action,
      hasUnsavedChanges: computed,
      setFormName: action,
      setBlockEditId: action,
    });
  }

  get activeBlock() {
    return this.blocks.get(this.blocksOrder[this.activeElementIndex]);
  }

  get activeBlockId() {
    return this.blocksOrder[this.activeElementIndex];
  }

  get currentBlockEdit() {
    if (!this.blockEditId) return null;
    return this.blocks.get(this.blockEditId);
  }

  get currentBlockType() {
    return this.currentBlockEdit?.type || 0;
  }

  get currentBlockTool() {
    return TOOLS.find((tool) => `tool-${tool.id}` === this.currentBlockType);
  }

  get hasUnsavedChanges() {
    return (
      JSON.stringify(this.blocksOrderSaved) !== JSON.stringify(this.blocksOrder)
    );
  }

  updateSavedBlocksOrder() {
    this.blocksOrderSaved = [...this.blocksOrder];
  }

  setIsLoading(bool) {
    this.isLoading = bool;
  }

  setBlockEditId(blockId) {
    this.blockEditId = blockId;
  }

  getBlockIndex(blockId) {
    return this.blocksOrder.findIndex(
      (itemId) => `block-${itemId}` === blockId
    );
  }

  setActiveElement(blockId) {
    if (blockId?.includes("tool")) {
      runInAction(() => {
        this.activeToolId = blockId;
      });
    }
    const newIndex = this.getBlockIndex(blockId);
    runInAction(() => {
      this.activeElementIndex = newIndex !== -1 ? newIndex : null;
    });
  }

  setFormName(formName) {
    this.formName = formName;
  }

  add(newBlock, blockId = null) {
    let index =
      blockId && blockId !== "main-board" && !blockId.includes("anchor")
        ? this.getBlockIndex(blockId)
        : this.blocks.size;

    this.blocks.set(newBlock.id, {
      type: newBlock.type,
    });
    runInAction(() => {
      this.blocksOrder = [
        ...this.blocksOrder.slice(0, index),
        newBlock.id,
        ...this.blocksOrder.slice(index),
      ];
    });
  }

  async duplicate(blockId) {
    const blockIndex = this.getBlockIndex(`block-${blockId}`);
    if (blockIndex === -1) return;
    const newBlockId = makeid();
    const sourceBlockProps = this.blocks.get(blockId);

    if (!sourceBlockProps) {
      console.error("wrong duplicaiton");
      return;
    }

    this.blocks.set(newBlockId, { type: sourceBlockProps.type });
    runInAction(() => {
      this.blocksOrder = [
        ...this.blocksOrder.slice(0, blockIndex + 1),
        newBlockId,
        ...this.blocksOrder.slice(blockIndex + 1),
      ];
    });
    return newBlockId;
  }

  update(blockId, newType) {
    this.blocks.set(blockId, { type: newType });
  }

  moveBlocks(activeId, overId) {
    const activeIndex = this.getBlockIndex(activeId);
    const overIndex =
      overId === "anchor-end"
        ? this.blocksOrder.length
        : this.getBlockIndex(overId);
    if (activeIndex !== overIndex) {
      runInAction(() => {
        this.blocksOrder = arrayMove(this.blocksOrder, activeIndex, overIndex);
      });
    }
  }

  remove(blockId) {
    this.blocks.delete(blockId);
    runInAction(() => {
      this.blocksOrder = this.blocksOrder.filter(
        (itemId) => itemId !== blockId
      );
    });
  }

  reset() {
    this.blocks.clear();
    runInAction(() => {
      this.isLoading = true;
      this.formName = "";
      this.blocksOrder = [];
      this.activeElementIndex = null;
      this.activeToolId = null;
      this.blockEditId = null;
    });
  }
}
