import { useFormState } from "react-final-form";
import { Checkbox as SemanticCheckbox } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import * as S from "./styles";

export const Checkbox = ({ blockId }) => {
  const { t } = useTranslation();
  const values = useFormState()?.values?.[blockId]?.["tool-6"] || {};

  return (
    <S.Block>
      <SemanticCheckbox
        toggle={values.type === "toggle"}
        name={`${blockId}-preview`}
        label={values.field || t("block.checkbox.example_label")}
      />
    </S.Block>
  );
};
