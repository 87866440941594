import styled from "styled-components";
import tl from "assets/illustrations/topleft.svg";
import rb from "assets/illustrations/rightbottom.svg";

export const MessagePageWrapper = styled.div`
  min-height: 100dvh;
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 24px;
  background-image: url(${tl}), url(${rb});
  background-position: top left, right bottom;
  background-size: min(80vw, 100vh), min(80vw, 100vh);
  background-repeat: no-repeat, no-repeat;

  .ls-logo-website {
    position: absolute;
    top: 24px;
    right: 24px;
    > svg {
      height: 36px;
      width: 200px;
    }
  }

  h2 {
    color: var(--primary-500);
  }

  .ls-content {
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
    margin: 0 auto;
    text-align: left;
    line-height: 1.5;

    p {
      margin: 0px;
    }

    .ls-info {
      margin-top: 24px;

      p {
        font-weight: 600;
      }

      ul {
        margin: 4px 0;
      }
    }
  }

  strong {
    color: var(--secondary-500);
  }
`;
