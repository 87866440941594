import { makeObservable, observable, computed, action } from "mobx";

export class View {
  sidebarContent = null;
  backNavigation = null;

  constructor(root) {
    this.rootStore = root;
    makeObservable(this, {
      backNavigation: observable,
      sidebarContent: observable.ref,
      hasSidebarContent: computed,
      setSidebarContent: action,
      setBackNavigation: action,
    });
  }

  get hasSidebarContent() {
    return this.sidebarContent !== null;
  }

  setSidebarContent(content) {
    this.sidebarContent = content;
  }

  setBackNavigation(text, path) {
    this.backNavigation = { text, path };
  }

  resetBackNavigation() {
    this.backNavigation = null;
  }
}
