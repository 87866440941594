import { Field } from "react-final-form";
import { FormField, TextAreaWrapper } from "./common-styles";
import ReactTextareaAutosize from "react-textarea-autosize";

export const FieldTextArea = ({ label, finalFormProps, semanticProps }) => {
  return (
    <Field {...finalFormProps}>
      {({ meta, input }) => {
        const hasError =
          meta.touched &&
          (meta.error?.[input.name] ||
            (meta.submitError?.[input.name] && !meta.modifiedSinceLastSubmit));

        return (
          <FormField>
            <label>{label}</label>
            <TextAreaWrapper className={hasError ? "error" : ""}>
              <ReactTextareaAutosize
                name={input.name}
                value={input.value}
                onChange={input.onChange}
                minRows={4}
                {...semanticProps}
              />
            </TextAreaWrapper>
            {hasError && (
              <p className="ls-error">
                {meta.error?.[input.name] || meta.submitError?.[input.name]}
              </p>
            )}
          </FormField>
        );
      }}
    </Field>
  );
};
