import { defaultDropAnimation, DragOverlay } from "@dnd-kit/core";
import {
  BlockItem,
  Droppable,
} from "components/shared/creationComponents/DNDComponents";
import { observer } from "mobx-react-lite";
import { useStore } from "structure";

export const MainBord = observer(({ setView }) => {
  const { formEditorStore } = useStore();
  return (
    <>
      <Droppable id="main-board" setView={setView} />
      <DragOverlay dropAnimation={defaultDropAnimation}>
        {formEditorStore.activeElementIndex !== null && <BlockItem />}
      </DragOverlay>
    </>
  );
});
