import { Icon } from "assets/icons/Icon";
import { FieldDropdown } from "ui/FieldDropdown";
import { StyleFieldItem } from "./StyleFieldItem";

const options = ["left", "cetner", "right"].map((item) => ({
  key: item,
  value: item,
  text: item,
}));

export const Allignment = ({ name, labelIntl, sub, defaultValue }) => {
  return (
    <StyleFieldItem
      name={`${name}`}
      labelIntl={labelIntl}
      options={softAlignOptions}
      columns={3}
      sub={sub}
    >
      <div>
        <FieldDropdown
          finalFormProps={{
            name: `${name}`,
            defaultValue: defaultValue || "center",
          }}
          semanticProps={{
            options: options,
          }}
        />
      </div>
    </StyleFieldItem>
  );
};

const softAlignOptions = [
  {
    key: 1,
    value: "left",
    content: <Icon name="alignLeft" />,
  },
  {
    key: 2,
    value: "center",
    content: <Icon name="alignCenter" />,
  },
  {
    key: 3,
    value: "right",
    content: <Icon name="alignRight" />,
  },
];
