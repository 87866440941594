import { observer } from "mobx-react-lite";
import { useStore } from "structure";
import * as S from "./styles";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { formatDate } from "util/helpers";
import { T } from "util/Translation";

export const Billing = observer(() => {
  const { accountStore } = useStore();
  const { currentAccount } = accountStore;

  return (
    <S.FieldsSectionWrapper>
      <h4>
        <T id="billing.title" />
      </h4>
      <ul>
        <li>
          <label>
            <T id="billing.current_subscription" />
          </label>
          <SubscriptionCard
            type={currentAccount.subscription_type}
            expire={currentAccount.expiration_date}
          />
        </li>

        <li>
          <label>
            <T id="billing.payments_title" />
          </label>
          <Link to="/account/payment">
            <T id="billing.payments_action" />
          </Link>
        </li>
      </ul>
      <div className="ls-actions">
        <Button as={Link} to="/account/upgrade" primary>
          <T id="billing.upgrade_cta" />
        </Button>
      </div>
    </S.FieldsSectionWrapper>
  );
});

export const SubscriptionCard = ({ type, expire }) => {
  const subscription = subscriptionTypes[type] ?? subscriptionTypes.basic;
  const expired = expire ? formatDate(expire, "date") : null;
  return (
    <S.SubscriptionCardWrapper subscription={subscription.id}>
      <p className="subscription-type">
        <T id={subscription.t_name} />
      </p>
      {expired ? (
        <p>
          <T id="subscription_card.expire" date={expired} />
        </p>
      ) : (
        <p>
          <T id="subscription_card.limited" />
        </p>
      )}
    </S.SubscriptionCardWrapper>
  );
};

const subscriptionTypes = {
  basic: {
    id: 1,
    t_name: "subscription_card.plan_basic",
  },
  premium: {
    id: 2,
    t_name: "subscription_card.plan_premium",
  },
  pro: {
    id: 3,
    t_name: "subscription_card.plan_pro",
  },
};
