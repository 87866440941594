import { FieldText } from "ui/FieldText";
import { FieldDropdown } from "ui/FieldDropdown";
import { StyleFieldItem } from "./StyleFieldItem";

const options = ["px", "%", "rem", "em", "vw"].map((item) => ({
  key: item,
  value: item,
  text: item,
}));

export const Width = ({ name, labelIntl, smallSize }) => {
  const softOptions = [
    {
      key: 1,
      value: {},
      content: "Default",
    },
    {
      key: 2,
      value: {
        value: smallSize,
        unit: "px",
      },
      content: "Small",
    },
    {
      key: 3,
      value: {
        value: 100,
        unit: "%",
      },
      content: "Full",
    },
  ];

  return (
    <StyleFieldItem
      name={name}
      labelIntl={labelIntl}
      options={softOptions}
      columns={3}
    >
      <div className="ls-row">
        <FieldText
          finalFormProps={{
            name: `${name}.value`,
          }}
          semanticProps={{
            type: "number",
            min: 0,
          }}
        />
        <FieldDropdown
          finalFormProps={{
            name: `${name}.unit`,
            defaultValue: "px",
          }}
          semanticProps={{
            options: options,
          }}
        />
      </div>
    </StyleFieldItem>
  );
};
