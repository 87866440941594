import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import * as S from "./styles";

export const Header = ({ data }) => {
  if (!data) return null;

  const { title, frame, button } = data;

  return (
    <S.HeaderWrapper frame={frame}>
      <div>
        {(title?.logo || title?.text) && (
          <S.Title size={title.size || 40}>
            {title.logo?.[0] ? (
              <img src={title.logo[0]} alt={title.text || "logo"} />
            ) : (
              <h4>{title.text}</h4>
            )}
          </S.Title>
        )}
      </div>

      <div>
        {button?.text && (
          <Button
            primary
            size={button.size || "medium"}
            as={Link}
            to={{ pathname: button.link || "#" }}
            target="_blank"
          >
            {button.text}
          </Button>
        )}
      </div>
    </S.HeaderWrapper>
  );
};
