import { FieldButtonSelect } from "ui/FieldButtonSelect";
import { multiValidators, required } from "util/validators";
import * as S from "./styles";

export const Rate = ({ blockId, data }) => {
  if (!data.amount || !data.type) return null;

  const validatorsArr = data.required ? [required] : [];
  const items = Array.from({ length: data.amount }, (_, i) => i + 1);
  const options = items.map((item) => ({
    key: item,
    value: item,
    content: data.type === "numbers" ? item : data.icon,
  }));

  return (
    <S.Block>
      {data.field && (
        <p className={"lf-field" + (data.required ? " lf-required" : "")}>
          {data.field}
        </p>
      )}
      {data.desc && <p>{data.desc}</p>}
      <S.RateButtonsWrapper className={data.type === "icon" ? "ls-icons" : ""}>
        <FieldButtonSelect
          finalFormProps={{
            name: `${blockId}`,
            validate: multiValidators(validatorsArr),
          }}
          options={options}
        />
      </S.RateButtonsWrapper>
    </S.Block>
  );
};
