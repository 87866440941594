import { Field } from "react-final-form";
import ReactQuill from "react-quill";
import * as S from "./styles";

const toolbarOptions = [
  [{ size: ["small", false, "large", "huge"] }],
  ["bold", "italic", "underline"],
  ["link", "blockquote", "code-block"],
  [{ list: "ordered" }, { list: "bullet" }],
  //[{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }],
  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  //[{ font: [] }],
  [{ align: [] }],
];

export const RichText = ({ blockId }) => {
  return (
    <S.Block>
      <Field name={`${blockId}.tool-14.editor`}>
        {(props) => (
          <S.RichText>
            <input
              hidden
              name={props.input.name}
              value={props.input.value}
              onChange={() => null}
            />
            <ReactQuill
              value={props.input.value}
              onChange={(value) => {
                props.input.onChange(value);
              }}
              modules={{
                toolbar: toolbarOptions,
              }}
              theme="snow"
            />
          </S.RichText>
        )}
      </Field>
    </S.Block>
  );
};
