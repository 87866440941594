import { Text as TextElement } from "./Elements/Text";
import { TextArea as TextAreaElement } from "./Elements/TextArea";
import { RadioGroup as RadioGroupElement } from "./Elements/RadioGroup";
import { Dropdown as DropdownElement } from "./Elements/Dropdown";
import { Checkbox as CheckboxElement } from "./Elements/Checkbox";
import { DateEl as DateElement } from "./Elements/Date";
import { Image as ImageElement } from "./Elements/Image";
import { Gallery as GalleryElement } from "./Elements/Gallery";
import { Video as VideoElement } from "./Elements/Video";
import { RichText as RichTextElement } from "./Elements/RichText";
import * as S from "./styles";
import { Rate } from "./Elements/Rate";
import { ButtonSelect } from "./Elements/ButtonSelect";
import { Button } from "./Elements/Button";

export const Block = ({ type, blockId, data }) => {
  if (!data) return null;
  return (
    <S.Wrapper key={blockId}>
      <div className="ls-block">
        {type === "tool-2" && <TextElement blockId={blockId} data={data} />}

        {type === "tool-3" && <TextAreaElement blockId={blockId} data={data} />}

        {type === "tool-4" && (
          <RadioGroupElement blockId={blockId} data={data} />
        )}

        {type === "tool-5" && <DropdownElement blockId={blockId} data={data} />}

        {type === "tool-6" && <CheckboxElement blockId={blockId} data={data} />}

        {type === "tool-7" && <DateElement blockId={blockId} data={data} />}

        {type === "tool-10" && <ImageElement blockId={blockId} data={data} />}

        {type === "tool-11" && <GalleryElement blockId={blockId} data={data} />}

        {type === "tool-12" && <VideoElement blockId={blockId} data={data} />}

        {type === "tool-14" && (
          <RichTextElement blockId={blockId} data={data} />
        )}

        {type === "tool-15" && <Button blockId={blockId} data={data} />}

        {type === "tool-16" && <ButtonSelect blockId={blockId} data={data} />}

        {type === "tool-18" && <Rate blockId={blockId} data={data} />}
      </div>
    </S.Wrapper>
  );
};
