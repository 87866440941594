import { Field, useField } from "react-final-form";
import * as S from "./styles";
import { Button, Dropdown, Input } from "semantic-ui-react";
import { MediaGalleryModal } from "components/shared/MediaGalleryModal";
import { Icon } from "assets/icons/Icon";
import { API_URL } from "util/consts";

import { useTranslation } from "react-i18next";
import { T } from "util/Translation";

export const GallerySettings = ({ blockId }) => {
  const { t } = useTranslation();

  const sizeOptions = [
    {
      key: "default",
      text: t("gallery_settings.size.default"),
      value: "default",
    },
    {
      key: "px",
      text: t("gallery_settings.size.px"),
      value: "px",
    },
    {
      key: "percent",
      text: t("gallery_settings.size.percent"),
      value: "percent",
    },
  ];

  const { input: size } = useField(`${blockId}.tool-11.size`);
  const { input: height } = useField(`${blockId}.tool-11.height`);
  const { input: width } = useField(`${blockId}.tool-11.width`);

  return (
    <S.SectionWrapper>
      <li>
        <Field name={`${blockId}.tool-11.images`} defaultValue={[]}>
          {(props) => (
            <>
              <input
                name={props.input.name}
                value={props.input.value}
                onChange={props.input.onChange}
                type="hidden"
              />
              <MediaGalleryModal
                onSelect={(data) => {
                  props.input.onChange(data);
                }}
                multiple
                initialValue={props.input.value.map((item) =>
                  item?.replace(API_URL, "")
                )}
                trigger={
                  <Button
                    type="button"
                    compact
                    fluid
                    className="ls-bright ls-split"
                    color="grey"
                  >
                    <span>
                      <T id="gallery_settings.edit_gallery" />
                    </span>
                    <Icon name="addPhoto" height={18} />
                  </Button>
                }
              />
            </>
          )}
        </Field>
      </li>

      <li>
        <Field
          name={`${blockId}.tool-11.size`}
          defaultValue={"default"}
          className="selectField"
        >
          {(props) => (
            <S.Field>
              <label>
                <T id="gallery_settings.image_size" />
              </label>
              <Dropdown
                name={props.input.name}
                value={props.input.value}
                onChange={(e, data) => {
                  props.input.onChange(data.value);
                  if (data.value === "percent") {
                    height.onChange(100);
                    width.onChange(100);
                  } else {
                    height.onChange(300);
                    width.onChange(300);
                  }
                }}
                fluid
                selection
                options={sizeOptions}
              />
            </S.Field>
          )}
        </Field>
      </li>

      {size.value && size.value !== "default" && (
        <>
          <li>
            <Field name={`${blockId}.tool-11.height`} defaultValue={300}>
              {(props) => (
                <S.Field>
                  <label style={{ marginRight: 16 }}>
                    <T id="gallery_settings.height" />
                  </label>
                  <Input
                    type="number"
                    name={props.input.name}
                    value={props.input.value}
                    onChange={(e, data) => {
                      props.input.onChange(data.value);
                    }}
                    min={0}
                  />
                </S.Field>
              )}
            </Field>
          </li>

          <li>
            <Field name={`${blockId}.tool-11.width`} defaultValue={300}>
              {(props) => (
                <S.Field>
                  <label style={{ marginRight: 16 }}>
                    <T id="gallery_settings.width" />
                  </label>
                  <Input
                    type="number"
                    name={props.input.name}
                    value={props.input.value}
                    min={0}
                    onChange={(e, data) => {
                      props.input.onChange(data.value);
                    }}
                  />
                </S.Field>
              )}
            </Field>
          </li>
        </>
      )}
    </S.SectionWrapper>
  );
};
