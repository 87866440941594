import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 auto;
  max-width: calc(100% - 48px);

  .ls-header {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ls-body {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin: 0 auto;
    max-width: calc(100% - 48px);
    width: 1200px;

    h1 {
      color: var(--primary-500);
      strong {
        color: var(--secondary-500);
      }
    }

    h4 {
      color: var(--grey-700);
    }

    .ls-disclaimer {
    margin-bottom: 32px;
    }

    .ls-cycle, .ls-account {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        margin-bottom: 40px;
        img {
        border-radius: 100%;
        }
      .row {
        display: flex;
        flex-direction: row;
        gap: 16px;
        align-items: center;
        justify-content: flex-start;
      }

      .col {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      .ls-note {
      font-size: 0.9em;
      color: var(--grey-500);
      }

      p {
        margin-bottom: 0;
      }
    }

    .ls-payment {
          display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    margin-bottom: 40px;
    }

    .ls-options {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 32px;

        .ui.button {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 32px 24px;
        border-radius: 8px;
        border: 1px solid var(--grey-200);
        color: var(--grey-500);
        width: 200px;
        box-shadow: var(--shadow-200);
        transition: border-color 0.3s;

        .period {
            font-size: 24px;
            color: var(--secondary-600);
            font-weight: 700;
        }

        .price {
        color: var(--grey-800);
        display: flex;
        align-items: flex-end;
        gap: 2px;
        height: 48px;
            > span {
                font-weight: 700;
            }
            .p-s {
                font-size: 16px;
                line-height: 2;
            }

            .p-m {
                font-size: 32px;
                line-height: 1.2;
            }

            .p-l {
                font-size: 48px;
                line-height: 1;
            }
        }

        .save {
          height: 0;
          width: 100%;
            >span {
              background: var(--grey-200);
              color: var(--grey-700);
              font-weight: 700;
              width: calc(100% + 48px);
              display: block;
              margin-left: -24px;
              margin-top: 8px;
              border-radius: 0 0 8px 8px;
              transition: 0.3s;
              }
        }

        &.ls-selected {
          border-color: var(--tertiary-500);
          .save > span{
            background: var(--tertiary-500);
            color: white;
          }
        }
    }
  }
`;

export const PaymentSummary = styled.ul`
  padding-inline-start: 0;
  border-bottom: 1px solid var(--secondary-200);
  max-width: 624px;
  padding-bottom: 4px;
  li {
    display: grid;
    grid-template-columns: 70% 30%;

    strong {
      color: var(--secondary-500);
      font-weight: 600;
      animation: fadeIn 0.3s;
    }

    > span:last-child {
      animation: fadeIn 0.3s;
    }

    &.ls-total span {
      font-weight: 700;
    }
  }
`;

export const AvatarWrapper = styled.div`
  > img,
  .ls-avatar {
    height: 80px;
    width: 80px;
    border-radius: 40px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 36px;
    letter-spacing: 1px;
    font-weight: 400;
    background: var(--secondary-500);
    color: var(--secondary-50);
    object-fit: cover;
  }
`;
