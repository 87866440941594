import { Button as Btn } from "semantic-ui-react";
import * as S from "./styles";

export const Button = ({ data }) => {
  return (
    <S.Block
      style={{
        display: "flex",
        justifyContent: data?.alignBtn,
      }}
    >
      <Btn
        type="button"
        as="a"
        href={data.link.startsWith("http") ? data.link : `https://${data.link}`}
        target="_blank"
        primary
        style={{
          width:
            data.size === "default"
              ? "fit-content"
              : `${data.width}${data.size === "percent" ? "%" : "px"}`,
          minWidth: 100,
          maxWidth: "100%",
          overflow: "hidden",
          justifyContent: data.alignText,
        }}
      >
        {data.text}
      </Btn>
    </S.Block>
  );
};
