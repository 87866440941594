import { Field } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";
import { Dropdown, Checkbox } from "semantic-ui-react";

import { useTranslation } from "react-i18next";
import { T } from "util/Translation";

import * as S from "./styles";

export const TextSettings = ({ blockId }) => {
  const { t } = useTranslation();

  const typeOptions = [
    {
      key: "text",
      text: t("text_settings.type.text"),
      value: "text",
    },
    {
      key: "number",
      text: t("text_settings.type.number"),
      value: "number",
    },
    {
      key: "email",
      text: t("text_settings.type.email"),
      value: "email",
    },
  ];

  return (
    <S.SectionWrapper>
      <li>
        <Field name={`${blockId}.tool-2.placeholder`}>
          {(props) => (
            <S.Field className="ls-field">
              <label>
                <T id="text_settings.placeholder" />
              </label>
              <TextareaAutosize
                name={props.input.name}
                value={props.input.value}
                onChange={props.input.onChange}
                className="ls-contentEditable"
                placeholder={t("text_settings.placeholder_optional")}
              />
            </S.Field>
          )}
        </Field>
      </li>
      <li>
        <Field name={`${blockId}.tool-2.required`}>
          {(props) => (
            <S.Field>
              <Checkbox
                name={props.input.name}
                checked={props.input.value || false}
                onChange={(e, data) => {
                  props.input.onChange(data.checked);
                }}
                label={t("text_settings.required")}
              />
            </S.Field>
          )}
        </Field>
      </li>
      <li>
        <Field name={`${blockId}.tool-2.type`}>
          {(props) => (
            <S.Field>
              <label>
                <T id="text_settings.field_type" />
              </label>
              <Dropdown
                name={props.input.name}
                value={props.input.value}
                onChange={(e, data) => {
                  props.input.onChange(data.value);
                }}
                placeholder={t("text_settings.select_placeholder")}
                fluid
                search
                selection
                options={typeOptions}
              />
            </S.Field>
          )}
        </Field>
      </li>
    </S.SectionWrapper>
  );
};
