import { observer } from "mobx-react-lite";
import { Form } from "react-final-form";
import { FieldText } from "ui/FieldText";
import { GeneralEdit } from "ui/common-styles";
import { FieldCheckbox } from "ui/FieldCheckbox";
import { FieldTextArea } from "ui/FieldTextArea";
import { PanelActions } from "../../PanelActions";

import { T } from "util/Translation";
import { useTranslation } from "react-i18next";

export const Webhook = observer(({ project, updateSettings }) => {
  const { t } = useTranslation();
  const projectId = project.id;
  const projectSettings = project.settings;

  const onSubmit = async (data) => {
    await updateSettings(projectId, data);
  };

  return (
    <GeneralEdit>
      <h4>
        <T id="webhook.title" />
      </h4>
      <p>
        <T id="webhook.description" />
      </p>

      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <ul>
                <li>
                  <FieldCheckbox
                    finalFormProps={{
                      name: "use_reply_webhook",
                      initialValue: projectSettings.use_reply_webhook,
                    }}
                    semanticProps={{
                      label: t("webhook.send_webhook"),
                      disabled: submitting,
                    }}
                    initialChecked={projectSettings.use_reply_webhook}
                  />
                </li>
                {values.use_reply_webhook && (
                  <>
                    <li>
                      <FieldText
                        label={t("webhook.url_label")}
                        finalFormProps={{
                          name: "reply_webhook",
                          initialValue: projectSettings.reply_webhook,
                        }}
                        semanticProps={{
                          placeholder: t("webhook.url_placeholder"),
                          disabled: submitting,
                        }}
                      />
                    </li>
                    <li>
                      <FieldTextArea
                        label={t("webhook.headers_label")}
                        finalFormProps={{
                          name: "reply_webhook_headers",
                          initialValue: projectSettings.reply_webhook_headers,
                        }}
                        semanticProps={{
                          placeholder: t("webhook.headers_placeholder"),
                          disabled: submitting,
                        }}
                      />
                    </li>
                  </>
                )}
              </ul>
              <PanelActions />
            </form>
          );
        }}
      />
    </GeneralEdit>
  );
});
