import styled from "styled-components";

export const DropIndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #06b6d477;
  border: 2px solid var(--primary-500);
  color: var(--primary-800);
  font-weight: 600;
  font-size: 18px;
  padding: 24px;
  margin: -24px;
  z-index: 999;
  animation: openIndicator 0.3s;

  @keyframes openIndicator {
    from {
      padding: 4px;
    }
    to {
      padding: 24px;
    }
  }
`;
