import {
  footerWebsiteStyling,
  headerWebsiteStyling,
} from "components/shared/website/styles";
import { Button } from "semantic-ui-react";
import styled from "styled-components";
import { AsText, scrollElement } from "ui/common-styles";

export const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 40px;
  gap: 16px;

  .ui.vertical.menu.fluid {
    border-color: var(--grey-300);
    border-radius: 8px;
    .item {
      border-color: var(--grey-200);
      color: var(--grey-700);
      &.active {
        background: var(--grey-100);
      }
    }
  }

  .ls-group {
    max-height: 400px;
    overflow: auto;
    border-radius: 8px;
    background: var(--grey-100);
    border: 1px solid var(--grey-300);
    padding: 8px 24px;

    ${scrollElement}

    ul {
      padding-inline-start: 0;
      list-style-type: none;
      margin-bottom: 0;

      li {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        gap: 0 16px;
        flex-wrap: wrap;

        > div:nth-child(2) {
          flex: 1;
        }
        > button {
          margin-bottom: 17px;
        }
      }
    }
  }

  @media (max-width: 700px) {
    padding: 1rem;

    .ls-settings {
      .ui.grid {
        flex-direction: column;

        > .column {
          width: 100vw !important;
          border-radius: 0;
          padding: 0;
          margin: 0;
          border-top: 1px solid var(--grey-300);
          border-bottom: 1px solid var(--grey-300);

          :not(.stretched) {
            margin-top: 16px;
            max-width: 100vw;
            overflow-x: scroll;

            ::-webkit-scrollbar {
              display: none;
            }
          }

          &.stretched {
            border: none;

            .ls-group {
              padding: 16px 24px 16px;
              margin: 16px 16px 90px;
              max-height: none;
            }
          }

          .ui.menu {
            display: flex;
            flex-direction: row;
            border-radius: 0;
            box-shadow: none;
            border: 0;
            padding: 0 1rem;

            .item {
              border-radius: 4px !important;
              margin: 8px 4px;
              padding: 12px 20px;

              :before {
                height: 0;
              }
            }
          }
        }
      }
    }
  }
`;

export const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  justify-content: space-between;
  border-bottom: none;

  ${({ frame }) => headerWebsiteStyling(frame)}
`;

export const FooterPreviewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  padding: 8px 16px;
  justify-content: space-between;
  border-bottom: none;

  ${({ frame }) => footerWebsiteStyling(frame)}

  .ls-links {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 16px;
    height: 100%;
    width: 50%;

    ul {
      list-style-type: none;
      padding-inline-start: 0;
      margin: 8px;

      li {
        min-width: 100px;
      }
    }
  }

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const DemoBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  border-radius: 8px;
  min-height: 240px;
  background: var(--grey-100);
  border: 1px solid var(--grey-500);
  overflow: hidden;
  margin-bottom: 24px;

  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;

    > h5 {
      min-height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
    }
  }

  .ls-part-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .ls-about {
    min-width: 50%;
  }

  .ls-edit-icon {
    background: var(--grey-100);
    fill: var(--grey-600);
    padding: 4px;
    border: 1px solid var(--grey-300);
    border-radius: 10px;
    svg {
      height: 16px;
      width: 16px;
    }
  }

  a {
    pointer-events: none;
  }
`;

export const Placeholder = styled(AsText)`
  border-radius: 2px;
  border: 1px dashed var(--primary-500);
  padding: 16px 24px;
`;

export const Title = styled.div`
  ${({ size }) =>
    size &&
    `
    h4 {
  line-height: ${size}px;
  font-size: ${size * 0.8}px;
    }
  img {
  height: ${size}px;
  
  }
`}
`;

export const ButtonItem = styled(Button)`
  ${({ size }) =>
    size &&
    `
  line-height: ${size}px;
  font-size: ${size * 0.5}px;
`}
`;
