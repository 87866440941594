import { observer } from "mobx-react-lite";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { T } from "util/Translation";
import { useStore } from "structure";
import { ProjectSection } from "./ProjectsSection";
import { TipsSection } from "./TipsSection";
import { Replies } from "./Replies";
import * as S from "./styles";

export const Home = observer(() => {
  const { accountStore } = useStore();
  const { currentAccount } = accountStore;

  return (
    <S.Wrapper>
      <div className="ls-header">
        <h1>
          <T id="welcome" values={{ name: currentAccount.first_name }} />
        </h1>
        <Button as={Link} className="ls-hide-m" to="/account/upgrade" primary>
          <T id="billing.upgrade_cta" />
        </Button>
      </div>
      <div className="ls-body">
        <Replies />
        <S.TipsSectionWrapper>
          <TipsSection />
        </S.TipsSectionWrapper>
        <S.ProjectSectionWrapper>
          <ProjectSection />
        </S.ProjectSectionWrapper>
      </div>
    </S.Wrapper>
  );
});
