export const DEFAULT_CUSTOM_UI = {
  global: {
    steps: true,
    background: { type: "color", color: "#F7FAFB" },
    primaryColor: "#05B5D3",
    titleStyle: {
      font: "Kumbh Sans",
      color: "#334055",
    },
    titleSize: {
      size: 24,
      unit: "px",
      weight: 600,
    },

    textStyle: {
      font: "Source Sans 3",
      color: "#334055",
    },
    textSize: {
      size: 16,
      unit: "px",
      weight: 400,
    },
  },
  formFrame: {
    alignment: "left",
    width: {
      value: 400,
      unit: "px",
    },
    background: {
      type: "color",
      color: "#ffffff",
    },
    border: {
      size: 0,
      style: "solid",
      color: "#00000000",
    },
    radius: {
      value: 8,
      unit: "px",
    },
    shadow: {
      size: "L",
      color: "#22222222",
    },
  },
  input: {
    alignment: "left",
    width: {
      value: 100,
      unit: "%",
    },
    border: {
      size: 1,
      style: "solid",
      color: "#CBD4DF",
    },
    radius: {
      value: 4,
      unit: "px",
    },
    shadow: {
      size: "None",
    },
  },
  button: {
    alignment: "right",
    width: {
      value: 0,
      unit: "px",
    },
    shape: {
      size: "M",
      color: "#E1E7EF",
    },
    fontStyle: {
      font: "Kumbh Sans",
      color: "#ffffff",
    },
    fontSize: {
      size: 20,
      unit: "px",
      weight: 600,
    },
    border: {
      size: 1,
      style: "solid",
      color: "#00000000",
    },
    radius: {
      value: 4,
      unit: "px",
    },
    shadow: {
      size: "S",
      color: "#d7dce0",
    },
    nextButtonText: "Next",
    prevButtonText: "Back",
    submitButtonText: "Submit",
  },
};
