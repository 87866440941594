import StyleFields from "./StyleFields";

const InputSettings = () => {
  return (
    <>
      <StyleFields.Switch
        name="input.mark_require"
        labelIntl="projectPreview.design.input.mark_require"
      />
      <StyleFields.Width
        name="input.width"
        labelIntl="projectPreview.design.input.width"
        smallSize={200}
      />
      <StyleFields.Border
        name="input.border"
        labelIntl="projectPreview.design.input.border"
      />
      <StyleFields.Radius
        name="input.radius"
        labelIntl="projectPreview.design.input.radius"
      />
      <StyleFields.Shadow
        name="input.shadow"
        labelIntl="projectPreview.design.input.shadow"
      />
      <StyleFields.Allignment
        name="input.alignment"
        labelIntl="projectPreview.design.input.align"
      />
    </>
  );
};

export default InputSettings;
