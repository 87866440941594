import { shadeColor } from "util/helpers";

export const headerWebsiteStyling = (frame) => {
  const backgroundStyle = frame?.background
    ? createBackgroundStyle(frame.background)
    : "background: var(--white);";
  return `
      height: ${
        frame?.height
          ? `${frame.height.value}${frame.height.unit}`
          : "fit-content"
      };
       ${backgroundStyle}
      border-bottom: 1px solid ${frame?.border || "transparent"};
    `;
};

export const footerWebsiteStyling = (frame) => {
  const backgroundStyle = frame?.background
    ? createBackgroundStyle(frame.background)
    : "background: var(--white);";
  return `
      min-height: ${
        frame?.height
          ? `${frame.height.value}${frame.height.unit}`
          : "fit-content"
      };
       ${backgroundStyle}
      border-top: 1px solid ${frame?.border || "transparent"};
      color: ${frame?.text || "var(--grey-700)"};
      li p,h6 {
      color: ${frame?.text ? shadeColor(frame.text, 15) : "var(--grey-700)"}; 
      }

      li a {
      color: ${frame?.text || "var(--grey-700)"};
      text-decoration: none;
      }
    `;
};

export const fillColor = (name, base) => {
  let res = `--${name}-50: ${shadeColor(base, 95)};`;
  for (let i = 1; i <= 9; i++) {
    res += `--${name}-${i * 100}: ${shadeColor(base, 100 - i * 20)};`;
  }
  return res;
};

export const createBackgroundStyle = (background) => {
  let style = ``;
  if (background.type === "color") {
    style += `
        background: ${background.color || "var(--white)"} !important;
        `;
  }
  if (background.type === "image") {
    style += `
        background-image: url('${background.image?.[0]}') !important;
        background-position: ${background.position || "center"} !important;
        background-repeat: no-repeat;
        background-size: cover;
        `;
  }
  if (background.type === "gradient") {
    style += `
        background: linear-gradient(${background.deg}deg, ${background.color1}, ${background.color2}) !important;
        `;
  }

  return style;
};

export const createShadowStyle = (shadow) => {
  const { x, y, blur, color } = shadow;
  return `filter: drop-shadow(${x}px ${y}px ${blur}px ${color});`;
};
