import { Button, Modal } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { Icon } from "assets/icons/Icon";
import { FieldText } from "ui/FieldText";
import { ModalCloseWrapper, ModalContentWrapper } from "ui/common-styles";
import { T } from "util/Translation";

export const CreateFormModal = observer(({ open, setOpen, handleCreate }) => {
  const { t } = useTranslation();

  const onSubmit = async (data) => {
    await handleCreate(data.name);
    setOpen(false);
  };

  return (
    <Modal
      size="tiny"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      closeIcon={
        <ModalCloseWrapper>
          <Icon name="close" />
        </ModalCloseWrapper>
      }
    >
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <ModalContentWrapper>
              <div className="ls-content">
                <h3>
                  <T id="create_step.title" />
                </h3>
              </div>
              <div className="ls-mark">
                <FieldText
                  label={t("create_step.enter_name")}
                  finalFormProps={{ name: "name" }}
                  semanticProps={{
                    placeholder: t("create_step.placeholder_name"),
                  }}
                />
              </div>
              <div className="ls-actions">
                <Button
                  type="submit"
                  className="ls-bright"
                  primary
                  content={t("create_step.create_button")}
                />
                <Button
                  type="button"
                  className="ls-bright"
                  color="grey"
                  content={t("create_step.cancel_button")}
                  onClick={() => setOpen(false)}
                />
              </div>
            </ModalContentWrapper>
          </form>
        )}
      />
    </Modal>
  );
});
