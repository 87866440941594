import { FieldText } from "ui/FieldText";
import { FieldDropdown } from "ui/FieldDropdown";
import { StyleFieldItem } from "./StyleFieldItem";

const options = ["px", "%", "rem", "em", "vw"].map((item) => ({
  key: item,
  value: item,
  text: item,
}));

const weightOptions = ["400", "500", "600", "700"].map((item) => ({
  key: item,
  value: item,
  text: item,
}));

export const FontSize = ({ name, labelIntl, defaultValue = {} }) => {
  return (
    <StyleFieldItem
      name={`${name}`}
      labelIntl={labelIntl}
      options={softTextSizeOptions}
      sub
      columns={3}
    >
      <div className="ls-row ls-row-border">
        <FieldText
          finalFormProps={{
            name: `${name}.size`,
            defaultValue: defaultValue.size || "16",
          }}
          semanticProps={{
            type: "number",
            min: 0,
          }}
        />
        <FieldDropdown
          finalFormProps={{
            name: `${name}.unit`,
            defaultValue: "px",
          }}
          semanticProps={{
            options: options,
          }}
        />
        <FieldDropdown
          finalFormProps={{
            name: `${name}.weight`,
            defaultValue: defaultValue.weight || "400",
          }}
          semanticProps={{
            options: weightOptions,
          }}
        />
      </div>
    </StyleFieldItem>
  );
};

const PreviewFont = ({ size, weight }) => (
  <span
    style={{
      fontSize: size,
      fontWeight: weight,
    }}
  >
    Aa
  </span>
);

const softTextSizeOptions = [
  {
    key: 1,
    value: {
      size: 24,
      unit: "px",
      weight: 600,
    },
    content: <PreviewFont size={20} weight={600} />,
  },
  {
    key: 2,
    value: {
      size: 16,
      unit: "px",
      weight: 800,
    },
    content: <PreviewFont size={16} weight={800} />,
  },
  {
    key: 3,
    value: {
      size: 16,
      unit: "px",
      weight: 400,
    },
    content: <PreviewFont size={16} weight={400} />,
  },
];
