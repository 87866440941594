import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "assets/icons/logo_color.svg";
import { MAIN_URL } from "util/consts";
import * as S from "./styles";
import { RichText } from "../Block/Elements/styles";

export const Footer = ({ data }) => {
  const { about, frame } = data || {};

  return (
    <footer id="footer">
      <div>
        {!!data && (
          <S.FooterWrapper frame={frame}>
            <div>
              {about && (
                <>
                  {(about.logo || about.title) && (
                    <S.Title size={about?.size || 40}>
                      {about?.logo?.[0] ? (
                        <img src={about.logo[0]} alt={about?.title || "logo"} />
                      ) : (
                        <h4>{about.title}</h4>
                      )}
                    </S.Title>
                  )}
                  {about.text && (
                    <RichText
                      dangerouslySetInnerHTML={{ __html: about.text }}
                    />
                  )}
                </>
              )}
            </div>

            <div className="ls-links">
              {["links1", "links2", "links3"].map((item) => {
                const column = data[item];
                if (
                  !column ||
                  !(
                    column?.title ||
                    column?.linkIds.some(
                      (id) =>
                        column?.linksContent?.[id] ||
                        column?.linksContent?.[id]?.text
                    )
                  )
                ) {
                  return <div className="ls-empty"></div>;
                }

                const links =
                  (column?.linkIds || [])
                    .map((id) => ({ id: id, ...column.linksContent?.[id] }))
                    .filter((link) => link?.text) || [];

                return (
                  <ul>
                    {column.title && (
                      <li>
                        <h6>{column.title}</h6>
                      </li>
                    )}
                    {links.map((link) => (
                      <li key={link.id}>
                        <Link href={link.link} target="_blank">
                          {link.text}
                        </Link>
                      </li>
                    ))}
                  </ul>
                );
              })}
            </div>
          </S.FooterWrapper>
        )}
        <div className="ls-lumo-pages-footer">
          <div className="lp-logo">
            <span>Built with</span>
            <Link to={{ pathname: MAIN_URL }} target="_blank">
              <Logo height={24} width={48} />
            </Link>
          </div>
          <ul>
            <li>
              <p>© 2024 Lumo Pages </p>
            </li>
            <li>
              <Link
                to={{
                  pathname: `${MAIN_URL}user-created-pages-terms-of-use`,
                }}
                target="_blank"
              >
                Terms of Use
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: `${MAIN_URL}user-created-pages-privacy-policy`,
                }}
                target="_blank"
              >
                Pricavy Policy
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname: `${MAIN_URL}user-created-pages-accessibility-statement`,
                }}
                target="_blank"
              >
                Accessibility Statement
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};
