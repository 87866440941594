import { FieldText } from "ui/FieldText";
import { FieldDropdown } from "ui/FieldDropdown";
import { StyleFieldItem } from "./StyleFieldItem";

const options = ["px", "rem", "em"].map((item) => ({
  key: item,
  value: item,
  text: item,
}));

const softRadiusOptions = [0, 4, 8, 16, 32].map((item, idx) => ({
  key: idx + 1,
  value: {
    value: item,
    unit: "px",
  },
  content: (
    <div
      style={{
        height: 34,
        width: 34,
        margin: -14,
        overflow: "hidden",
      }}
    >
      <span
        style={{
          display: "block",
          height: 80,
          width: 80,
          borderRadius: item,
          borderTop: "4px solid var(--grey-500)",
          borderRight: "4px solid var(--grey-500)",
          position: "relative",
          top: 5,
          right: 52,
        }}
      ></span>
    </div>
  ),
}));

export const Radius = ({ name, labelIntl }) => {
  return (
    <StyleFieldItem
      sub
      name={`${name}`}
      labelIntl={labelIntl}
      options={softRadiusOptions}
      columns={0}
    >
      <div className="ls-row">
        <FieldText
          finalFormProps={{
            name: `${name}.value`,
          }}
          semanticProps={{
            type: "number",
            min: 0,
          }}
        />
        <FieldDropdown
          finalFormProps={{
            name: `${name}.unit`,
            defaultValue: "px",
          }}
          semanticProps={{
            options: options,
          }}
        />
      </div>
    </StyleFieldItem>
  );
};
