import { useFormState } from "react-final-form";
import { Button as Btn } from "semantic-ui-react";
import { T } from "util/Translation";
import * as S from "./styles";

export const Button = ({ blockId }) => {
  const values = useFormState()?.values?.[blockId]?.["tool-15"] || {};

  return (
    <S.Block
      style={{
        display: "flex",
        justifyContent: values?.alignBtn,
      }}
    >
      <Btn
        type="button"
        primary
        style={{
          width:
            values.size === "default"
              ? "fit-content"
              : `${values.width}${values.size === "percent" ? "%" : "px"}`,
          minWidth: 100,
          maxWidth: "100%",
          overflow: "hidden",
          justifyContent: values.alignText,
        }}
      >
        {values.text || (
          <span className="ls-placeholder">
            <T id="button_settings.placeholder_text" />
          </span>
        )}
      </Btn>
    </S.Block>
  );
};
