import { observer } from "mobx-react-lite";
import { Form } from "react-final-form";
import { FieldCheckbox } from "ui/FieldCheckbox";
import { GeneralEdit } from "ui/common-styles";
import { useStore } from "structure";
import { PanelActions } from "../../PanelActions";

import { T } from "util/Translation";
import { useTranslation } from "react-i18next";

export const Publish = observer(({ project }) => {
  const { projectsStore } = useStore();
  const { t } = useTranslation();
  const projectId = project.id;

  const onSubmit = async (data) => {
    await projectsStore
      .update(projectId, { isPublic: data.isPublic })
      .then(() => projectsStore.fetchProjects());
  };

  return (
    <GeneralEdit>
      <h4>
        <T id="publish.title" />
      </h4>
      <p>
        <T id="publish.description" />
      </p>

      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, form }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FieldCheckbox
                finalFormProps={{
                  name: "isPublic",
                  initialValue: project.isPublic,
                }}
                semanticProps={{
                  label: t("publish.label"),
                  toggle: true,
                  disabled: submitting,
                }}
              />

              <PanelActions />
            </form>
          );
        }}
      />
    </GeneralEdit>
  );
});
