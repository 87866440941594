import { ContentPart } from "./ContentPart";
import * as S from "./styles";

export const ErrorPage = ({ data }) => {
  if (!data) return <DefaultPage />;

  return <ContentPart id="error" fields={data.fields} />;
};

const DefaultPage = () => {
  return (
    <S.PageWrapper>
      <h1>🚫 Something Went Wrong!</h1>
      <p>We couldn't process your submission.</p>
    </S.PageWrapper>
  );
};
