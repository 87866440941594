import { Container } from "./styles";
import { T } from "util/Translation";

const ERRORS = {
  "e-404": "error.page_not_found",
  "e-500": "error.internal_server_error",
  // Additional error codes can be added here
};

export const ErrorPage = ({ error = 404 }) => {
  const errorKey = `e-${error}`;

  return (
    <Container>
      <h1>{error}</h1>
      <p>
        <T id={ERRORS[errorKey] || "error.generic_error"} />
      </p>
    </Container>
  );
};
