import { T } from "util/Translation";
import { useState } from "react";
import { FieldButtonSelect } from "ui/FieldButtonSelect";
import { AsText } from "ui/common-styles";
import { Icon } from "assets/icons/Icon";
import * as S from "./styles";

export const StyleFieldItem = ({
  options,
  labelIntl,
  name,
  sub,
  columns,
  children,
}) => {
  const [isAdvancedMode, setIsAdvancedMode] = useState(false);
  return (
    <S.FieldWrapper>
      <div className={"ls-h" + (sub ? " ls-sub" : "")}>
        <label>{labelIntl && <T id={labelIntl} />}</label>
        <AsText onClick={() => setIsAdvancedMode((prev) => !prev)}>
          <Icon
            name="tune"
            color={isAdvancedMode ? "var(--primary-500)" : "var(--grey-500)"}
          />
        </AsText>
      </div>
      <div className="ls-b">
        {isAdvancedMode ? (
          <> {children}</>
        ) : (
          <FieldButtonSelect
            finalFormProps={{ name: name }}
            options={options}
            columns={columns}
          />
        )}
      </div>
    </S.FieldWrapper>
  );
};
