import styled from "styled-components";

export const PlanOptionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;

  .ui.button {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid var(--grey-200);
    color: var(--grey-500);
    width: 200px;
    background: var(--white);
    box-shadow: var(--shadow-200);

    .price {
      color: var(--grey-800);
      display: flex;
      align-items: flex-end;
      gap: 2px;
      height: 48px;
      > span {
        font-weight: 700;
      }
      .p-s {
        font-size: 16px;
        line-height: 2;
      }

      .p-m {
        font-size: 32px;
        line-height: 1.2;
      }

      .p-l {
        font-size: 48px;
        line-height: 1;
      }
    }

    .title {
      display: flex;
      flex-direction: column;
    }

    h3 {
      margin: 0;
      line-height: 1;
      color: var(--secondary-600);
    }

    .subtitle {
      font-family: var(--title-font);
      color: var(--secondary-500);
    }

    .notes {
      height: 48px;
      align-content: center;
    }

    :focus {
      background: var(--white);
    }

    &.ls-selected {
      border: 1px solid var(--tertiary-500);
    }
  }
`;
