import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 400px;
    width: 400px;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -60px;
    margin-bottom: 120px;

    h2 {
      text-align: center;
    }

    p {
      max-width: 400px;
      text-align: center;
    }

    .ui.button {
      margin-top: 8px;
    }
  }
`;
