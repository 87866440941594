import { FieldText } from "ui/FieldText";
import * as S from "./styles";
import { FieldDropdown } from "ui/FieldDropdown";
import { T } from "util/Translation";

const fonts = [
  "Arial",
  "Calibri",
  "Cantarell",
  "Comic Sans MS",
  "Courier New",
  "Droid Sans",
  "Fira Sans",
  "Georgia",
  "Helvetica Neue",
  "Kumbh Sans",
  "Lato",
  "Montserrat",
  "Nunito",
  "Oswald",
  "Poppins",
  "Playfair Display",
  "Quicksand",
  "Raleway",
  "Roboto Condensed",
  "Source Sans 3",
  "Source Sans Pro",
  "Trebuchet MS",
  "Ubuntu",
  "Verdana",
];

// eslint-disable-next-line
const heFonts = [
  "Noto Sans Hebrew",
  "David Libre",
  "Rubik",
  "Varela Round",
  "Miriam Libre",
  "Frank Ruhl Libre",
  "Miriam",
];

const options = fonts.map((item) => ({
  key: item,
  value: item,
  text: item,
}));

export const FontStyle = ({ name, labelIntl }) => {
  return (
    <S.FieldWrapper>
      <div className="ls-h">
        {labelIntl && (
          <label>
            <T id={labelIntl} />
          </label>
        )}
      </div>
      <div className="ls-b">
        <div className="ls-row">
          <FieldDropdown
            finalFormProps={{
              name: `${name}.font`,
              defaultValue: "Ariel",
            }}
            semanticProps={{
              options: options,
              search: true,
            }}
          />
          <FieldText
            finalFormProps={{
              name: `${name}.color`,
              defaultValue: "#6366f1",
            }}
            semanticProps={{
              type: "color",
              className: "ls-color-picker",
            }}
          />
        </div>
      </div>
    </S.FieldWrapper>
  );
};
