import { FieldArray } from "react-final-form-arrays";
import { Field } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";
import { Checkbox, Button, Dropdown } from "semantic-ui-react";
import { Icon } from "assets/icons/Icon";

import { useTranslation } from "react-i18next";
import { T } from "util/Translation";

import * as S from "./styles";

export const ButtonSelectSettings = ({ blockId }) => {
  const { t } = useTranslation();

  const selectionOptions = [
    {
      key: "single",
      text: t("button_selection_settings.selection.single"),
      value: "single",
    },
    {
      key: "multi",
      text: t("button_selection_settings.selection.multi"),
      value: "multi",
    },
  ];

  return (
    <S.SectionWrapper>
      <li>
        <Field name={`${blockId}.tool-16.required`}>
          {(props) => (
            <S.Field>
              <Checkbox
                name={props.input.name}
                checked={props.input.value || false}
                onChange={(e, data) => {
                  props.input.onChange(data.checked);
                }}
                label={t("button_selection_settings.required")}
              />
            </S.Field>
          )}
        </Field>
      </li>
      <li>
        <Field name={`${blockId}.tool-16.selection`} defaultValue={"single"}>
          {(props) => (
            <S.Field>
              <label>
                <T id="button_selection_settings.selection_label" />
              </label>
              <Dropdown
                name={props.input.name}
                value={props.input.value}
                onChange={(e, data) => {
                  props.input.onChange(data.value);
                }}
                fluid
                search
                selection
                options={selectionOptions}
              />
            </S.Field>
          )}
        </Field>
      </li>
      <FieldArray name={`${blockId}.tool-16.options`} defaultValue={[]}>
        {({ fields }) => (
          <S.RadioOptions>
            <h6>
              <T id="button_selection_settings.options" />
            </h6>

            {fields.map((name, idx) => (
              <Field key={name} name={name}>
                {(props) => (
                  <S.Field className="ls-field">
                    <TextareaAutosize
                      name={props.input.name}
                      value={props.input.value}
                      onChange={props.input.onChange}
                      className="ls-contentEditable"
                      placeholder={t(
                        "button_selection_settings.option_placeholder",
                        { number: idx + 1 }
                      )}
                    />
                    <Button
                      className="remove-option ls-bright"
                      type="button"
                      color="red"
                      onClick={() => fields.remove(idx)}
                    >
                      <Icon name="delete" />
                    </Button>
                  </S.Field>
                )}
              </Field>
            ))}
            <br />
            <Button
              primary
              className="ls-bright ls-split"
              fluid
              compact
              type="button"
              onClick={() => fields.push("")}
            >
              <span>
                <T id="button_selection_settings.add_option" />
              </span>
              <Icon name="plus" />
            </Button>
          </S.RadioOptions>
        )}
      </FieldArray>
    </S.SectionWrapper>
  );
};
