import { Field, useFormState } from "react-final-form";
import TextareaAutosize from "react-textarea-autosize";
import * as S from "./styles";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { FieldButtonSelect } from "ui/FieldButtonSelect";

export const Rate = ({ blockId }) => {
  const { t } = useTranslation();
  const values = useFormState()?.values?.[blockId]?.["tool-18"] || {};

  const options = useMemo(() => {
    const items = Array.from({ length: values.amount }, (_, i) => i + 1);

    return items.map((item) => ({
      key: item,
      value: item,
      content: values.type === "numbers" ? item : values.icon,
    }));
  }, [values.amount, values.type, values.icon]);

  return (
    <S.Block>
      <Field
        name={`${blockId}.tool-18.field`}
        initialValue={values.field || ""}
      >
        {(props) => (
          <TextareaAutosize
            name={props.input.name}
            value={props.input.value}
            onChange={props.input.onChange}
            className="ls-contentEditable"
            placeholder={t("block.rate.field_placeholder")}
          />
        )}
      </Field>
      <Field name={`${blockId}.tool-18.desc`} initialValue={values.desc || ""}>
        {(props) => (
          <S.Field>
            <TextareaAutosize
              name={props.input.name}
              value={props.input.value}
              onChange={props.input.onChange}
              className="ls-contentEditable"
              placeholder={t("block.rate.desc_placeholder")}
            />
          </S.Field>
        )}
      </Field>

      <S.RateButtonsWrapper
        className={values.type === "icon" ? "ls-icons" : ""}
      >
        <FieldButtonSelect
          finalFormProps={{ name: `${blockId}-preview` }}
          options={options}
        />
      </S.RateButtonsWrapper>
    </S.Block>
  );
};
