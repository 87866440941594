import React, { useState } from "react";
import {
  Input,
  Button,
  Grid,
  List,
  Form,
  TextArea,
  Segment,
  Header,
} from "semantic-ui-react";

const flattenJSON = (obj, parentKey = "", result = {}) => {
  for (let key in obj) {
    const newKey = parentKey ? `${parentKey}.${key}` : key;
    if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
      flattenJSON(obj[key], newKey, result);
    } else {
      result[newKey] = obj[key];
    }
  }
  return result;
};

const unflattenJSON = (obj) => {
  const result = {};
  for (let key in obj) {
    const keys = key.split(".");
    keys.reduce((acc, part, i) => {
      if (i === keys.length - 1) {
        acc[part] = obj[key];
      } else {
        acc[part] = acc[part] || {};
      }
      return acc[part];
    }, result);
  }
  return result;
};

const TranslationEditor = () => {
  const [translations, setTranslations] = useState({});

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedKey, setSelectedKey] = useState("");
  const [selectedValue, setSelectedValue] = useState("");

  // Handle importing JSON file
  const handleFileImport = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const json = JSON.parse(event.target.result);
          setTranslations(flattenJSON(json));
        } catch (error) {
          alert("Invalid JSON file.");
        }
      };
      reader.readAsText(file);
    }
  };

  // Handle exporting JSON file
  const handleFileExport = () => {
    const unflattened = unflattenJSON(translations);
    const blob = new Blob([JSON.stringify(unflattened, null, 2)], {
      type: "application/json",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "translations.json";
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleSearchChange = (e) =>
    setSearchQuery(e.target.value.toLowerCase());

  const handleKeySelect = (key) => {
    setSelectedKey(key);
    setSelectedValue(translations[key]);
  };

  const handleKeyChange = (newKey) => {
    setSelectedKey(newKey);
  };

  const handleValueChange = (newValue) => {
    setSelectedValue(newValue);
  };

  const handleAddPair = () => {
    if (selectedKey) {
      setTranslations((prev) => ({
        ...prev,
        [selectedKey]: selectedValue,
      }));

      setSelectedKey("");
      setSelectedValue("");
    }
  };

  const handleDeleteKey = (key) => {
    setTranslations((prev) => {
      const updated = { ...prev };
      delete updated[key];
      return updated;
    });
    if (key === selectedKey) {
      setSelectedKey("");
      setSelectedValue("");
    }
  };

  const filteredKeys = Object.keys(translations).filter((key) => {
    const value = translations[key];
    return (
      key.toLowerCase().includes(searchQuery) ||
      (value && value.toLowerCase().includes(searchQuery))
    );
  });

  return (
    <Grid
      divided="vertically"
      style={{ maxWidth: "1600px", margin: "40px auto" }}
    >
      {/* Left Panel */}
      <Grid.Column width={6} style={{ padding: "1rem", overflowY: "auto" }}>
        <Header as="h1">Translations</Header>

        <Button
          as="label"
          htmlFor="fileInput"
          primary
          style={{ marginBottom: "1rem" }}
        >
          Import JSON
        </Button>
        <input
          id="fileInput"
          type="file"
          accept=".json"
          onChange={handleFileImport}
          style={{ display: "none" }}
        />

        <Button
          color="green"
          onClick={handleFileExport}
          style={{ marginBottom: "1rem", marginLeft: "1rem" }}
        >
          Export JSON
        </Button>

        <Header as="h2">Search Translations</Header>
        <Input
          fluid
          icon="search"
          placeholder="Search keys or values..."
          value={searchQuery}
          onChange={handleSearchChange}
          style={{ marginBottom: "1rem" }}
        />

        <Segment>
          <List divided relaxed style={{ maxHeight: 400, overflowY: "auto" }}>
            {filteredKeys.map((key) => (
              <List.Item
                key={key}
                onClick={() => handleKeySelect(key)}
                style={{
                  cursor: "pointer",
                  padding: "0.5rem",
                  backgroundColor:
                    key === selectedKey ? "#f9f9f9" : "transparent",
                }}
              >
                <List.Content>
                  <List.Header>{key}</List.Header>
                  <List.Description>{translations[key]}</List.Description>
                </List.Content>
              </List.Item>
            ))}
          </List>
        </Segment>
      </Grid.Column>

      {/* Right Panel */}
      <Grid.Column width={10} style={{ padding: "1rem" }}>
        <Header as="h2">Edit Translation</Header>
        <Segment>
          <Form>
            <Form.Field>
              <label>Key</label>
              <Input
                fluid
                value={selectedKey}
                onChange={(e) => handleKeyChange(e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <label>Value</label>
              <TextArea
                value={selectedValue}
                onChange={(e) => handleValueChange(e.target.value)}
                style={{ minHeight: "100px" }}
              />
            </Form.Field>
          </Form>
          <Button
            color="red"
            onClick={() => handleDeleteKey(selectedKey)}
            style={{ marginTop: "1rem" }}
          >
            Delete Selected Pair
          </Button>

          <Button
            primary
            onClick={handleAddPair}
            style={{ marginTop: "1rem", marginLeft: "1rem" }}
          >
            Save Changes
          </Button>
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

export default TranslationEditor;
