import { observer } from "mobx-react-lite";
import { Forms } from "./content/Forms";
import { PanelTemplate } from "../PanelTemplate";

import { useTranslation } from "react-i18next";

export const Build = observer(({ lcoalStore, project }) => {
  const { t } = useTranslation();

  const pages = [
    {
      name: t("build.forms"),
      value: t("build.forms_count", { amount: lcoalStore.order.length }),
      className: false,
      content: <Forms lcoalStore={lcoalStore} project={project} />,
    },
  ];

  return <PanelTemplate pages={pages} projectName={project.name} />;
});
