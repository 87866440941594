import { Divider } from "semantic-ui-react";
import StyleFields from "./StyleFields";
import { T } from "util/Translation";

const ButtonSettings = () => {
  return (
    <>
      <StyleFields.Color
        name="button.color"
        labelIntl="projectPreview.design.button.color"
        sub
      />
      <StyleFields.Allignment
        name="button.alignment"
        labelIntl="projectPreview.design.button.alignment"
      />
      <StyleFields.Width
        name="button.width"
        labelIntl="projectPreview.design.button.width"
        smallSize={120}
      />

      <StyleFields.FontStyle
        name="button.fontStyle"
        labelIntl="projectPreview.design.button.font_style"
      />
      <StyleFields.FontSize
        name="button.fontSize"
        labelIntl="projectPreview.design.button.font_size"
      />
      <StyleFields.Border
        name="button.border"
        labelIntl="projectPreview.design.button.border"
      />
      <StyleFields.Radius
        name="button.radius"
        labelIntl="projectPreview.design.button.radius"
      />
      <StyleFields.Shadow
        name="button.shadow"
        labelIntl="projectPreview.design.button.shadow"
      />
      <Divider />
      <h5>
        <T id="projectPreview.design.button.text.title" />
      </h5>
      <br />
      <StyleFields.Text
        name="button.nextButtonText"
        labelIntl="projectPreview.design.button.text.next"
      />
      <StyleFields.Text
        name="button.prevButtonText"
        labelIntl="projectPreview.design.button.text.next"
      />
      <StyleFields.Text
        name="button.submitButtonText"
        labelIntl="projectPreview.design.button.text.submit"
      />
    </>
  );
};

export default ButtonSettings;
