import styled from "styled-components";

export const PreviewHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 32px;
  position: fixed;
  top: 0;
  background: var(--secondary-50);
  width: 100%;
  border-bottom: 1px solid var(--secondary-500);
  z-index: 3;

  > p {
    margin: 0;
    color: var(--secondary-700);
    font-size: 18px;
    font-family: var(--title-font);
    font-weight: 700;
  }

  > a {
    color: var(--grey-600);
    font-family: var(--title-font);
    text-decoration: none;
    margin-left: -16px;
    padding: 8px;
    text-decoration: none;
    font-weight: 700;
    font-size: 16px;
    animation: 0.3s ease 0s 1 normal none running fadeIn;

    svg {
      height: 20px;
      width: auto;
      fill: var(--grey-600);
    }

    :hover {
      color: var(--grey-700);
      opacity: 1;

      svg {
        fill: var(--grey-700);
        opacity: 1;
      }
    }
  }
`;

export const PreviewFooter = styled.footer`
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--grey-50);
  border-top: 1px solid var(--grey-200);
`;

export const PreviewBody = styled.div`
  display: grid;
  grid-template-columns: auto max(300px, 25%);
  margin-top: 54px;
  height: calc(100dvh - 54px);

  .ls-editor {
    margin: 16px;
    border: 1px solid var(--grey-300);
    border-radius: 4px;
    height: calc(100% - 32px);
    overflow-y: scroll;

    .ls-section-header {
      padding: 16px;
      margin-bottom: 16px;
    }

    .ui.styled.accordion {
      box-shadow: none;
      border-radius: 0;
      border-top: 1px solid var(--grey-200);
      border-bottom: 1px solid var(--grey-200);
      .title {
        color: var(--grey-400);
        border-color: var(--grey-200);

        &.active {
          color: var(--grey-600);
        }
      }
    }
  }

  .ls-preview {
    margin: 16px;
    margin-right: 8px;
    border-radius: 0.28571429rem;
    border: 1px solid var(--grey-300);
    overflow: auto;

    > div {
      border-radius: 0.28571429rem;
      min-height: calc(100dvh - 88px);
      max-height: calc(100dvh - 88px);
    }
  }

  .ls-view-navigator-space-saver {
    display: none;
    height: 58px;
    width: 100%;
  }
  .ls-view-navigator {
    display: none;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    padding: 0 16px;
    background: var(--secondary-50);
    border-top: 1px solid var(--secondary-200);
    height: 58px;
    position: fixed;
    bottom: 0;
    z-index: 3;

    > .ui.button {
      margin-top: -1px;
      border-radius: 0;
      border-top: 2px solid transparent;
      padding: 16px;

      &.ls-active {
        border-color: var(--secondary-700);
      }
    }
  }

  .ui.secondary.pointing.menu {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    border-color: var(--secondary-100);

    .item {
      color: var(--grey-600);
      font-weight: 700;
      justify-content: center;

      &.active {
        border-color: var(--secondary-500);
      }

      &.disabled {
        color: var(--grey-400) !important;
      }
    }
  }

  @media only screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    padding-bottom: 58px;

    .ls-preview {
      margin: 0;
      border-radius: 0;
      border: none;
    }

    > .ls-view {
      margin: 0 auto;
      padding: 32px 32px 88px;
      height: fit-content;
      min-height: calc(100dvh - 112px);
      max-width: 100vw;
      .ls-section-header {
        margin-bottom: 24px;
      }
    }
    .ls-editor {
      display: none;
    }
    .ls-view-navigator {
      display: grid;
    }
    .ls-view-navigator-space-saver {
      display: block;
    }
  }
`;

export const ResponsePreviewWrapper = styled.div`
  padding: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 32px;

  ul {
    display: flex;
    flex-direction: column;
    gap: 16px;
    list-style-type: none;
    padding-inline-start: 0;

    > li {
      display: flex;
      flex-direction: column;
      gap: 4px;

      p {
        margin: 0;
        overflow-wrap: anywhere;

        :first-child {
          font-weight: 700;
        }
      }
    }
  }
`;
