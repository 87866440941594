import { computed, makeObservable, observable, runInAction } from "mobx";
import { payment as apiPayment, user as apiUser } from "../endpoints";

export class Payments {
  payments = [];
  paginationMeta = {
    current_page: 1,
  };
  isLoading = false;

  constructor() {
    makeObservable(this, {
      payments: observable.ref,
      paginationMeta: observable.ref,
      isLoading: observable,
      isEmpty: computed,
    });
  }

  get isEmpty() {
    return this.paginationMeta?.total === 0;
  }

  async fetchPayments(params = {}) {
    runInAction(() => {
      this.isLoading = true;
    });
    const res = await apiUser.getPaymentHistory(params);

    if (res.data) {
      runInAction(() => {
        this.payments = res.data.payments;
        this.paginationMeta = res.data.pagination;
      });
    }
    runInAction(() => {
      this.isLoading = false;
    });
  }

  subscribe({ planId, currentAccount }) {
    return apiPayment.createSubscription({
      plan_id: planId,
      first_name: currentAccount.first_name,
      last_name: currentAccount.last_name,
      email: currentAccount.email,
    });
  }
}
