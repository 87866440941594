import { observer } from "mobx-react-lite";
import { BuildPart } from "./content/BuildPart";
import { PanelTemplate } from "../PanelTemplate";

import { useTranslation } from "react-i18next";
import { T } from "util/Translation";

import { BUILD_PARTS } from "util/consts";

export const CustomSections = observer(({ lcoalStore, project }) => {
  const { t } = useTranslation();

  const pages = Object.values(BUILD_PARTS).map((part) => ({
    name: t(part.name),
    value: project.settings?.[part.key]?.isActive ? (
      <span className="ls-tag ls-active">
        <T id="build.active" />
      </span>
    ) : (
      <span className="ls-tag">
        <T id="build.disabled" />
      </span>
    ),
    content: <BuildPart key={part.key} partId={part.key} project={project} />,
  }));

  return <PanelTemplate pages={pages} projectName={project.name} />;
});
