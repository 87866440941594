import { FieldText } from "ui/FieldText";
import * as S from "./styles";
import { FieldDropdown } from "ui/FieldDropdown";
import { T } from "util/Translation";
import { useFormState } from "react-final-form";
import { FieldImage } from "ui/FieldImage";
import { StyleFieldItem } from "./StyleFieldItem";

const options = ["color", "image", "gradient"].map((item) => ({
  key: item,
  value: item,
  text: item,
}));

const allColors = [
  "F0F8FF",
  "F2F3F4",
  "FEFEFA",
  "FFF0F5",
  "FFFAFA",
  "A50021",
  "AB274F",
  "DE3163",
  "FFA000",
  "E8AC41",
  "177245",
  "93C572",
  "3AB09E",
  "72A0C1",
  "003262",
  "1560BD",
  "512888",
  "86608E",
  "8B8589",
  "2A3439",
  "1B1B1B",
  "010B13",
];

const allGradients = [
  ["0061fd", "60effd"],
  ["ff0f7b", "f89b29"],
  ["d4acfb", "b84fce"],
  ["00ff87", "60efff"],
  ["f5e6ad", "f13c77"],
  ["fbd07c", "f7f779"],
];

const softColorOptions = [0, 25, 50, 75]
  .map((item, idx) => ({
    key: idx + 1,
    value: {
      type: "color",
      color: `rgba(255, 255, 255, 0.${item})`,
    },
    content: (
      <span
        style={{
          height: 34,
          width: 34,
          margin: -14,
          borderRadius: 2,
          lineHeight: "32px",
          fontSize: 14,
        }}
      >
        {item}%
      </span>
    ),
  }))
  .concat(
    allColors.map((color, idx) => ({
      key: idx + 5,
      value: {
        type: "color",
        color: `#${color}`,
      },
      content: (
        <span
          style={{
            height: 34,
            width: 34,
            background: `#${color}`,
            margin: -14,
            borderRadius: 2,
          }}
        />
      ),
    }))
  );

const softGradientOptions = allGradients.map(([color1, color2], idx) => ({
  key: idx + 1,
  value: {
    type: "gradient",
    deg: 135,
    color1: `#${color1}`,
    color2: `#${color2}`,
  },
  content: (
    <span
      style={{
        height: 34,
        width: "calc(100% + 28px)",
        background: `linear-gradient(135deg, #${color1}, #${color2})`,
        margin: -40,
        borderRadius: 2,
      }}
    />
  ),
}));

export const Background = ({ name, labelIntl, defaultValue = {} }) => {
  const { values } = useFormState();
  const type = accessNestedProperty(values, `${name}.type`) || "color";

  return (
    <S.FieldWrapper>
      <div className="ls-h">
        {labelIntl && (
          <label>
            <T id={labelIntl} />
          </label>
        )}
      </div>
      <div className="ls-b">
        <div>
          <FieldDropdown
            finalFormProps={{
              name: `${name}.type`,
              defaultValue: "color",
            }}
            semanticProps={{
              options: options,
            }}
          />
        </div>
        {type === "color" && (
          <StyleFieldItem
            name={`${name}`}
            labelIntl={"Color settings"}
            options={softColorOptions}
            sub
            columns={0}
          >
            <div className="ls-row">
              <FieldText
                finalFormProps={{
                  name: `${name}.color`,
                  defaultValue: "#6366f1",
                }}
                semanticProps={{
                  type: "color",
                  className: "ls-color-picker",
                }}
              />
            </div>
          </StyleFieldItem>
        )}
        {type === "gradient" && (
          <StyleFieldItem
            name={`${name}`}
            labelIntl={"Gradient settings"}
            options={softGradientOptions}
            sub
          >
            <div className="ls-row ls-row-border">
              <FieldText
                finalFormProps={{
                  name: `${name}.deg`,
                  defaultValue: 135,
                }}
                semanticProps={{
                  type: "number",
                }}
              />
              <FieldText
                finalFormProps={{
                  name: `${name}.color1`,
                  defaultValue: "#6366f1",
                }}
                semanticProps={{
                  type: "color",
                  className: "ls-color-picker",
                }}
              />
              <FieldText
                finalFormProps={{
                  name: `${name}.color2`,
                  defaultValue: "#6366f1",
                }}
                semanticProps={{
                  type: "color",
                  className: "ls-color-picker",
                }}
              />
            </div>
          </StyleFieldItem>
        )}
        {type === "image" && (
          <FieldImage
            finalFormProps={{
              name: `${name}.image`,
              defaultValue: defaultValue.image || null,
            }}
          />
        )}
      </div>
    </S.FieldWrapper>
  );
};

function accessNestedProperty(obj, path) {
  const keys = path.split(".");
  let value = obj;

  for (const key of keys) {
    value = value[key];
    if (value === undefined) {
      return undefined;
    }
  }
  return value;
}
