import { FieldText } from "ui/FieldText";
import { FieldDropdown } from "ui/FieldDropdown";
import { StyleFieldItem } from "./StyleFieldItem";

const options = ["solid", "dotted"].map((item) => ({
  key: item,
  value: item,
  text: item,
}));

const softOptions = [
  {
    key: 1,
    value: {},
    content: "Default",
  },
  {
    key: 2,
    value: {
      size: 1,
      style: "solid",
      color: "var(--grey-700)",
    },
    content: "Thin",
  },
  {
    key: 3,
    value: {
      size: 4,
      style: "solid",
      color: "var(--grey-600)",
    },
    content: "Thick",
  },
  {
    key: 4,
    value: {
      size: 1,
      style: "dashed",
      color: "var(--primary-500)",
    },
    content: "Dashed",
  },
];

export const Border = ({ name, labelIntl }) => {
  return (
    <StyleFieldItem name={name} labelIntl={labelIntl} options={softOptions}>
      <div className="ls-row ls-row-border">
        <FieldText
          finalFormProps={{
            name: `${name}.size`,
          }}
          semanticProps={{
            type: "number",
            min: 0,
            max: 10,
          }}
        />
        <FieldDropdown
          finalFormProps={{
            name: `${name}.style`,
            defaultValue: "solid",
          }}
          semanticProps={{
            options: options,
          }}
        />
        <FieldText
          finalFormProps={{
            name: `${name}.color`,
            defaultValue: "#6366f1",
          }}
          semanticProps={{
            type: "color",
            className: "ls-color-picker",
          }}
        />
      </div>
    </StyleFieldItem>
  );
};
