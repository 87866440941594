import { Carusel } from "ui/Carusel";
import * as S from "./styles";
import { calcSize } from "util/helpers";

export const Gallery = ({ data }) => {
  const [height, width] = calcSize(data);
  return (
    <S.Block>
      {data.desc && <p>{data.desc}</p>}

      <Carusel>
        {data.images.map((item, idx) => (
          <img
            key={idx}
            alt="gallery-item"
            src={item}
            height={height}
            width={width}
          />
        ))}
      </Carusel>
    </S.Block>
  );
};
