import { FieldText } from "ui/FieldText";
import * as S from "./styles";
import { T } from "util/Translation";

export const Text = ({ name, labelIntl }) => {
  return (
    <S.FieldWrapper>
      {labelIntl && (
        <label>
          <T id={labelIntl} />
        </label>
      )}
      <div>
        <FieldText
          finalFormProps={{
            name: name,
          }}
        />
      </div>
    </S.FieldWrapper>
  );
};
