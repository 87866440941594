import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "components/App/App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
}
