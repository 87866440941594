import { Field } from "react-final-form";
import * as S from "./styles";
import TextareaAutosize from "react-textarea-autosize";
import { multiValidators, required } from "util/validators";
import { TextAreaWrapper } from "ui/common-styles";

export const TextArea = ({ blockId, data }) => {
  const validatorsArr = data.required ? [required] : [];

  return (
    <S.Block>
      {data.field && (
        <p className={"lf-field" + (data.required ? " lf-required" : "")}>
          {data.field}
        </p>
      )}
      {data.desc && <p>{data.desc}</p>}
      <Field name={blockId} validate={multiValidators(validatorsArr)}>
        {({ input, meta }) => (
          <TextAreaWrapper
            className={
              meta.touched && (meta.error || meta.submitError) ? "error" : ""
            }
          >
            <TextareaAutosize
              name={input.name}
              value={input.value}
              onChange={input.onChange}
              placeholder={data.placeholder}
              minRows={data.rows}
            />
            {meta.touched && (meta.error || meta.submitError) && (
              <p className="ls-error">{meta.error || meta.submitError}</p>
            )}
          </TextAreaWrapper>
        )}
      </Field>
    </S.Block>
  );
};
