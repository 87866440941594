import { Form as FormUI, Button, Divider } from "semantic-ui-react";
import { useHistory, Link } from "react-router-dom";
import { Field, Form, useFormState } from "react-final-form";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "structure";
import { GoogleLogin } from "@react-oauth/google";
import { Icon } from "assets/icons/Icon";
import { T } from "util/Translation";
import { useTranslation } from "react-i18next";

import { FieldText } from "ui/FieldText";
import { ExistFieldIndicator, FormField } from "ui/common-styles";
import {
  multiValidators,
  required,
  min,
  max,
  equals,
  email,
} from "util/validators";

import * as S from "./styles";
import { AdditionalInfoRequired } from "../Login/Login";
import { MAIN_URL } from "util/consts";

const STEPS = {
  TYPE: 0,
  EMAIL: 1,
  COMPLETE: 2,
};

export const Register = observer(() => (
  <>
    <div className="ls-outlet-wrapper">
      <Content />
    </div>
    <div className="ls-message">
      <p>
        <T id="register.already_registered" />
      </p>
      <Link to="/login">
        <T id="register.login" />
      </Link>
    </div>
  </>
));

const Content = observer(() => {
  const { accountStore } = useStore();
  const history = useHistory();
  const [step, setStep] = useState(STEPS.TYPE);

  const submit = async (data) => {
    if (step === STEPS.EMAIL) {
      setStep(STEPS.COMPLETE);
    } else {
      const res = await accountStore.register(data);
      if (res.error || res.errors) {
        return res.error || res.errors;
      } else {
        history.push(`/payment/${data.plan}`);
      }
    }
  };

  return (
    <Form
      onSubmit={submit}
      render={({ handleSubmit }) => (
        <FormUI onSubmit={handleSubmit}>
          {step === STEPS.TYPE && (
            <SelectType emailHandler={() => setStep(STEPS.EMAIL)} />
          )}
          {step === STEPS.EMAIL && (
            <EmailDetails handleBack={() => setStep(STEPS.TYPE)} />
          )}
          {step === STEPS.COMPLETE && (
            <CompleteSection handleBack={() => setStep(STEPS.EMAIL)} />
          )}
        </FormUI>
      )}
    />
  );
});

const SelectType = ({ emailHandler }) => {
  const { accountStore } = useStore();
  const history = useHistory();
  const [isAdditionalInfoRequired, setIsAdditionalInfoRequired] =
    useState(false);
  const [additionalInfo, setAdditionalInfo] = useState({
    email: "",
    name: "",
    google_id: "",
    avatar: "",
    username: "",
  });
  const [isChecking, setIsChecking] = useState(false);
  const [emailExists, setEmailExists] = useState(null);
  const { values, submitting } = useFormState();

  useEffect(() => {
    if (!values.email) {
      setEmailExists(null);
      return;
    }
    setIsChecking(true);
    const timeoutId = setTimeout(async () => {
      try {
        const res = await accountStore.checkEmailExists(values.email);
        setEmailExists(res.exists);
      } catch (error) {
        console.error("Error checking email", error);
      } finally {
        setIsChecking(false);
      }
    }, 3000);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, [values.email]);

  const handleGoogleLoginSuccess = async (credentialResponse) => {
    const res = await accountStore.loginGoogle(credentialResponse.credential);
    if (res.status === "additional_info_required") {
      setIsAdditionalInfoRequired(true);
      setAdditionalInfo({
        email: res.email,
        name: res.name,
        google_id: res.google_id,
        avatar: res.avatar,
        username: "",
      });
    }
    if (res.user) {
      if (accountStore.subscriptionLead) {
        history.push(`/payment/${accountStore.subscriptionLead}`);
      } else {
        history.push("/");
      }
    }
  };

  return (
    <div className="ls-register">
      <h1>
        <T id="register.create_account_title" components={{ span: <span /> }} />
      </h1>
      {isAdditionalInfoRequired ? (
        <AdditionalInfoRequired info={additionalInfo} />
      ) : (
        <>
          <div className="ls-body">
            <div className="ls-section-center">
              <GoogleLogin
                onSuccess={handleGoogleLoginSuccess}
                onError={() => console.error("Login Failed")}
              />
            </div>
            <Divider horizontal>
              <T id="register.or_continue_with_email" />
            </Divider>
            <div className="ls-section">
              {emailExists !== null && !isChecking && (
                <ExistFieldIndicator exist={emailExists}>
                  <Icon name={emailExists ? "close" : "check"} />
                </ExistFieldIndicator>
              )}
              <FieldText
                finalFormProps={{
                  name: "email",
                  validate: multiValidators([required, email, max(255)]),
                }}
                semanticProps={{
                  disabled: submitting,
                  type: "email",
                  error: emailExists,
                  loading: isChecking,
                }}
                label={<T id="register.email_label" />}
              />
              {emailExists && (
                <p className="ls-error">
                  <T id="register.email_exists" />
                </p>
              )}
              <Button
                primary
                fluid
                type="button"
                onClick={emailHandler}
                disabled={emailExists || isChecking}
              >
                <T id="register.next_button" />
              </Button>
            </div>
            <p className="ls-disclaimer">
              <T id="register.terms_disclaimer" />{" "}
              <Link
                to={{ pathname: `${MAIN_URL}terms-of-use/` }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <T id="register.terms_of_use" />
              </Link>{" "}
              <T id="register.and_privacy" />{" "}
              <Link
                to={{ pathname: `${MAIN_URL}privacy-policy/` }}
                target="_blank"
                rel="noopener noreferrer"
              >
                <T id="register.privacy_policy" />
              </Link>
            </p>
          </div>
        </>
      )}
    </div>
  );
};

const EmailDetails = ({ handleBack }) => {
  const { submitting } = useFormState();

  return (
    <div className="ls-register">
      <h1>
        <T id="register.create_account_title" components={{ span: <span /> }} />
      </h1>
      <div className="ls-body">
        <div className="ls-section">
          <FieldText
            finalFormProps={{
              name: "first_name",
              validate: multiValidators([required, min(2)]),
            }}
            semanticProps={{ disabled: submitting }}
            label={<T id="register.first_name" />}
          />
          <FieldText
            finalFormProps={{
              name: "last_name",
              validate: multiValidators([required, min(2)]),
            }}
            semanticProps={{ disabled: submitting }}
            label={<T id="register.last_name" />}
          />
          <FieldText
            finalFormProps={{
              name: "password",
              validate: multiValidators([required, min(8), max(255)]),
            }}
            semanticProps={{ disabled: submitting, type: "password" }}
            label={<T id="register.password_label" />}
          />
          <FieldText
            finalFormProps={{
              name: "password_confirmation",
              validate: multiValidators([
                required,
                min(8),
                max(255),
                equals("password"),
              ]),
            }}
            semanticProps={{ disabled: submitting, type: "password" }}
            label={<T id="register.password_confirm" />}
          />
          <br />
          <div className="ls-split" style={{ gap: 16 }}>
            <Button
              fluid
              type="button"
              className="ls-bright"
              color="grey"
              onClick={handleBack}
            >
              <T id="register.back_button" />
            </Button>
            <Button primary fluid type="submit" disabled={submitting}>
              <T id="register.next_button" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const CompleteSection = ({ handleBack }) => {
  const { t } = useTranslation();
  const { submitting, values } = useFormState();
  const hasBack = !!values?.email;
  const { accountStore } = useStore();
  const [isChecking, setIsChecking] = useState(false);
  const [usernameExists, setUsernameExists] = useState(null);

  const plans = {
    lite: {
      name: t("plans.basic.name"),
      subtitle: t("plans.basic.subtitle"),
      note: t("plans.basic.note"),
    },
    premium: {
      name: t("plans.premium.name"),
      subtitle: t("plans.premium.subtitle"),
      note: t("plans.premium.note"),
      price: 14,
    },
    pro: {
      name: t("plans.pro.name"),
      subtitle: t("plans.pro.subtitle"),
      note: t("plans.pro.note"),
      price: 36,
    },
  };

  useEffect(() => {
    if (!values.username) {
      setUsernameExists(null);
      return;
    }
    setIsChecking(true);
    const timeoutId = setTimeout(async () => {
      try {
        const res = await accountStore.checkUsernameExists(values.username);
        setUsernameExists(res.exists);
      } catch (error) {
        console.error("Error checking username", error);
      } finally {
        setIsChecking(false);
      }
    }, 3000);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, [values.username]);

  return (
    <div className="ls-complete">
      <h1>
        <T id="register.complete_account" />
      </h1>
      <div className="ls-body ls-body-complete">
        {usernameExists !== null && !isChecking && (
          <ExistFieldIndicator exist={usernameExists}>
            <Icon name={usernameExists ? "close" : "check"} />
          </ExistFieldIndicator>
        )}
        <FieldText
          finalFormProps={{
            name: "username",
            validate: multiValidators([required, min(4)]),
          }}
          semanticProps={{
            disabled: submitting,
            error: usernameExists,
            loading: isChecking,
          }}
          label={<T id="register.username_label" />}
        />
        {usernameExists && (
          <p className="ls-error">
            <T id="register.username_exists" />
          </p>
        )}
        <div className="ls-section">
          <Field name="plan" validate={required}>
            {({ meta, input }) => (
              <FormField>
                <label>
                  <T id="register.select_plan" />
                </label>
                <input
                  type="hidden"
                  name={input.name}
                  value={input.value}
                  onChange={input.onChange}
                  initialValue={accountStore.subscriptionLead}
                />
                <S.PlanOptionsWrapper>
                  {Object.entries(plans).map(([plan, data]) => (
                    <Button
                      className={plan === values.plan ? "ls-selected" : ""}
                      type="button"
                      onClick={() => input.onChange(plan)}
                    >
                      <div className="price">
                        {data.price ? (
                          <>
                            <span className="p-m">$</span>
                            <span className="p-l">{data.price}</span>
                            <span className="p-s">/mo</span>
                          </>
                        ) : (
                          <span className="p-m">
                            <T id="register.free_plan" />
                          </span>
                        )}
                      </div>
                      <div className="title">
                        <h3>{data.name}</h3>
                        <span className="subtitle">{data.subtitle}</span>
                      </div>
                      <span className="notes">{data.note}</span>
                    </Button>
                  ))}
                </S.PlanOptionsWrapper>
                {meta.touched && (meta.error || meta.submitError) && (
                  <p className="ls-error">{meta.error || meta.submitError}</p>
                )}
              </FormField>
            )}
          </Field>
        </div>
        <div className="ls-section">
          <div className="ls-split" style={{ gap: 16 }}>
            {hasBack && (
              <Button
                fluid
                className="ls-bright"
                color="grey"
                type="button"
                disabled={submitting}
                onClick={handleBack}
              >
                <T id="register.back_button" />
              </Button>
            )}
            <Button
              fluid
              primary
              type="submit"
              disabled={submitting || usernameExists || isChecking}
              loading={submitting}
            >
              <T id="register.complete_account_button" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
