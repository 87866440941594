import { FieldText } from "ui/FieldText";
import { StyleFieldItem } from "./StyleFieldItem";

export const Color = ({ name, labelIntl, sub }) => {
  return (
    <StyleFieldItem
      name={name}
      labelIntl={labelIntl}
      options={softColorOptions}
      sub={sub}
      columns={0}
    >
      <div className="ls-row">
        <FieldText
          finalFormProps={{
            name: `${name}`,
            defaultValue: "#6366f1",
          }}
          semanticProps={{
            type: "color",
            className: "ls-color-picker",
          }}
        />
      </div>
    </StyleFieldItem>
  );
};

const allColors = [
  "A50021",
  "AB274F",
  "DE3163",
  "FFA000",
  "E8AC41",
  "177245",
  "3AB09E",
  "72A0C1",
  "003262",
  "1560BD",
  "512888",
  "86608E",
  "8B8589",
  "2A3439",
  "1B1B1B",
  "010B13",
];

const softColorOptions = allColors.map((color, idx) => ({
  key: idx,
  value: `#${color}`,
  content: (
    <span
      style={{
        height: 34,
        width: 34,
        background: `#${color}`,
        margin: -14,
        borderRadius: 2,
      }}
    />
  ),
}));
