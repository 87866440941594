import { FieldImage } from "ui/FieldImage";
import { useTranslation } from "react-i18next";
import { useFormState } from "react-final-form";
import { FieldButtonSelect } from "ui/FieldButtonSelect";
import StyleFields from "components/Dashboard/Project/ProjectPreview/settingsSections/StyleFields";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Button, MenuItem, Tab } from "semantic-ui-react";
import { T } from "util/Translation";
import { AsText } from "ui/common-styles";
import { Icon } from "assets/icons/Icon";
import * as S from "./styles";

export const HeaderEditor = ({ handleSubmit }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const { values } = useFormState();

  const handleTabChange = (e, { activeIndex }) => {
    setCurrentTab(activeIndex);
  };

  const panes = [
    {
      menuItem: (
        <MenuItem key="frame">
          <T id="Header section" />
        </MenuItem>
      ),
      render: () => <FrameSettings />,
    },
    {
      menuItem: (
        <MenuItem key="title">
          <T id="Title section" />
        </MenuItem>
      ),
      render: () => <TitleSettings />,
    },
    {
      menuItem: (
        <MenuItem key="button">
          <T id="Button section" />
        </MenuItem>
      ),
      render: () => <ButtonSettings />,
    },
  ];

  return (
    <S.ContainerForm onSubmit={handleSubmit}>
      <S.DemoBodyWrapper>
        <div>
          <S.PreviewWrapper frame={values.frame}>
            <Title data={values.title} onClick={() => setCurrentTab(1)} />
            <ButtonSection
              data={values.button}
              onClick={() => setCurrentTab(2)}
            />
          </S.PreviewWrapper>
          <h5>Preview</h5>
        </div>
      </S.DemoBodyWrapper>

      <h5 className="ls-hide-s">Settings</h5>

      <Tab
        className="ls-settings"
        menu={{ fluid: true, vertical: true }}
        panes={panes}
        activeIndex={currentTab}
        onTabChange={handleTabChange}
      />
    </S.ContainerForm>
  );
};

const sizeOptions = [
  {
    key: 1,
    value: 32,
    content: "Small",
  },
  {
    key: 2,
    value: 48,
    content: "Medium",
  },
  {
    key: 3,
    value: 64,
    content: "Large",
  },
];

const sizeButtonOptions = [
  {
    key: 1,
    value: "small",
    content: "Small",
  },
  {
    key: 2,
    value: "medium",
    content: "Medium",
  },
  {
    key: 3,
    value: "big",
    content: "Large",
  },
];

const Title = ({ data, onClick }) => {
  if (!data || !(data.text || data.logo)) {
    return <S.Placeholder onClick={onClick}>Title / Logo</S.Placeholder>;
  }
  return (
    <div className="ls-part-wrapper">
      <S.Title size={data.size || 40}>
        {data.logo?.[0] ? (
          <img src={data.logo[0]} alt={data.text || "logo"} />
        ) : (
          <h4>{data.text}</h4>
        )}
      </S.Title>
      <AsText className="ls-edit-icon" onClick={onClick}>
        <Icon name="edit" />
      </AsText>
    </div>
  );
};

const ButtonSection = ({ data, onClick }) => {
  if (!data || !data.text) {
    return <S.Placeholder onClick={onClick}>Button Link</S.Placeholder>;
  }
  return (
    <div className="ls-part-wrapper">
      <Button
        primary
        size={data.size}
        as={Link}
        to={{ pathname: data.link }}
        target="_blank"
      >
        {data.text}
      </Button>
      <AsText className="ls-edit-icon" onClick={onClick}>
        <Icon name="edit" />
      </AsText>
    </div>
  );
};

const FrameSettings = () => (
  <div className="ls-group">
    <StyleFields.Size
      sub
      name="frame.height"
      labelIntl="Header height"
      minSize={40}
    />
    <StyleFields.Background
      name="frame.background"
      sub
      labelIntl="Header background"
    />
    <StyleFields.Color name="frame.border" labelIntl="Header border color" />
  </div>
);

const ButtonSettings = () => {
  const { t } = useTranslation();
  return (
    <div className="ls-group">
      <StyleFields.Text sub name="button.text" labelIntl="Button text" />
      <StyleFields.Text sub name="button.link" labelIntl="Button link" />
      <FieldButtonSelect
        label={t("Button size")}
        finalFormProps={{
          name: "button.size",
          defaultValue: "medium",
        }}
        options={sizeButtonOptions}
        columns={3}
      />
    </div>
  );
};

const TitleSettings = () => {
  const { t } = useTranslation();
  return (
    <div className="ls-group">
      <StyleFields.Text sub name="title.text" labelIntl="Title text" />
      <FieldImage
        label={t("Logo")}
        finalFormProps={{
          name: "title.logo",
        }}
      />
      <FieldButtonSelect
        label={t("Title size")}
        finalFormProps={{
          name: "title.size",
          defaultValue: 40,
        }}
        options={sizeOptions}
        columns={3}
      />
    </div>
  );
};
